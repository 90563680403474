import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import ErrorInner from '@/components/ErrorInner';

const UhOhPage = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const location = useLocation();

  const updateErrorMsg = () => {
    const e = new URLSearchParams(location.search).get('e');

    let title = 'An error has occured';
    let content = 'Oops, something went wrong!';

    if (['1', '4'].includes(e)) {
      title = 'UH-OH!';
      content =
        'Thank you for attempting this survey, unfortunately you did not meet the qualifications to complete. <br />Please try our other available offers on the dashboard!';
    }
    if (e == 3) {
      content =
        'The maximum number of allowed participants for this survey has already been reached.';
    }
    if (e == 5) {
      title = 'We regret that you did not qualify for this survey.';
      content =
        'Unfortunately, our survey provider has no surveys available for you.';
    }

    setTitle(title);
    setContent(content);
  };

  useEffect(() => {
    updateErrorMsg();
  }, [location]);

  const token = new URLSearchParams(location.search).get('token_id');

  return <ErrorInner title={title} content={content} />;
};

export default UhOhPage;
