import { createSlice } from '@reduxjs/toolkit';

export const pageSlice = createSlice({
  name: 'page',
  initialState: {
    title: null,
    content: null,
  },
  reducers: {
    resetPageContent: (state) => {
      state.title = null;
      state.content = null;
    },
    setPageContent: (state, { payload }) => {
      const { title, content } = payload;
      state.title = title;
      state.content = content;
    },
  },
});

export const { setPageContent, resetPageContent } = pageSlice.actions;

export default pageSlice.reducer;
