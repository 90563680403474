import Button from '@/components/Button';
import Logo from '@/components/Logo';
import AppFooterSimple from '@/components/AppFooterSimple';
import PageHeader from '@/components/PageHeader';

const Error404Page = () => {
  return (
    <div className="bg-grey-200 flex flex-col min-h-screen justify-between py-12">
      <div className="flex justify-center">
        <Logo width="w-56" doNotBreak />
      </div>
      <section className="max-w-2xl py-6 px-4 mx-auto text-center">
        <PageHeader isSmall title="There's nothing here" />
        <div className="my-3">
          <img
            src="/img/task/i_c_dkmb_Broken.svg"
            alt="Cat spilling glass of water"
          />
        </div>
        <Button to="/" variant="outline">
          Return to the main page
        </Button>
      </section>
      <AppFooterSimple />
    </div>
  );
};

export default Error404Page;
