import PropTypes from 'prop-types';
import { css } from '@emotion/css';

const FormRadio = ({ onChange, className = '', name, value, label }) => {
  const radioStyle = css`
    .radio-btn:after {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: white;
      content: '';
      position: absolute;
      opacity: 0;
      transition: all 0.2s;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
    input:checked ~ .radio-btn {
      background-color: #1494d6;
      &:after {
        opacity: 1;
      }
    }
  `;

  return (
    <label
      className={`${className} ${radioStyle} w-full flex items-center relative cursor-pointer `}
    >
      <input
        type="radio"
        className="absolute opacity-0 cursor-pointer"
        name={name}
        value={value}
        onChange={onChange}
      />
      <span className="radio-btn relative w-5 h-5 bg-grey-300 left-1 transition-all duration-200 rounded-full" />
      {label && <span className="text-sm font-bold pl-3 mt-px">{label}</span>}
    </label>
  );
};

FormRadio.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.any.isRequired,
};

export default FormRadio;
