import PropTypes from 'prop-types';
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import withSession from '@/hoc/withSession';

const PageLayout = ({ children }) => {
  return (
    <div className="bg-grey-200 min-h-screen flex flex-col">
      <AppHeader />
      <div className="flex-auto">
        <main className="bg-white">{children}</main>
      </div>
      <div className="flex-shrink-0">
        <AppFooter />
      </div>
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withSession(PageLayout);
