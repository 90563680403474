import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormSelect from '@/components/FormSelect';

const OptionsMulti = ({ data, onChange }) => {
  const { answer_set, id } = data;

  const handleOnChange = (e) => {
    onChange({
      [e.target.value]: true,
    });
  };

  return (
    <section>
      <FormSelect
        valueName="id"
        placeholderOption="Select an Option"
        options={answer_set}
        onChange={handleOnChange}
      />
    </section>
  );
};

OptionsMulti.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OptionsMulti;
