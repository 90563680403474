import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LoadingEllipsis from '@/components/LoadingEllipsis';

const Button = ({
  children,
  to = null,
  className = '',
  onClick,
  variant = 'default',
  loading = false,
  disabled = false,
  size = 'base',
}) => {
  const btnStyle =
    variant === 'secondary'
      ? 'bg-grey hover:bg-grey-400 active:bg-grey-600 text-white'
      : variant === 'outline'
      ? 'border-2 bg-transparent hover:bg-grey-700 active:bg-grey-800 hover:text-white active:text-white border-grey-700 active:border-grey-800'
      : variant === 'success'
      ? 'bg-green hover:bg-green-400 active:bg-green-600 text-white'
      : 'bg-blue text-white hover:bg-blue-400 active:bg-blue-600';

  const btnSize = size === 'large' ? 'h-11 text-lg' : 'h-10';

  const btnClass = `${className} ${btnSize} ${btnStyle} ${
    disabled ? 'opacity-60 pointer-events-none' : ''
  } transition-all font-bold duration-200 rounded px-6 leading-none inline-flex items-center justify-center`;

  return to ? (
    <Link to={to} onClick={onClick} className={btnClass}>
      {loading ? <LoadingEllipsis /> : children}
    </Link>
  ) : (
    <button
      disabled={disabled || loading}
      className={btnClass}
      onClick={onClick}
    >
      {loading ? <LoadingEllipsis width={40} /> : children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  to: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Button;
