const TaskHot = () => (
  <div className="top-0 right-0 absolute z-1">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <path
        fill="#DC2626"
        d="M36,0 L36,36 L0,0 L36,0 Z M24.3566844,4 C24.3972031,5.51666667 23.9987687,6.68281481 23.5840767,7.61447257 L23.3930652,8.02824074 L23.3930652,8.02824074 L23.1224207,8.592 L23.1224207,8.592 L23.0778708,8.44333037 L23.0778708,8.44333037 L23.0191913,8.2784 L23.0191913,8.2784 L22.9335825,8.0716563 C22.8172209,7.81148444 22.6418059,7.4944 22.393083,7.22 C22.3743821,7.42533333 22.2372417,7.70533333 22.0356868,8.03925926 L21.8146614,8.39039232 L21.8146614,8.39039232 L21.291804,9.18207407 L21.291804,9.18207407 L21.0139831,9.61340466 C20.8291282,9.90730864 20.6476596,10.2128889 20.4855846,10.524 C20.2331216,11.028 19.8123498,12.568 20.0928643,13.744 L20.1859619,14.0989321 C20.6637425,15.7629867 21.7703409,17.5034667 24.4408387,18 L24.3194068,17.7996662 L24.3194068,17.7996662 L24.2108539,17.602327 L24.2108539,17.602327 L24.1267825,17.4380756 L24.1267825,17.4380756 L24.0366782,17.2497558 L24.0366782,17.2497558 L23.9433947,17.0391552 L23.9433947,17.0391552 L23.8497856,16.8080612 L23.8497856,16.8080612 L23.7587046,16.5582613 L23.7587046,16.5582613 L23.6730053,16.2915428 C23.2627194,14.9186331 23.1612611,12.9783077 24.9738162,11.476 C24.8445059,14.3524878 26.381812,16.2696966 27.4467568,17.2436193 L27.6747663,17.4445258 L27.6747663,17.4445258 L27.8820087,17.6144165 L27.8820087,17.6144165 L28.0632675,17.7533306 L28.0632675,17.7533306 L28.2133268,17.8613069 L28.2133268,17.8613069 L28.4241443,18 L28.4241443,18 C30.2194369,17.104 31.1731861,15.928 31.6781122,14.472 C32.6318614,11.812 31.2853919,9.04 29.9950253,8.032 C29.8547681,8.62 29.5181507,9.096 29.0412761,9.544 C28.7607616,7.22 26.4324915,5.232 24.3566844,4 Z"
      />
    </svg>
  </div>
);

export default TaskHot;
