import PropTypes from 'prop-types';

const Testimonial = ({ testimonial }) => {
  const { name, age, job, location, review, avatar } = testimonial;

  const avatarImg = require(`@/assets/img/testimonial_${avatar}.png?webp`)
    .default;

  return (
    <div className="p-6 flex flex-col md:flex-row">
      <div className="">
        <img className="w-20" src={avatarImg} alt={`${name} avatar`} />
      </div>
      <div className="pt-3 md:pt-0 md:pl-8 md:w-4/5">
        <div className="font-bold">
          {name}, {age}. {job}
        </div>
        <div className="text-grey-600 text-sm">{location}</div>
        <p className="text-lg mt-2">{review}</p>
      </div>
    </div>
  );
};

Testimonial.propTypes = {
  testimonial: PropTypes.object.isRequired,
};

export default Testimonial;
