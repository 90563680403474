import LoadingEllipsis from '@/components/LoadingEllipsis';
import PropTypes from 'prop-types';

const ReadyBtn = ({ loading = false }) => (
  <div className="absolute bottom-3 left-3 lg:left-4 right-3 lg:right-4 flex items-center py-2.5 px-3 lg:px-4 justify-center">
    <div
      disabled={loading}
      className="flex rounded bg-green hover:bg-green-400 duration-200 transition-colors items-center text-white font-bold uppercase px-2 py-1.5 text-sm"
    >
      {loading ? (
        <LoadingEllipsis width={32} />
      ) : (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-5 -7 24 24"
            width="20"
          >
            <path
              fill="currentColor"
              d="M5.486 9.73a.997.997 0 0 1-.707-.292L.537 5.195A1 1 0 1 1 1.95 3.78l3.535 3.535L11.85.952a1 1 0 0 1 1.415 1.414L6.193 9.438a.997.997 0 0 1-.707.292z"
            />
          </svg>
          <span>Ready to Redeem</span>
        </>
      )}
    </div>
  </div>
);

ReadyBtn.propTypes = {
  loading: PropTypes.bool,
};

export default ReadyBtn;
