import PageSimple from '@/components/PageSimple';

const PrivacyCaliforniaPage = () => (
  <PageSimple
    title="Privacy Policy - California Residents"
    path="/privacy-policy-ccpa"
  />
);

export default PrivacyCaliforniaPage;
