import PropTypes from 'prop-types';
import { css } from '@emotion/css';

const FormCheckbox = ({
  checked = false,
  onChange,
  name,
  label,
  className = '',
}) => {
  const checkboxStyle = css`
    .check-btn:after {
      width: 1.25rem;
      height: 1.25rem;
      transform: rotate(-20deg);
      content: '';
      position: absolute;
      opacity: 0;
      transition: transform 0.2s, opacity 0.2s;
      top: 0;
      left: 0;
      background: no-repeat
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-5 -7 24 24' width='24' height='24'%3E%3Cpath fill='%23fff' d='M5.486 9.73a.997.997 0 0 1-.707-.292L.537 5.195A1 1 0 1 1 1.95 3.78l3.535 3.535L11.85.952a1 1 0 0 1 1.415 1.414L6.193 9.438a.997.997 0 0 1-.707.292z'%3E%3C/path%3E%3C/svg%3E")
        center center / contain;
    }
    input:checked ~ .check-btn {
      background-color: #1494d6;
      &:after {
        opacity: 1;
        transform: rotate(0);
      }
    }
  `;

  return (
    <label
      className={`${checkboxStyle} w-full flex items-center relative pl-7 cursor-pointer ${className}`}
    >
      <span className="text-sm font-bold pl-1 mt-px">{label}</span>
      <input
        checked={checked}
        type="checkbox"
        className="absolute opacity-0 cursor-pointer"
        name={name}
        onChange={onChange}
      />
      <span className="check-btn absolute w-5 h-5 bg-grey-300 left-1 transition-all duration-200 rounded" />
    </label>
  );
};

FormCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default FormCheckbox;
