import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import Alert from '@/components/Alert';

const FormWrapper = ({
  children,
  error = '',
  success = '',
  onSubmit,
  className = '',
}) => {
  const methods = useForm();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  const alertType = error ? 'error' : 'success';

  return (
    <>
      {(error || success) && (
        <Alert type={alertType}>
          <span dangerouslySetInnerHTML={{ __html: error || success }} />
        </Alert>
      )}
      <form onSubmit={handleSubmit} className={className}>
        {children}
      </form>
    </>
  );
};

FormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
  success: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default FormWrapper;
