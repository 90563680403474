import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { InView } from 'react-intersection-observer';
import TaskCard from '@/components/TaskCard';
import icons from './icons';

const TaskCategory = ({ title, tasks, type }) => {
  const { list, tutorialAvailable } = useSelector((state) => state.actions);

  const showTutorial =
    tutorialAvailable &&
    type === 'answer_and_earn' &&
    list.length &&
    list[0].id === 'TUTORIAL_FIRST_TIME_USER';

  return (
    <InView triggerOnce={true} threshold={0.25}>
      {({ inView, ref }) => (
        <>
          <section
            ref={ref}
            className={`${inView && !showTutorial ? 'animate-1' : ''} ${
              !showTutorial ? 'animate' : ''
            } mb-8`}
          >
            <h2 className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                viewBox={icons[type].viewBox}
                className="mr-2"
              >
                <path fill="#767676" d={icons[type].path} />
              </svg>
              {title}
            </h2>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {tasks.map((task, i) => (
                <TaskCard
                  showTutorial={
                    showTutorial && task.source === 'GeneralProfiler2105'
                  }
                  task={task}
                  category={type}
                  key={`tsk-${i}`}
                />
              ))}
            </div>
          </section>

          {showTutorial && (
            <div className="opacity-80 visible z-150 fixed inset-0 bg-grey-900" />
          )}
        </>
      )}
    </InView>
  );
};

TaskCategory.propTypes = {
  title: PropTypes.string.isRequired,
  tasks: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

export default TaskCategory;
