import PropTypes from 'prop-types';
import articles from '@/data/blog.json';
import { Link } from 'react-router-dom';
import { css } from '@emotion/css';

const BlogSeeAlso = ({ article }) => {
  const { id, title, featured_image, slug } = article;

  const style = css`
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgb(0 0 0 / 38%);
      transition: opacity 0.2s;
    }
    &:hover:after {
      opacity: 0.4;
    }
  `;

  return (
    <section className="flex flex-col md:flex-row border-t border-grey-250 mt-8 pt-8">
      <Link
        to="/blog"
        className="p-3 bg-blue text-white hover:shadow-lg hover:bg-blue-400 transition-all duration-200 text-2xl font-bold rounded flex-1 mr-5 h-40 flex items-center justify-center"
      >
        Back To Blogs
      </Link>
      <Link
        to={`/blog/${id}/${slug}`}
        className={`p-5 flex-1 rounded h-40 hover:shadow transition-all duration-200 bg-grey font-bold text-shadow flex items-center text-2xl text-white overflow-hidden relative ${style}`}
        style={{
          background: `url(${featured_image}) center center / cover`,
        }}
      >
        <span className="relative z-10">{title}</span>
      </Link>
    </section>
  );
};

BlogSeeAlso.propTypes = {
  article: PropTypes.object.isRequired,
};

export default BlogSeeAlso;
