import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { post } from '@/config/axios';
import Button from '@/components/Button';
import TaskDetailHeader from './TaskDetailHeader';
import Alert from '@/components/Alert';
import TaskInfo from './TaskInfo';
import { logAnalyticEvent } from '@/config/analytics';

const TaskCheckEmail = ({ task, onClose }) => {
  const { userId } = useSelector((state) => state.user);
  const [taskClone, setTaskClone] = useState(task);
  const [isSent, setIsSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { points, featured, task_type } = task;

  const handleSubmit = () => {
    isSent ? onClose() : sendEmail();
  };

  const sendEmail = async () => {
    setError('');
    setLoading(true);

    try {
      await post('v1/email-verification/send-email', null, {
        user_id: userId,
      });
      taskClone.name = 'Email Sent!';
      setIsSent(true);
      setLoading(false);

      logAnalyticEvent('verify_email', 'close', {
        task_type,
        featured,
        points,
      });
    } catch (err) {
      const errorMsg =
        err?.response?.data?.errors && err?.response?.data?.errors.length
          ? err.response.data.errors[0].message
          : 'Something went wrong, please try again';
      setError(errorMsg);
      setLoading(false);
    }
  };

  const modalText = isSent
    ? 'An email has been sent to your inbox, check the email and click on the link provided'
    : 'Verify your email address by clicking on the SEND button below. Check you inbox and click on the link provided in the email.';

  const modalBtn = isSent ? 'Ok' : 'Send';

  useEffect(() => {
    logAnalyticEvent('verify_email', 'start', {
      task_type,
      featured,
      points,
    });
  }, []);

  return (
    <div className="task-single bg-grey-50">
      <TaskDetailHeader task={task} onClose={onClose} />
      <div className="px-8">
        {error && (
          <div className="flex-1 mt-6 w-full">
            <Alert type="error">{error}</Alert>
          </div>
        )}
        {!isSent && (
          <div>
            <TaskInfo points={points} />
          </div>
        )}

        <section className={isSent ? 'mt-2' : ''}>
          <p className="text-lg text-center">{modalText}</p>
        </section>
      </div>
      <footer className="p-8">
        <Button
          onClick={handleSubmit}
          loading={loading}
          variant="success"
          className="w-full"
        >
          {modalBtn}
        </Button>
      </footer>
    </div>
  );
};

TaskCheckEmail.propTypes = {
  task: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TaskCheckEmail;
