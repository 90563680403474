const AppFooterSimple = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="text-center mt-8">
      <small className="text-sm text-grey-400">
        Copyright {year} Communa. All Rights Reserved.
      </small>
    </footer>
  );
};

export default AppFooterSimple;
