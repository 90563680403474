import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { sleep, numberFormat } from '@/utils/helpers';

// https://jshakespeare.com/simple-count-up-number-animation-javascript-react/

const CountAnimation = ({
  start = 0,
  points = null,
  duration = 1500,
  delay = 0,
}) => {
  if (!points) {
    return numberFormat(start);
  }

  const easeOutQuad = (t) => t * (2 - t);
  const frameDuration = 1000 / 60;

  const countTo = parseInt(points, 10);
  const [count, setCount] = useState(start);

  const runCounter = async () => {
    await sleep(delay);
    let frame = 0;
    const totalFrames = Math.round(duration / frameDuration);
    const counter = setInterval(() => {
      frame++;
      const progress = easeOutQuad(frame / totalFrames);
      setCount(start + countTo * progress);

      if (frame === totalFrames) {
        clearInterval(counter);
      }
    }, frameDuration);
  };

  useEffect(() => {
    runCounter();
  }, []);

  return numberFormat(Math.floor(count));
};

CountAnimation.propTypes = {
  start: PropTypes.number,
  delay: PropTypes.number,
  points: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  duration: PropTypes.number,
};

export default CountAnimation;
