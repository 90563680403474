import { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@/components/Modal';
import TaskModal, { TaskVerifyPhone } from '@/components/TaskModal';

const VerifyPhoneModal = ({ onClose }) => {
  const [showCheck, setShowCheck] = useState(false);

  if (showCheck) {
    return <TaskModal taskId="verify_phone" source="Goals" onClose={onClose} />;
  }

  return (
    <Modal onClose={onClose}>
      {(onClose) => (
        <TaskVerifyPhone onClose={onClose} onCheck={() => setShowCheck(true)} />
      )}
    </Modal>
  );
};

VerifyPhoneModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default VerifyPhoneModal;
