import PropTypes from 'prop-types';
import Button from '@/components/Button';
import Logo from '@/components/Logo';
import AppFooterSimple from '@/components/AppFooterSimple';

const ErrorInner = ({ title, content }) => (
  <div className="bg-grey-200 flex flex-col min-h-screen justify-between py-12">
    <div className="flex justify-center">
      <Logo width="w-56" doNotBreak />
    </div>
    <section className="max-w-2xl py-8 px-4 mx-auto text-center">
      <h1 className="text-2xl md:text-3xl text-center">{title}</h1>
      <p
        className="text-lg md:text-xl mb-8 leading-relaxed"
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <Button to="/" variant="outline">
        Return to the dashboard
      </Button>
    </section>
    <AppFooterSimple />
  </div>
);

ErrorInner.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default ErrorInner;
