import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setTutorialAvailable } from '@/store/slices/actionsSlice';
import Modal from '@/components/Modal';
import { TaskWelcome } from '@/components/TaskModal';

const WelcomeModal = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(true);

  const handleOnClose = () => {
    dispatch(setTutorialAvailable(true));
    setShowModal(false);
  };

  if (!showModal) return <></>;

  return (
    <Modal onClose={handleOnClose}>
      {(onClose) => <TaskWelcome onClose={onClose} />}
    </Modal>
  );
};

export default WelcomeModal;
