import { createSlice } from '@reduxjs/toolkit';

export const featureFlagsSlice = createSlice({
  name: 'flags',
  initialState: {
    list: {},
  },
  reducers: {
    setFlags: (state, { payload }) => {
      state.list = payload;
    },
  },
});

export const { setFlags } = featureFlagsSlice.actions;

export default featureFlagsSlice.reducer;
