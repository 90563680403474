import { useRef } from 'react';
import PropTypes from 'prop-types';
import { randId } from '@/utils/helpers';

const FormSelect = ({
  value,
  label = null,
  type = 'text',
  onChange,
  placeholder = '',
  tooltip = null,
  required = false,
  id = '',
  className = '',
  labelName = 'label',
  valueName = 'value',
  name,
  placeholderOption = null,
  options = [],
  ...props
}) => {
  const ref = useRef();
  const elId = id || randId();

  return (
    <div className={`${className}`}>
      {label && (
        <label
          className="text-xs font-oswald mb-1 block uppercase"
          htmlFor={elId}
        >
          {label}
        </label>
      )}
      <div className="border bg-white h-10 rounded border-grey-275 w-full text-sm">
        <select
          ref={ref}
          id={elId}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="w-full appearance-none h-10 px-3 "
          style={{
            background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='80' viewBox='0 0 44 80'%3E%3Cpath fill='%23414C60' d='M4.3,56 L22,75.3 L39.7,56 L44,56 L22,80 L0,56 L4.3,56 Z M22,0 L44,24 L39.7,24 L22,4.7 L4.3,24 L0,24 L22,0 Z'/%3E%3C/svg%3E%0A") 99% center / 20px 14px no-repeat`,
          }}
          {...props}
        >
          {placeholderOption !== null && (
            <option value="" selected disabled>
              {placeholderOption}
            </option>
          )}
          {options.map((option, i) => (
            <option value={option[valueName]} key={`op-${i}`} {...option}>
              {option[labelName]}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

FormSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  placeholderOption: PropTypes.string,
  tooltip: PropTypes.string,
  labelName: PropTypes.string,
  valueName: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
};

export default FormSelect;
