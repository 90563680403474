import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeEvent } from '@/store/slices/eventsSlice';
import Modal from '@/components/Modal';
import EventDispatcher from './components/EventDispatcher';

const EventsManager = () => {
  const [currentEvent, setCurrentEvent] = useState();
  const { list } = useSelector((state) => state.events);
  const [isInitial, setIsInitial] = useState(true);
  const dispatch = useDispatch();

  const onClose = async () => {
    // close current event
    setIsInitial(false);
    setCurrentEvent();
    dispatch(removeEvent(currentEvent?._id));
  };

  useEffect(() => {
    const currentEvent = list && list.length ? list[0] : null;

    if (
      currentEvent &&
      (['DAY_FIRST_LANDING_POPUP'].includes(currentEvent.type) ||
        currentEvent.points !== 0)
    ) {
      setCurrentEvent(list[0]);
    }
    if (!list.length) {
      setIsInitial(true);
    }
  }, [list]);

  if (!currentEvent) return <></>;

  return (
    <Modal onClose={onClose}>
      {(onClose) => <EventDispatcher event={currentEvent} onClose={onClose} />}
    </Modal>
  );
};

export default EventsManager;
