import { useSelector } from 'react-redux';
import NavItem from './NavItem';

const SponsoredNav = () => {
  const { list } = useSelector((state) => state.sponsors);

  return (
    <section className="md:block hidden">
      <h3 className="px-3 mt-5 mb-2 font-oswald text-xs text-grey-400 uppercase">
        Sponsored Surveys
      </h3>
      <nav>
        {list.map(({ id, name, url }, i) => (
          <NavItem to={url} label={name} key={`nv-${id}-${i}`} />
        ))}
      </nav>
    </section>
  );
};

export default SponsoredNav;
