import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from '@/config/axios';
import Joi from 'joi';

import SponsoredHeader from '@/components/SponsoredHeader';
import LoadingEllipsis from '@/components/LoadingEllipsis';
import TaskCard from '@/components/TaskCard';
import { string } from 'prop-types';

const SponsoredSurveyPage = () => {
  const { slug } = useParams();
  const history = useHistory();
  const { list } = useSelector((state) => state.sponsors);
  const { userId } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [sponsor, setSponsor] = useState(null);
  const [tasks, setTasks] = useState([]);

  const getSurvey = async () => {
    setLoading(true);
    setSponsor(null);
    setTasks([]);

    const sponsorMatch = list.find((item) => slug === item.slug);

    // If there's no sponsor match
    if (!sponsorMatch) {
      setTasks([]);
      setLoading(false);
      return;
    }

    // Schema for the sponsor object from server
    const sponsorSchema = Joi.object({
      name: Joi.string(),
      id: Joi.string(),
      slug: Joi.string(),
      url: Joi.string(),
    });

    const { error } = sponsorSchema.validate(sponsorMatch);

    // If there's an error, show the error message
    /*if (error) {
      setTasks([]);
      setLoading(false);
      return;
    }*/

    // Otherwise, it's valid. Get the surveys
    const { id } = sponsorMatch;
    try {
      const { data } = await get('v1/task/load', {
        sponsor: id,
        user_id: userId,
      });
      if (data?.data?.tasks) {
        setTasks(data.data.tasks);
      }
      setSponsor({ ...sponsorMatch });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSurvey();
  }, [slug]);

  if (loading) {
    return (
      <div className="text-blue flex p-10 md:p-16 justify-center">
        <LoadingEllipsis width={60} />
      </div>
    );
  }

  if (!loading && !tasks.length) {
    return (
      <div className="flex flex-col justify-center items-center">
        <img
          className="w-56 md:w-72"
          src="/img/task/i_c_dkmb_Broken.svg"
          alt="Not found icon"
        />
        <h1 className="text-center text-xl md:text-2xl max-w-lg md:mt-4">
          Sorry, we have no surveys available at this time. Please try again
          later!
        </h1>
      </div>
    );
  }

  return (
    <section className="bg-grey-200">
      <SponsoredHeader sponsor={sponsor} />
      <section className="my-8 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {tasks.map((task, i) => (
          <TaskCard task={task} key={`tsk-${i}`} />
        ))}
      </section>
    </section>
  );
};

export default SponsoredSurveyPage;
