import PropTypes from 'prop-types';

const PageHeader = ({ title, small = false, className = '' }) => {
  return (
    <header
      className={`${className} text-center px-6  bg-grey-200 font-montserrat`}
    >
      {small ? <div className="text-2xl">{title}</div> : <h1>{title}</h1>}
      <div
        className={`${small ? '' : 'md:mt-4'} bg-blue h-1.5 w-12 mt-2 mx-auto`}
      />
    </header>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  small: PropTypes.bool,
};

export default PageHeader;
