import PropTypes from 'prop-types';

const ProfilerSkeleton = ({ innerOnly = false }) => (
  <div className="animate-pulse max-w-3xl mx-auto p-8">
    {!innerOnly && (
      <>
        <div className="h-4 rounded-full mb-6 bg-grey-250" />
        <div className="h-7 w-56 mx-auto rounded bg-grey-250" />
      </>
    )}

    <div className="h-10 w-full my-6 rounded bg-grey-250" />
    <div className="h-10 w-full my-6 rounded bg-grey-250" />
    <div className="h-10 max-w-md mx-auto my-6 rounded bg-grey-250" />

    {!innerOnly && (
      <div className="flex justify-between my-5">
        <div className="h-10 w-32  rounded bg-grey-250" />
        <div className="h-10 w-32 rounded bg-grey-250" />
      </div>
    )}
  </div>
);

ProfilerSkeleton.propTypes = {
  innerOnly: PropTypes.bool,
};

export default ProfilerSkeleton;
