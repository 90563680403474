import PropTypes from 'prop-types';

const Fieldset = ({ title, children, className = '' }) => {
  return (
    <section className={`p-5 rounded-lg border border-grey-275 ${className}`}>
      <div className="text-xl font-montserrat font-bold">{title}</div>
      <div>{children}</div>
    </section>
  );
};

Fieldset.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Fieldset;
