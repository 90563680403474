import PropTypes from 'prop-types';
import Button from '@/components/Button';
import TaskDetailHeader from './TaskDetailHeader';

const TaskSomethingWrong = ({ content, onClose }) => {
  const task = {
    name: 'Oops, something went wrong!',
    design_class: 'Broken',
  };

  return (
    <div className="task-single bg-grey-50">
      <TaskDetailHeader task={task} onClose={onClose} />
      <section className="mt-4">
        <p
          className="px-5 md:px-8 md:text-lg text-center"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </section>

      <footer className="p-5 pt-8 md:p-8">
        <Button to="/dashboard" variant="success" className="w-full">
          Return to Dashboard
        </Button>
      </footer>
    </div>
  );
};

TaskSomethingWrong.propTypes = {
  content: PropTypes.string.isRequired,
  onCheck: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TaskSomethingWrong;
