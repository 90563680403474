import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  updateUserPoints,
  setAddPoints,
  setHasCollectedPoints,
} from '@/store/slices/userSlice';
import CountAnimation from './CountAnimation';
import CoinsAnimation from './CoinsAnimation';

import usePortal from '@/hooks/usePortal';

const PointsCount = ({ refEl }) => {
  const { points, addPoints } = useSelector((state) => state.user);
  const [animate, setAnimate] = useState('idle');
  const [posRight, setPosRight] = useState(180);
  const currentRef = useRef();

  const dispatch = useDispatch();

  const pointsPlural = points && points > 1 ? 's' : '';
  const isAnimating = animate === 'running';

  const positionCounter = () => {
    if (!refEl.current || !currentRef.current) return;
    const ballot = document.querySelector('#ballot');
    const { left } = ballot.getBoundingClientRect();
    setPosRight(window.innerWidth - left);
  };

  useEffect(() => {
    if (addPoints) {
      setAnimate('running');
      dispatch(updateUserPoints(addPoints));
    }
  }, [addPoints]);

  useEffect(() => {
    if (animate === 'complete') {
      dispatch(setHasCollectedPoints(true));
      setAnimate('idle');
      setTimeout(() => {
        dispatch(setAddPoints(null));
        dispatch(setHasCollectedPoints(false));
      }, 200);
    }
  }, [animate]);

  useEffect(() => {
    if (currentRef.current && refEl.current) {
      positionCounter();
    }
  }, [currentRef.current, refEl.current]);

  useEffect(() => {
    //
    window.addEventListener('resize', positionCounter);
    return () => window.removeEventListener('resize', positionCounter);
  }, []);

  return createPortal(
    !points ? (
      <></>
    ) : (
      <>
        <div
          className={`h-12 pt-1 fixed top-0 z-20 transition-colors duration-200  ${
            isAnimating ? 'z-full bg-white rounded-full ' : ''
          }`}
          style={{ right: posRight }}
          id="points"
          ref={currentRef}
        >
          <Link to="/rewards" className="font-bold flex items-center px-3 h-10">
            <div className="mr-1 h-2 rounded-full bg-green w-2" />
            <div className="text-sm sm:text-base">
              <CountAnimation start={points} points={addPoints} delay={400} />{' '}
              Pt
              {pointsPlural}
            </div>
          </Link>
        </div>

        {isAnimating && (
          <CoinsAnimation onDone={() => setAnimate('complete')} />
        )}
      </>
    ),
    usePortal('points-root')
  );
};

PointsCount.propTypes = {
  refEl: PropTypes.any.isRequired,
};

export default PointsCount;
