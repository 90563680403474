import { InView } from 'react-intersection-observer';
import DecoratedHeading from '@/components/DecoratedHeading';
import WomanMoney from '@/assets/img/woman-money.png?webp';
import Img from 'react-optimized-image';
import bckgImg from '@/assets/img/c-pattern.png?webp';

const AboutHow = () => {
  return (
    <InView triggerOnce={true} threshold={0.25}>
      {({ inView, ref }) => (
        <div ref={ref} className="relative">
          <img
            src={bckgImg}
            alt=""
            className="absolute right-10 bottom-10 md:bottom-auto md:top-10 w-80"
          />
          <section className="max-w-7xl mx-auto px-6 pt-12 md:pt-20 flex flex-col md:flex-row items-center">
            <div
              className={`${
                inView ? 'animate-2' : ''
              } animate md:w-1/2 mt-5 md:mt-0`}
            >
              <DecoratedHeading h={2} label="How Do You Participate?" />
              <p className="text-lg my-6 text-center md:text-left px-6 md:px-0">
                Joining Communa is simple and free. Fill out the registration
                form, confirm your membership via email, and start earning
                rewards by sharing your opinions through our surveys. Surveys
                are available anytime, anywhere, through any device.
              </p>
            </div>
            <div
              className={`${
                inView ? 'animate-1' : ''
              } animate md:w-1/2 w-full px-6 md:px-0  flex justify-center`}
            >
              <div className="w-72">
                <Img src={WomanMoney} webp />
              </div>
            </div>
          </section>
        </div>
      )}
    </InView>
  );
};

export default AboutHow;
