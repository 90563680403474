import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import navIcons from '../navIcons';

const NavItem = ({ to, label, className = '', external = false }) => {
  const El = external ? 'a' : NavLink;

  return (
    <El
      className={`text-grey-500 hover:text-grey-900 flex hover:bg-grey-250 transition-colors flex-1 md:flex-auto md:flex-row flex-col md:justify-start justify-center md:h-10 relative duration-200 items-center py-3 md:py-0 md:px-3 md:rounded-r-full ${className}`}
      {...(external
        ? { href: to }
        : { to, activeClassName: 'text-grey-900 bg-grey-250' })}
    >
      {navIcons[label] && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          viewBox={navIcons[label].viewBox}
          className="w-auto h-5 md:h-auto md:w-4.5 md:mr-2.5 mb-1 md:mb-0"
        >
          <path fill="currentColor" d={navIcons[label].path} />
        </svg>
      )}
      <span className="text-sm font-bold">{label}</span>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="5"
        viewBox="0 0 48 80"
        className="md:block hidden absolute right-3"
      >
        <path
          fill="currentColor"
          d="M12.8832539,77.4554509 L45.0632539,45.0554509 C47.9232539,42.2254509 47.9232539,37.7754509 45.0632539,34.8954509 L12.8832539,2.50545092 C10.0232539,-0.374549081 5.99325393,-1.02454908 3.14325393,1.89545092 L1.93325393,3.07545092 C-0.316746073,5.69545092 -0.836746073,8.74545092 1.63325393,11.0154509 C9.56325393,18.9954509 17.4432539,26.9754509 25.3632539,34.9554509 C28.1732539,37.7854509 28.1732539,42.2354509 25.3632539,45.0754509 C17.4332539,53.0554509 9.55325393,61.0354509 1.63325393,68.9654509 C-0.836746073,71.2754509 -0.316746073,74.3254509 1.93325393,76.9054509 L3.14325393,78.1254509 C5.99325393,81.0254509 10.0232539,80.3754509 12.8832539,77.4554509 L12.8832539,77.4554509 Z"
        />
      </svg>
    </El>
  );
};

NavItem.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  className: PropTypes.string,
  external: PropTypes.bool,
};

export default NavItem;
