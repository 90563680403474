import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs/lib/anime.es.js';

const CoinsAnimation = ({ onDone }) => {
  const coinsRef = useRef();
  const [posRect, setPosRect] = useState();

  const definePosAndTarget = () => {
    let x = 0;
    let y = 0;

    const {
      top: pointsTop,
      left: pointsLeft,
      width: pointsWidth,
    } = document.querySelector('#points').getBoundingClientRect();
    const {
      top: coinTop,
      left: coinLeft,
    } = coinsRef.current.getBoundingClientRect();

    x = pointsLeft - coinLeft + pointsWidth / 2 - 10;
    y = pointsTop - coinTop + 34;

    return {
      x,
      y,
    };
  };

  const startAnimation = () => {
    const { x, y } = definePosAndTarget();
    anime({
      targets: '.coin',
      translateX: x,
      translateY: y,
      duration: 600,
      startDelay: 300,
      endDelay: 600,
      easing: 'easeInOutQuad',
      opacity: { value: 0, delay: anime.stagger(125) },
      delay: anime.stagger(100),
      complete: () => {
        onDone();
      },
    });
  };

  const setCoinsInitialPos = () => {
    const coinsStart = document.getElementById('coinsStart');
    if (coinsStart) {
      const { left, top, width, height } = coinsStart.getBoundingClientRect();
      setPosRect({
        left: left + width / 2,
        top: top - height - 20,
      });
    }
  };

  useEffect(() => {
    startAnimation();
  }, [posRect]);

  useEffect(() => {
    setCoinsInitialPos();
  }, []);

  return (
    <div className="fixed inset-0 z-full">
      <div
        ref={coinsRef}
        style={{
          left: posRect?.left || '50%',
          top: posRect?.top || '50%',
        }}
        className=" absolute w-5 h-5 transform"
      >
        {Array.from(Array(15).keys()).map((coin) => (
          <div key={`c${coin}`} className="coin absolute ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              viewBox="0 0 40 38"
            >
              <defs>
                <linearGradient
                  id="group-a"
                  x1="45.623%"
                  x2="19.387%"
                  y1="0%"
                  y2="88.369%"
                >
                  <stop offset="0%" stopColor="#F50" />
                  <stop offset="100%" stopColor="#FF0" />
                </linearGradient>
                <linearGradient
                  id="group-b"
                  x1="90.912%"
                  x2="7.895%"
                  y1="6.354%"
                  y2="90.994%"
                >
                  <stop offset="0%" stopColor="#FF8000" />
                  <stop offset="100%" stopColor="#FFD500" />
                </linearGradient>
                <linearGradient
                  id="group-c"
                  x1="80.615%"
                  x2="11.211%"
                  y1="8.862%"
                  y2="82.079%"
                >
                  <stop offset="0%" stopColor="#FF0" />
                  <stop offset="100%" stopColor="#FF8000" />
                </linearGradient>
                <linearGradient
                  id="group-d"
                  x1="50%"
                  x2="50%"
                  y1="0%"
                  y2="100%"
                >
                  <stop offset="0%" stopColor="#FF0" />
                  <stop offset="100%" stopColor="#FFD500" />
                </linearGradient>
              </defs>
              <g fill="none">
                <circle cx="21" cy="19" r="19" fill="#FF8000" />
                <circle cx="19" cy="19" r="19" fill="url(#group-a)" />
                <circle cx="20" cy="19" r="16" fill="url(#group-b)" />
                <path
                  fill="url(#group-c)"
                  d="M19,38 C29.4934102,38 38,29.4934102 38,19 C38,8.50658975 29.4934102,0 19,0 C8.50658975,0 0,8.50658975 0,19 C0,29.4934102 8.50658975,38 19,38 Z M19,35 C10.163444,35 3,27.836556 3,19 C3,10.163444 10.163444,3 19,3 C27.836556,3 35,10.163444 35,19 C35,27.836556 27.836556,35 19,35 Z"
                />
                <path
                  fill="#FF8000"
                  d="M26.8090909,14.871142 C26.3977537,12.9706637 24.883135,11.4899576 22.95,11.0984568 C22.7909091,11.0716049 22.6272727,11.0447531 22.4636364,11.0268519 L22.4454545,11.0268519 C22.2863636,11.0134259 22.1227273,11 21.9590909,11 L17,11 L17,28.5790123 L20.9681818,28.5790123 L20.9681818,27.1111111 L18.9818182,27.1111111 L18.9818182,25.6476852 L20.9681818,25.6476852 L20.9681818,24.1842593 L18.9818182,24.1842593 L18.9818182,20.7651235 L21.9590909,20.7651235 C22.1227273,20.7651235 22.2863636,20.7561728 22.4454545,20.7427469 L22.4636364,20.7427469 C22.6272727,20.7203704 22.7909091,20.6979938 22.95,20.6666667 C25.2584319,20.2015106 26.9165298,18.2024806 26.918212,15.8825617 C26.9190572,15.5425305 26.8824798,15.2034084 26.8090909,14.871142 L26.8090909,14.871142 Z M22.95,18.6438272 C22.6323074,18.7570128 22.2969427,18.8145687 21.9590909,18.8139006 L18.9818182,18.8139006 L18.9818182,12.9556981 L21.9590909,12.9556981 C22.2969427,12.95503 22.6323074,13.012586 22.95,13.1257716 C24.1376756,13.5390447 24.9317515,14.6445705 24.9317515,15.8847994 C24.9317515,17.1250283 24.1376756,18.2305541 22.95,18.6438272 L22.95,18.6438272 Z"
                />
                <path
                  fill="url(#group-d)"
                  d="M24.8090909,14.871142 C24.3977537,12.9706637 22.883135,11.4899576 20.95,11.0984568 C20.7909091,11.0716049 20.6272727,11.0447531 20.4636364,11.0268519 L20.4454545,11.0268519 C20.2863636,11.0134259 20.1227273,11 19.9590909,11 L15,11 L15,28.5790123 L18.9681818,28.5790123 L18.9681818,27.1111111 L16.9818182,27.1111111 L16.9818182,25.6476852 L18.9681818,25.6476852 L18.9681818,24.1842593 L16.9818182,24.1842593 L16.9818182,20.7651235 L19.9590909,20.7651235 C20.1227273,20.7651235 20.2863636,20.7561728 20.4454545,20.7427469 L20.4636364,20.7427469 C20.6272727,20.7203704 20.7909091,20.6979938 20.95,20.6666667 C23.2584319,20.2015106 24.9165298,18.2024806 24.918212,15.8825617 C24.9190572,15.5425305 24.8824798,15.2034084 24.8090909,14.871142 L24.8090909,14.871142 Z M20.95,18.6438272 C20.6323074,18.7570128 20.2969427,18.8145687 19.9590909,18.8139006 L16.9818182,18.8139006 L16.9818182,12.9556981 L19.9590909,12.9556981 C20.2969427,12.95503 20.6323074,13.012586 20.95,13.1257716 C22.1376756,13.5390447 22.9317515,14.6445705 22.9317515,15.8847994 C22.9317515,17.1250283 22.1376756,18.2305541 20.95,18.6438272 L20.95,18.6438272 Z"
                />
                <polygon
                  fill="#FFFF80"
                  points="15 8 15.707 10.293 18 11 15.707 11.707 15 14 14.293 11.707 12 11 14.293 10.293"
                />
              </g>
            </svg>
          </div>
        ))}
      </div>
    </div>
  );
};

CoinsAnimation.propTypes = {
  onDone: PropTypes.func.isRequired,
};

export default CoinsAnimation;
