import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import FormItem from '@/components/FormItem';
import Alert from '@/components/Alert';
import Button from '@/components/Button';
import FormWrapper from '@/components/FormWrapper';
import { post } from '@/config/axios';
import { makeFieldErrors } from '@/utils/helpers';

const PasswordForgotPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    password: null,
    password_1: null,
  });
  const [error, setError] = useState(null);
  const [isReset, setIsReset] = useState(false);
  const [fieldErrors, setFieldErrors] = useState();
  const [formError, setFormError] = useState();

  const getTokenId = () => {
    if (!location.search) return null;

    const token = new URLSearchParams(location.search).get('token_id');

    return token;
  };

  const handleSubmit = async () => {
    const token_id = getTokenId();

    setError(null);
    setLoading(true);

    const postData = {
      ...formData,
      token_id,
    };
    try {
      await post('v1/reset_password/update', postData, {
        speculative: false,
      });
      setIsReset(true);
      setLoading(false);
    } catch (err) {
      const { fieldErrors, formError } = makeFieldErrors(
        err?.response?.data?.errors
      );
      if (fieldErrors) {
        setFieldErrors(fieldErrors);
      }
      if (formError) {
        setFormError(formError);
      }
      if (fieldErrors?.token_id) {
        setFormError(
          'The given URL seems invalid. Please try again or contact us.'
        );
      }
      setLoading(false);
    }
  };

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  if (isReset) {
    return (
      <div>
        <Alert>Your password has been successfully reset!</Alert>
        <div className="text-center text-sm">
          <Link
            to="/login"
            className="hover:text-blue transition-colors duration-200"
          >
            Return to Login Page
          </Link>
        </div>
      </div>
    );
  }

  const isBtnDisabled = !formData.password || !formData.password_1;

  return (
    <div>
      <p className="mb-5 text-center ">Please enter your new password.</p>
      <FormWrapper onSubmit={handleSubmit} error={formError}>
        <FormItem
          placeholder="New Password"
          name="password"
          type="password"
          autoComplete="new-password"
          className="mt-4"
          error={fieldErrors?.password}
          onChange={handleOnChange}
        />
        <FormItem
          placeholder="Confirm Password"
          name="password_1"
          type="password"
          autoComplete="new-password"
          className="my-4"
          error={fieldErrors?.password_1}
          onChange={handleOnChange}
        />

        <Button
          loading={loading}
          disabled={isBtnDisabled}
          className="w-full"
          variant="success"
          type="submit"
        >
          Submit
        </Button>
      </FormWrapper>
      <div className="mt-3 text-right">
        <Link
          to="/login"
          className="text-blue hover:text-blue-400 transition-colors duration-200 font-bold"
        >
          Cancel
        </Link>
      </div>
    </div>
  );
};

export default PasswordForgotPage;
