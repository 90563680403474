import { createSlice } from '@reduxjs/toolkit';

export const configSlice = createSlice({
  name: 'config',
  initialState: {
    showGlobalCurtain: false,
    isOnline: true,
    samplechain: null,
    lockAppToDashboard: false,
    cmpn: null,
  },
  reducers: {
    setShowGlobalCurtain: (state, { payload }) => {
      state.showGlobalCurtain = payload;
    },
    setIsOnline: (state, { payload }) => {
      state.isOnline = payload;
    },

    setSamplechain: (state, { payload }) => {
      state.samplechain = payload;
    },

    setLockAppToDashboard: (state, { payload }) => {
      state.lockAppToDashboard = payload;
    },
    setCmpn: (state, { payload }) => {
      state.cmpn = payload;
    },
  },
});

export const {
  setShowGlobalCurtain,
  setIsOnline,
  setSamplechain,
  setLockAppToDashboard,
  setCmpn,
} = configSlice.actions;

export default configSlice.reducer;
