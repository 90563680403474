import Button from '@/components/Button';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

const Navigation = ({
  showPrevious,
  showNext,
  onNext,
  onPrevious,
  previousLoading = false,
  nextLoading = false,
}) => {
  const nextRef = useRef();
  const handleOnKeyPress = (e) => {
    if (e.keyCode === 13) {
      nextRef.current();
    }
  };

  // Whaat?? -- This is because event listeners belong to inital render and
  // never re-render. Here we want to make sure we have the latest state of onNext()
  // so we store it in a ref to use it later.
  useEffect(() => {
    nextRef.current = onNext;
  }, [onNext]);

  useEffect(() => {
    window.addEventListener('keypress', handleOnKeyPress);
    return () => window.removeEventListener('keypress', handleOnKeyPress);
  }, []);

  return (
    <footer className="flex justify-between mt-8">
      {showPrevious ? (
        <Button
          variant="outline"
          disabled={nextLoading}
          loading={previousLoading}
          onClick={onPrevious}
        >
          Previous
        </Button>
      ) : (
        <div />
      )}
      <Button
        variant="success"
        loading={nextLoading}
        disabled={!showNext || previousLoading}
        onClick={onNext}
      >
        Next
      </Button>
    </footer>
  );
};

Navigation.propTypes = {
  showPrevious: PropTypes.bool.isRequired,
  showNext: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  previousLoading: PropTypes.bool,
  nextLoading: PropTypes.bool,
};

export default Navigation;
