import PropTypes from 'prop-types';
import Button from '@/components/Button';
import TaskDetailHeader from './TaskDetailHeader';

const TaskError = ({ task, onClose }) => {
  const errorTask = {
    ...task,
    design_class: 'Broken',
    name: 'Oops!',
  };

  const emailVerificationRequired =
    task.task_status === 'email_verification_required';

  const taskErrorMsg = `Before taking this survey please confirm your ${
    emailVerificationRequired ? 'email' : 'phone number'
  } on your account.`;

  return (
    <div className="task-single bg-grey-50">
      <TaskDetailHeader task={errorTask} onClose={onClose} />
      <p className="px-8 mt-6 text-lg text-center">{taskErrorMsg}</p>
      <footer className="p-8">
        <Button onClick={onClose} variant="success" className="w-full">
          Back to Surveys
        </Button>
      </footer>
    </div>
  );
};

TaskError.propTypes = {
  onClose: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
};

export default TaskError;
