import PropTypes from 'prop-types';
import FormRadio from '@/components/FormRadio';
import FormItem from '@/components/FormItem';
import Row from './Row';
import { useState, useEffect } from 'react';

const OptionsRadio = ({ data, onChange }) => {
  const { answer_set, id } = data;
  const [allowText, setAllowText] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOnChange = () => {
    if (!selectedOption) return;

    if (!selectedOption.text) {
      setInputValue('');
    }

    setAllowText(!!selectedOption.text);

    if (selectedOption.text && !inputValue) {
      onChange();
      return;
    }

    onChange({
      [selectedOption.id]:
        !!selectedOption.text && inputValue ? inputValue : true,
    });
  };

  const selectOption = (e) => {
    const value = answer_set.find(({ id }) => id === e.target.value);
    setSelectedOption(value);
  };

  useEffect(() => {
    handleOnChange();
  }, [selectedOption, inputValue]);

  useEffect(() => {
    setInputValue('');
    setSelectedOption(null);
    setAllowText(false);
  }, [data]);

  return (
    <section>
      {answer_set.map(({ id: value, label, text }, i) => (
        <Row key={`ro-${i}`}>
          <FormRadio
            name={`rd-${id}`}
            label={label}
            value={value}
            onChange={selectOption}
            className="py-4"
          />
          {text && (
            <FormItem
              value={inputValue}
              disabled={!allowText}
              className="mt-2 ml-8 py-4"
              onChange={(e) => setInputValue(e.target.value)}
            />
          )}
        </Row>
      ))}
    </section>
  );
};

OptionsRadio.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OptionsRadio;
