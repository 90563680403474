import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { updateUserData } from '@/store/slices/userSlice';
import { logAnalyticEvent } from '@/config/analytics';
import { post } from '@/config/axios';
import TaskInfo from './TaskInfo';
import Button from '@/components/Button';
import TaskDetailHeader from './TaskDetailHeader';
import FormItem from '@/components/FormItem';
import Alert from '@/components/Alert/Alert';

const TaskCheckPhone = ({ task, onClose }) => {
  const dispatch = useDispatch();

  const { userId } = useSelector((state) => state.user);
  const [phone, setPhone] = useState('');
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showStep1, setShowStep1] = useState(false);
  const [showStep2, setShowStep2] = useState(false);

  const { points, featured, task_type } = task;

  const taskClone = {
    ...task,
    name: 'Phone Number Verification',
  };

  const handleSubmit = () => {
    if (showStep2) {
      postPin();
      return;
    }
    if (showStep1) {
      postPhone();
      return;
    }
    setShowStep1(true);
  };

  const postPin = async () => {
    setError('');
    setLoading(true);
    try {
      const { data } = await post('v1/phone-verification/validate-pin', null, {
        user_id: userId,
        phone,
        pin,
      });
      dispatch(updateUserData({ phone }));
      setLoading(false);
      onClose();
    } catch (err) {
      const errorMsg =
        err?.response?.data?.errors && err?.response?.data?.errors.length
          ? err.response.data.errors[0].message
          : 'Something went wrong, please try again';
      setError(errorMsg);
      setLoading(false);
    }
  };

  const postPhone = async (call = false) => {
    setError('');
    setLoading(true);
    try {
      await post('v1/phone-verification/send-pin-for-phone', null, {
        user_id: userId,
        phone,
        call,
      });

      setShowStep1(false);
      setShowStep2(true);
      setLoading(false);
    } catch (err) {
      const errorMsg =
        err?.response?.data?.errors && err?.response?.data?.errors.length
          ? err.response.data.errors[0].message
          : 'Something went wrong, please try again';
      setError(errorMsg);
      setLoading(false);
    }
  };

  const modalText = () => {
    if (showStep2) {
      return "Code sent to your phone number!<br />Enter the code below so we know you're a real person.";
    }
    if (showStep1) {
      return 'Enter your phone number (without country code) below so we can send you an SMS to get started.';
    }
    return "Verify your phone number with us so we know you're a real person.";
  };

  const btnLabel = () => {
    if (showStep2) {
      return 'Verify PIN';
    }
    if (showStep1) {
      return 'Send';
    }
    return 'I Understand, Please Proceed';
  };

  const disabledState = (showStep2 && !pin) || (showStep1 && !phone);

  useEffect(() => {
    logAnalyticEvent('verify_phone', 'start', {
      task_type,
      featured,
      points,
    });
  }, []);

  return (
    <div className="task-single bg-grey-50">
      <TaskDetailHeader task={taskClone} onClose={onClose} />

      <section className={showStep1 || showStep2 ? 'mt-4' : ''}>
        {!showStep1 && !showStep2 && <TaskInfo points={points} />}
        <p
          className="px-5 md:px-8 text-lg text-center"
          dangerouslySetInnerHTML={{ __html: modalText() }}
        />

        <div className="px-5 md:px-8 mx-auto mt-4 flex flex-col items-center">
          {error && (
            <div className="flex-1 w-full">
              <Alert type="error">{error}</Alert>
            </div>
          )}
          {showStep2 && (
            <div className="flex flex-col items-center">
              <FormItem
                name="pin"
                value={pin}
                placeholder="1234"
                onChange={(e) => setPin(e.target.value)}
                className="w-32"
              />
              <div className="mt-1">
                <button
                  onClick={() => postPhone(true)}
                  className="mr-3 text-xs text-blue font-bold uppercase hover:text-blue-400 active:text-blue-600 transition-colors duration-200"
                >
                  Use Voice
                </button>
                <button
                  onClick={() => postPhone()}
                  className="text-xs text-blue font-bold uppercase hover:text-blue-400 active:text-blue-600 transition-colors duration-200"
                >
                  Resend Code
                </button>
              </div>
            </div>
          )}
          {showStep1 && (
            <FormItem
              name="phone"
              value={phone}
              placeholder="000-000-0000"
              onChange={(e) => setPhone(e.target.value)}
              className="w-56"
            />
          )}
        </div>
      </section>

      <footer className="p-5 pt-8 md:p-8">
        <Button
          onClick={handleSubmit}
          variant="success"
          className="w-full"
          disabled={disabledState}
          loading={loading}
        >
          {btnLabel()}
        </Button>
      </footer>
    </div>
  );
};

TaskCheckPhone.propTypes = {
  task: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TaskCheckPhone;
