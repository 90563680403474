import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { post } from '@/config/axios';
import { formatDate } from '@/utils/helpers';
import Fieldset from '@/components/Fieldset';
import Button from '@/components/Button';
import Alert from '@/components/Alert';

const ProfileDownloadData = () => {
  const { userId } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onBtnClick = async () => {
    setLoading(true);
    setHasError(false);

    try {
      const { data } = await post(
        'v1/user-action',
        {
          path: '/profile',
          action: 'export_csv_user_data',
        },
        {
          user_id: userId,
        },
        true
      );
      forceDownloadCsv(data);
      setLoading(false);
    } catch (err) {
      setHasError(true);
      setLoading(false);
    }
  };

  const forceDownloadCsv = (data) => {
    const formattedDate = formatDate(new Date(), 'YYYY-MM-DD_hh-mm-ss');
    const fileName = `communa_data_${formattedDate}.csv`;
    const dlLink = document.createElement('a');
    dlLink.href = window.URL.createObjectURL(new Blob([data]));
    dlLink.setAttribute('download', fileName);
    document.body.appendChild(dlLink);
    dlLink.click();
    document.body.removeChild(dlLink);
  };

  return (
    <Fieldset title="Download Data">
      {hasError && (
        <div className="mt-3">
          <Alert type="error">
            Something went wrong. Please try again. If the issue persists,
            please <Link to="/contact">contact us</Link>.
          </Alert>
        </div>
      )}

      <p className="mt-2 mb-4">Download a copy of your personal information.</p>
      <Button loading={loading} onClick={onBtnClick} variant="outline">
        Download
      </Button>
    </Fieldset>
  );
};

ProfileDownloadData.propTypes = {};

export default ProfileDownloadData;
