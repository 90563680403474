import { Route, Switch, Redirect } from 'react-router-dom';

import AppLayout from '@/layout/AppLayout';
import AuthLayout from '@/layout/AuthLayout';
import BlogLayout from '@/layout/BlogLayout';
import PageLayout from '@/layout/PageLayout';
import DefaultLayout from '@/layout/DefaultLayout';
import SignupLayout from '@/layout/SignupLayout';
import FaqPage from '@/pages/faq';
import PrivacyPage from '@/pages/privacy';
import RewardsPage from '@/pages/rewards';
import TermsPage from '@/pages/terms';
import TimelinePage from '@/pages/timeline';
import PrivacyCaliforniaPage from '@/pages/privacy-policy-ccpa';
import PrivacyCaliforniaPageOptIn from '@/pages/privacy-policy-ccpa-opt-in';
import PersonalInfoPage from '@/pages/do-not-sell-my-personal-information';
import DashboardPage from '@/pages/dashboard';
import ProfilePage from '@/pages/profile';
import LogoutPage from '@/pages/logout';
import LoginPage from '@/pages/login';
import SignupPage from '@/pages/signup';
import EmailVerifiedPage from '@/pages/email-verified';
import AboutPage from '@/pages/about';
import ContactPage from '@/pages/contact';
import PasswordForgotPage from '@/pages/password/forgot';
import PasswordResetPage from '@/pages/password/reset';
import BlogPage from '@/pages/blog';
import BlogArticlePage from '@/pages/blog/article';
import SponsoredSurveyPage from '@/pages/sponsored-surveys/slug';
import Error500Page from '@/pages/error';
import ErrorUhOhPage from '@/pages/uh-oh';
import Error404Page from '@/pages/error404';
import UnsubscribedPage from '@/pages/unsubscribed';
import RootPage from '@/pages/index';

const renderRoutes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <RootPage />
      </Route>
      <Route exact path="/dashboard">
        <AppLayout>
          <DashboardPage />
        </AppLayout>
      </Route>
      <Route exact path="/cash-out" render={() => <Redirect to="/rewards" />} />
      <Route exact path="/rewards">
        <AppLayout>
          <RewardsPage />
        </AppLayout>
      </Route>
      <Route exact path="/timeline">
        <AppLayout>
          <TimelinePage />
        </AppLayout>
      </Route>
      <Route exact path="/profile">
        <AppLayout>
          <ProfilePage />
        </AppLayout>
      </Route>
      <Route exact path="/logout">
        <AppLayout>
          <LogoutPage />
        </AppLayout>
      </Route>
      <Route path="/sponsored-surveys/:slug">
        <AppLayout>
          <SponsoredSurveyPage />
        </AppLayout>
      </Route>
      <Route exact path="/login">
        <AuthLayout>
          <LoginPage />
        </AuthLayout>
      </Route>
      <Route exact path="/signup">
        <SignupLayout>
          <SignupPage />
        </SignupLayout>
      </Route>
      <Route exact path="/about">
        <PageLayout>
          <AboutPage />
        </PageLayout>
      </Route>
      <Route exact path="/password/forgot">
        <AuthLayout>
          <PasswordForgotPage />
        </AuthLayout>
      </Route>
      <Route exact path="/reset_password">
        <AuthLayout>
          <PasswordResetPage />
        </AuthLayout>
      </Route>
      {/* Pages */}
      <Route exact path="/faq">
        <PageLayout>
          <FaqPage />
        </PageLayout>
      </Route>
      <Route exact path="/privacy">
        <PageLayout>
          <PrivacyPage />
        </PageLayout>
      </Route>
      <Route exact path="/contact">
        <PageLayout>
          <ContactPage />
        </PageLayout>
      </Route>
      <Route exact path="/privacy-policy-ccpa">
        <PageLayout>
          <PrivacyCaliforniaPage />
        </PageLayout>
      </Route>
      <Route exact path="/privacy-policy-ccpa-opt-in">
        <PageLayout>
          <PrivacyCaliforniaPageOptIn />
        </PageLayout>
      </Route>
      <Route exact path="/do-not-sell-my-personal-information">
        <PageLayout>
          <PersonalInfoPage />
        </PageLayout>
      </Route>
      <Route exact path="/terms">
        <PageLayout>
          <TermsPage />
        </PageLayout>
      </Route>
      <Route exact path="/blog">
        <BlogLayout>
          <BlogPage />
        </BlogLayout>
      </Route>
      <Route exact path="/blog/:id/:slug">
        <BlogLayout>
          <BlogArticlePage />
        </BlogLayout>
      </Route>
      <Route exact path="/uh-oh">
        <DefaultLayout>
          <ErrorUhOhPage />
        </DefaultLayout>
      </Route>
      <Route exact path="/something-went-wrong">
        <DefaultLayout>
          <Error500Page />
        </DefaultLayout>
      </Route>
      <Route exact path="/unsubscribed">
        <PageLayout>
          <UnsubscribedPage />
        </PageLayout>
      </Route>
      <Route exact path="/email-verified">
        <PageLayout>
          <EmailVerifiedPage />
        </PageLayout>
      </Route>
      <Route path="*" status={404}>
        <DefaultLayout>
          <Error404Page />
        </DefaultLayout>
      </Route>
    </Switch>
  );
};

export default renderRoutes;
