import React from 'react';
import { Helmet } from 'react-helmet-async';

const Head = () => (
  <Helmet>
    {/* META TAGS */}
    <meta name="title" content="Communa: Give Your Opinion. Earn Rewards" />
    <meta
      name="description"
      content="Communa is an online community where opinions matter! Members are able to voice their thoughts and ideas, and get rewarded for doing so. Communa members also have the added benefit of giving feedback anytime and anywhere."
    />
    <meta
      name="abstract"
      content="Communa is an online community where opinions matter! Members are able to voice their thoughts and ideas, and get rewarded for doing so. Communa members also have the added benefit of giving feedback anytime and anywhere."
    />
    <meta
      name="image"
      content="https://dp0oqu0ryo1g.cloudfront.net/communa-neo-prod/2021.09.10.20.19.23/static/images/og_communa_logo.jpg"
    />

    {/* OPEN GRAPH TAGS */}
    <meta
      property="og:title"
      content="Communa: Give Your Opinion. Earn Rewards"
    />
    <meta property="og:url" content="https://www.communa.com/" />
    <meta
      property="og:image"
      content="https://dp0oqu0ryo1g.cloudfront.net/communa-neo-prod/2021.09.10.20.19.23/static/images/og_communa_logo.jpg"
    />
    <meta
      property="og:description"
      content="Communa is an online community where opinions matter! Members are able to voice their thoughts and ideas, and get rewarded for doing so. Communa members also have the added benefit of giving feedback anytime and anywhere."
    />
  </Helmet>
);

export default Head;
