import PropTypes from 'prop-types';

const TaskDailyLoginUnlocked = ({ points }) => {
  return (
    <div className="flex items-center justify-center mt-6 mb-2">
      {Array.from(Array(5).keys()).map((i) => (
        <div
          className="relative flex items-center justify-center"
          key={`lk-${i}`}
        >
          <div
            className={`${
              i + 1 <= points
                ? 'bg-blue text-white'
                : 'bg-grey-250 text-grey-800'
            } w-9 h-9 md:w-12 md:h-12 rounded-full flex items-center justify-center relative`}
          >
            <div>{i + 1}</div>
            <div
              className={`absolute w-5 h-5 -right-0.5 flex items-center justify-center -bottom-0.5 rounded-full ${
                i + 1 <= points
                  ? 'bg-green'
                  : 'bg-white border-2 border-grey-800'
              }`}
            >
              {i + 1 <= points ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  viewBox="0 0 59 80"
                >
                  <path
                    fill="#FFF"
                    d="M51.8,30.3 C50.3,29.6 48.6,29.2 46.8,29.2 L14.7,29.2 L14.7,22.4 C14.7,14.3 21.3,7.7 29.4,7.7 C30.6,7.7 31.8,7.8 32.9,8.1 C34.8,8.6 36.7,7.6 37.4,5.8 L37.4,5.8 C38.3,3.6 37,1.2 34.7,0.6 C33,0.2 31.2,0 29.4,0 L29.4,0 C17,0 7,10 7,22.4 L7,30.3 C2.8,32.2 3.55271368e-15,36.4 3.55271368e-15,41.2 L3.55271368e-15,68 C3.55271368e-15,72.9 2.9,77 7,78.9 C8.5,79.6 10.2,80 12,80 L14.7,80 L46.8,80 C48.6,80 50.2,79.6 51.8,78.9 C56,77 58.8,72.8 58.8,68 L58.8,41.2 C58.8,36.4 55.9,32.2 51.8,30.3 Z M34,51.8 C33.7,52.3 33.6,52.9 33.6,53.5 L33.6,58.2 C33.6,60.5 31.7,62.3 29.5,62.3 L29.5,62.3 C27.2,62.3 25.4,60.4 25.4,58.2 L25.4,53.5 C25.4,52.9 25.2,52.4 25,51.8 C24.6,51 24.3,50.1 24.4,49.1 C24.5,46.5 26.6,44.4 29.2,44.2 C32.2,44 34.7,46.4 34.7,49.4 C34.6,50.3 34.4,51.1 34,51.8 Z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  viewBox="0 0 39 50"
                >
                  <path
                    fill="#323943"
                    d="M35.6,20.2 L34.6,20.2 L34.6,15.6 C34.6,7.2 27.9,0.1 19.6,0 C19.4,0 18.9,0 18.7,0 C10.3,0.2 3.7,7.2 3.7,15.6 L3.7,20.2 L2.7,20.2 C1.2,20.2 -2.84217094e-14,21.7 -2.84217094e-14,23.7 L-2.84217094e-14,46.5 C-2.84217094e-14,48.4 1.2,50 2.7,50 L35.6,50 C37.1,50 38.3,48.4 38.3,46.5 L38.3,23.6 C38.3,21.7 37.1,20.2 35.6,20.2 L35.6,20.2 Z M22.2,35 L22.2,41.9 C22.2,42.7 21.5,43.4 20.7,43.4 L17.4,43.4 C16.6,43.4 15.9,42.7 15.9,41.9 L15.9,35 C15.1,34.2 14.7,33.2 14.7,32 C14.7,29.8 16.4,27.9 18.5,27.8 C18.7,27.8 19.2,27.8 19.4,27.8 C21.6,27.9 23.2,29.8 23.2,32 C23.5,33.2 23,34.3 22.2,35 L22.2,35 Z M28.2,20.2 L10.1,20.2 L10.1,15.6 C10.1,10.6 14.2,6.4 19.2,6.4 C24.2,6.4 28.3,10.5 28.3,15.6 L28.3,20.2 L28.2,20.2 Z"
                  />
                </svg>
              )}
            </div>
          </div>
          {i < 4 && (
            <div
              className={`${
                i + 1 < points ? 'bg-blue' : 'bg-grey-250'
              } w-3 h-1`}
            />
          )}
        </div>
      ))}
    </div>
  );
};

TaskDailyLoginUnlocked.propTypes = {
  points: PropTypes.number.isRequired,
};

export default TaskDailyLoginUnlocked;
