import { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import Img from 'react-optimized-image';
import HeroImg from './components/HeroImg';
import SignForm from './components/SignForm';
import { css } from '@emotion/css';

import axios from '@/config/axios';
import RewardsCards from '@/assets/img/reward-cards.png';

const SignupHero = () => {
  const [heroImgVersion, setHeroImgVersion] = useState('');

  const VARIANT_IMAGE_LOOKUP = {
    mobile: {
      '211021_a': 'girl-on-green-background-mobile.jpg',
      '211021_b': 'group-feet-background-mobile.jpg',
    },
    desktop: {
      '211021_a': 'girl-on-green-background-desktop.jpg',
      '211021_b': 'group-feet-background-desktop.jpg',
    },
  };
  const headerStyles = css`
    .background-container {
      ${heroImgVersion === 'default' && 'opacity: 0;'}

      ${heroImgVersion &&
      VARIANT_IMAGE_LOOKUP['mobile'][heroImgVersion] &&
      `background-image: url('/img/hero/${VARIANT_IMAGE_LOOKUP['mobile'][heroImgVersion]}');`}
      
      height: 861px;

      @media only screen and (min-width: 475px) {
        height: 900px;
      }

      @media only screen and (min-width: 575px) {
        height: 1100px;
      }

      @media only screen and (min-width: 640px) {
        height: auto;
        padding-top: 68%;
      }

      @media only screen and (min-width: 768px) {
        padding-top: 83%;
        ${heroImgVersion &&
        VARIANT_IMAGE_LOOKUP['desktop'][heroImgVersion] &&
        `background-image: url('/img/hero/${VARIANT_IMAGE_LOOKUP['desktop'][heroImgVersion]}');`}
      }

      @media only screen and (min-width: 1024px) {
        padding-top: 70%;
      }
      @media only screen and (min-width: 1280px) {
        padding-top: 57%;
      }
      @media only screen and (min-width: 1536px) {
        padding-top: 49%;
      }
    }

    .hero-img-wrapper {
      opacity: 0;

      &.default {
        opacity: 1;
      }
    }

    .cards-section {
      h1 {
        color: #fff;
      }
      &.default {
        h1 {
          color: #323843;
          span {
            color: #1494d6;
          }
        }
      }
    }
  `;

  const getTestingVariant = async () => {
    const { data } = await axios().get('/v1/a-b-variant/get', {
      params: {
        version: '3.0.0',
      },
    });

    // If there's a result, set it in local storage
    if (data?.data) {
      localStorage.setItem('testing-variants', JSON.stringify(data.data));
      if (data.data?.signup_page_image) {
        displayVariantHero(data.data.signup_page_image);
        return;
      }
    }

    // Otherwise, set it as the default background
    setHeroImgVersion('default');
  };

  const displayVariantHero = (signupPageImgObj = {}) => {
    if (signupPageImgObj?.variant_id) {
      console.log('weaseal', signupPageImgObj.variant_id);
      setHeroImgVersion(signupPageImgObj.variant_id);
      return;
    }
    // Otherwise, set it as the default background
    setHeroImgVersion('default');
  };

  useEffect(() => {
    //  TEMP: Use default background for now. Remove setHeroImgVersion('default'); later to use A/B testing
    setHeroImgVersion('default');

    // TEMP: Un-comment below this to get the A/B testing back
    /*
    // Try and get the testing variant from localStorage
    const testingVariants = JSON.parse(
      localStorage.getItem('testing-variants')
    );

    // If existing testing variant data for the signup_page_image
    if (testingVariants?.signup_page_image) {
      displayVariantHero(testingVariants.signup_page_image);

      // Otherwise, get the testing variant from the API
    } else {
      getTestingVariant();
    }
    */
  }, []);

  return (
    <InView triggerOnce={true} threshold={0.25}>
      {({ inView, ref }) => (
        <div className={`${headerStyles} w-full relative`}>
          <div className="w-full bg-cover bg-center absolute background-container"></div>
          <header ref={ref} className="pt-8 px-6 max-w-5xl mx-auto">
            <section
              className={`${
                inView ? 'animate-0' : ''
              } animate flex md:flex-row flex-col-reverse items-center`}
            >
              <div className="w-full pt-6 md:pt-0 md:pr-8">
                <div className={`hero-img-wrapper ${heroImgVersion}`}>
                  <HeroImg />
                </div>
              </div>
              <div className="md:max-w-xs md:p-0 w-full">
                <SignForm />
              </div>
            </section>
            <section
              className={`flex flex-col items-center mt-12 cards-section ${heroImgVersion}`}
            >
              <h1
                className={`${inView ? 'animate-1' : ''} animate text-2xl mb-0`}
              >
                {heroImgVersion ? (
                  <>
                    Give Your Opinion. <span>Earn Rewards</span>
                  </>
                ) : (
                  <>&nbsp;</>
                )}
              </h1>
              <Img
                src={RewardsCards}
                className={`${inView ? 'animate-2' : ''} animate`}
                webp
              />
            </section>
          </header>
        </div>
      )}
    </InView>
  );
};

export default SignupHero;
