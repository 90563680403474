const TaskFeatured = () => (
  <div className="top-0 right-0 absolute z-1">
    <svg xmlns="http://www.w3.org/2000/svg" width="36" viewBox="0 0 100 100">
      <path
        fill="#E88820"
        d="M100,0 L100,100 L0,0 L100,0 Z M69.3,12.9 L64.7,24.9 L51.8,25.5 C49.9,25.6 49.5,26.6 51,27.8 L61.1,35.8 L59.1592593,42.9407407 L58.2481029,46.2430639 L58.2481029,46.2430639 L57.7,48.2 L57.7,48.2 C57.2151515,49.9454545 58.0467401,50.6565657 59.5564933,49.6950608 L60.1309796,49.3199354 L60.1309796,49.3199354 L70.5,42.6 L70.5,42.6 L81.3,49.6 C82.9,50.6 83.8,50 83.3,48.2 L80,35.8 L90.1,27.8 C91.6,26.6 91.2,25.5 89.3,25.5 L76.4,24.9 L71.8,12.9 C71.1,11.1 70,11.1 69.3,12.9 Z"
      />
      <path
        fill="#FFF"
        d="M69.3,12.9 L64.7,24.9 L51.8,25.5 C49.9,25.6 49.5,26.6 51,27.8 L61.1,35.8 L59.1592593,42.9407407 L58.2481029,46.2430639 L58.2481029,46.2430639 L57.7,48.2 L57.7,48.2 C57.2151515,49.9454545 58.0467401,50.6565657 59.5564933,49.6950608 L60.1309796,49.3199354 L60.1309796,49.3199354 L70.5,42.6 L70.5,42.6 L81.3,49.6 C82.9,50.6 83.8,50 83.3,48.2 L80,35.8 L90.1,27.8 C91.6,26.6 91.2,25.5 89.3,25.5 L76.4,24.9 L71.8,12.9 C71.1,11.1 70,11.1 69.3,12.9 Z"
      />
    </svg>
  </div>
);

export default TaskFeatured;
