import PropTypes from 'prop-types';

const Title = ({ data }) => {
  const { heading, description, question_type, type_specific_config } = data;

  const setSubtitle = () => {
    if (description) {
      return description;
    }
    if (question_type === 'option' && type_specific_config?.multi) {
      return 'Please select all that apply';
    }
    if (question_type === 'option') {
      return 'Select one';
    }
    if (question_type === 'likert_matrix') {
      return 'Select one in each row';
    }
    return false;
  };

  return (
    <section className="text-center my-5">
      <h1
        className="text-xl mb-0"
        dangerouslySetInnerHTML={{ __html: heading }}
      />
      {setSubtitle() && <div className="mt-1">{setSubtitle()}</div>}
    </section>
  );
};

Title.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Title;
