import { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@/components/Modal';
import TaskModal, { TaskVerifyEmail } from '@/components/TaskModal';

const VerifyEmailModal = ({ onClose }) => {
  const [showCheck, setShowCheck] = useState(false);

  if (showCheck) {
    return <TaskModal taskId="verify_email" source="Goals" onClose={onClose} />;
  }

  return (
    <Modal onClose={onClose}>
      {(onClose) => (
        <TaskVerifyEmail onClose={onClose} onCheck={() => setShowCheck(true)} />
      )}
    </Modal>
  );
};

VerifyEmailModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default VerifyEmailModal;
