import { Redirect } from 'react-router-dom';
import { getToken } from '@/utils/token';

const RootPage = () => {
  if (!!getToken()) {
    return <Redirect to="/dashboard" />;
  }

  const params = window.location.search;

  return <Redirect to={`/signup${params}`} />;
};

export default RootPage;
