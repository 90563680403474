import { createSlice, current } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    dailyLoginRewards: 0,
    logoutSessionId: '',
    csrf: '',
    addPoints: false,
    hasCollectedPoints: false,
    hasCollectedBallot: false,
    userId: null,
    points: null,
    ballots: null,
    nextDraw: null,
    isAdmin: false,
    data: {},
  },
  reducers: {
    setUser: (state, { payload }) => {
      const {
        _id,
        points,
        ballots,
        next_draw,
        ballot_login_point,
        role,
      } = payload;
      state.data = payload;
      state.userId = _id;
      state.points = points;
      state.ballots = ballots;
      state.nextDraw = next_draw;
      state.isAdmin = role === 'user-admin';
      state.dailyLoginRewards = ballot_login_point;
    },
    setHasCollectedPoints: (state, { payload }) => {
      state.hasCollectedPoints = payload;
    },
    setHasCollectedBallot: (state, { payload }) => {
      state.hasCollectedBallot = payload;
    },
    setLogoutSessionId: (state, { payload }) => {
      state.logoutSessionId = payload;
    },
    setCsrf: (state, { payload }) => {
      state.csrf = payload;
    },
    setUserData: (state, { payload }) => {
      state.user = payload;
    },
    updateUserData: (state, { payload }) => {
      state.data = {
        ...state.data,
        ...payload,
      };
    },
    setUserId: (state, { payload }) => {
      state.userId = payload;
    },
    updateUserPoints: (state, { payload }) => {
      state.points = Number(state.points || 0) + Number(payload);
    },
    setAddPoints: (state, { payload }) => {
      state.addPoints = payload;
    },
    setReducePoints: (state, { payload }) => {
      state.points = state.points + payload;
    },
    resetUser: (state) => {
      state.dailyLoginRewards = 0;
      state.logoutSessionId = '';
      state.csrf = '';
      state.addPoints = false;
      state.hasCollectedPoints = false;
      state.hasCollectedBallot = false;
      state.userId = null;
      state.points = null;
      state.ballots = null;
      state.nextDraw = null;
      state.data = {};
    },
  },
});

export const {
  setLogoutSessionId,
  setCsrf,
  setUserData,
  setUserId,
  setUser,
  updateUserData,
  setAddPoints,
  setReducePoints,
  updateUserPoints,
  setHasCollectedPoints,
  setHasCollectedBallot,
  resetUser,
} = userSlice.actions;

export default userSlice.reducer;
