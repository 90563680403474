import PropTypes from 'prop-types';
import withSession from '@/hoc/withSession';
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';

const SignupLayout = ({ children }) => {
  return (
    <div className="bg-grey-200">
      <AppHeader />
      <main className="bg-white">{children}</main>
      <AppFooter />
    </div>
  );
};

SignupLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withSession(SignupLayout);
