import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormItem from '@/components/FormItem';

const OptionText = ({ data, onChange }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <FormItem
      name="textEl"
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

OptionText.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OptionText;
