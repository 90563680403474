import PropTypes from 'prop-types';

const DecoratedHeading = ({
  h = 1,
  label,
  centered = false,
  className = '',
}) => {
  const Heading = `h${h}`;

  return (
    <div>
      <Heading
        className={`${
          centered ? 'text-center' : 'text-center md:text-left'
        } mb-0 ${className}`}
      >
        {label}
      </Heading>
      <div
        className={`h-1 w-10 bg-blue rounded mt-3 ${
          centered ? 'mx-auto' : 'mx-auto md:ml-6'
        }`}
      />
    </div>
  );
};

DecoratedHeading.propTypes = {
  h: PropTypes.number,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  centered: PropTypes.bool,
};

export default DecoratedHeading;
