import { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@/components/Modal';
import { TaskNotEnoughPoints } from '@/components/TaskModal';

const NotEnoughPointsModal = ({ minimumPoints, pointsRequired, onClose }) => {
  return (
    <Modal onClose={onClose}>
      {(onClose) => (
        <TaskNotEnoughPoints
          minimumPoints={minimumPoints}
          pointsRequired={pointsRequired}
          onClose={onClose}
        />
      )}
    </Modal>
  );
};

NotEnoughPointsModal.propTypes = {
  minimumPoints: PropTypes.number.isRequired,
  pointsRequired: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NotEnoughPointsModal;
