import SignupToday from '@/components/SignupToday';
import AboutWhyJoin from '@/components/AboutWhyJoin';
import AboutHow from '@/components/AboutHow';
import AboutHero from '@/components/AboutHero';

const AboutPage = () => {
  return (
    <section className="bg-grey-200">
      <AboutHero />
      <AboutWhyJoin />
      <SignupToday />
      <AboutHow />
    </section>
  );
};

export default AboutPage;
