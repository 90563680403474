import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import PageHeader from '@/components/PageHeader';
import LoadingEllipsis from '@/components/LoadingEllipsis';
import Alert from '@/components/Alert';
import useGetPageContent from '@/hooks/useGetPageContent';

const PageSimple = ({ title, path }) => {
  const { contentData, loading, error } = useGetPageContent(path);

  const seoTitle = title || 'Communa';

  return (
    <>
      <Helmet>
        <title>{seoTitle} | Give Your Opinion. Earn Rewards</title>
      </Helmet>
      <section>
        <PageHeader title={title} className="py-16 md:py-24" />
        <main className="max-w-3xl px-6 mx-auto md:pt-8 pb-16">
          {error && (
            <div className="mt-3">
              <Alert type="error">
                Something went wrong. Please refresh the page. If the issue
                persists, please <Link to="/contact">contact us</Link>.
              </Alert>
            </div>
          )}
          {loading ? (
            <div className="flex justify-center pt-12 pb-6 md:pt-24 md:pb-12 text-blue">
              <LoadingEllipsis width={48} />
            </div>
          ) : (
            <section
              className="page"
              dangerouslySetInnerHTML={{ __html: contentData?.content }}
            />
          )}
        </main>
      </section>
    </>
  );
};

PageSimple.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default PageSimple;
