import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { get } from '@/config/axios';
import { setRefreshTasks } from '@/store/slices/eventsSlice';
import TaskCategory from '@/components/TaskCategory/TaskCategory';
import GlobalLoading from '@/components/GlobalLoading';

const DashboardPage = () => {
  const { refreshTasks } = useSelector((state) => state.events);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [goals, setGoals] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [hasError, setHasError] = useState(false);

  const getGoalsAndTasks = async () => {
    setLoading(true);
    try {
      const { data: goalsData } = await get('v1/goal/load');
      const { data: tasksData } = await get('v1/task/load');

      goalsData?.data?.length && setGoals(goalsData.data);
      tasksData?.data?.tasks && setTasks(categorizeTasks(tasksData.data.tasks));

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setHasError(true);
    }
  };

  const categorizeTasks = (tasks) => {
    const featured = [];
    const hotSurveys = [];
    const answerAndEarn = [];
    const sponsored = [];
    const enterToWin = [];
    const limitedTimeOffers = [];
    const popularSurveys = [];
    const trendingSurveys = [];

    for (const task of tasks) {
      if (task.featured) {
        featured.push(task);
      }

      if (task.task_type === 'hot_surveys') {
        hotSurveys.push(task);
      }

      if (task.task_type === 'profiler') {
        answerAndEarn.push(task);
      }

      if (task.task_type === 'survey') {
        sponsored.push(task);
      }

      if (task.task_type === 'enter_to_win') {
        enterToWin.push(task);
      }

      if (task.task_type === 'limited_time_offers') {
        limitedTimeOffers.push(task);
      }

      if (task.task_type === 'popular_surveys') {
        popularSurveys.push(task);
      }

      if (task.task_type === 'trending_surveys') {
        trendingSurveys.push(task);
      }
    }

    const taskList = [
      {
        title: 'Featured Offers',
        type: 'featured',
        tasks: featured,
      },
      {
        title: 'Hot Surveys',
        type: 'hot_surveys',
        tasks: hotSurveys,
      },
      {
        title: 'Trending Surveys',
        type: 'trending_surveys',
        tasks: trendingSurveys,
      },
      {
        title: 'Answer Surveys and Earn',
        type: 'answer_and_earn',
        tasks: answerAndEarn,
      },
      {
        title: 'Popular Surveys',
        type: 'popular_surveys',
        tasks: popularSurveys,
      },
      {
        title: 'Sponsored Surveys',
        type: 'sponsored',
        tasks: sponsored,
      },
      {
        title: 'Enter to Win',
        type: 'enter_to_win',
        tasks: enterToWin,
      },
      {
        title: 'Limited Time Offers',
        type: 'limited_time_offers',
        tasks: limitedTimeOffers,
      },
    ];

    return taskList;
  };

  useEffect(() => {
    if (refreshTasks) {
      getGoalsAndTasks();
      dispatch(setRefreshTasks(false));
    }
  }, [refreshTasks]); // When a congrats 🎉 event is completed, refresh tasks

  useEffect(() => {
    getGoalsAndTasks();
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard / Communa | Give Your Opinion. Earn Rewards</title>
      </Helmet>

      {hasError ? (
        <h1 className="text-2xl md:text-3xl max-w-2xl leading-snug mx-auto py-8 text-center">
          Something went wrong. Please reload the page or try again later.
        </h1>
      ) : loading ? (
        <div>
          <GlobalLoading />
        </div>
      ) : (
        <>
          {goals && goals.length > 0 && (
            <TaskCategory
              title="Get Started and Earn"
              type="start"
              tasks={goals}
            />
          )}

          {tasks.map(
            ({ title, type, tasks }, i) =>
              tasks.length > 0 && (
                <TaskCategory
                  title={title}
                  type={type}
                  tasks={tasks}
                  key={`tskc-${i}`}
                />
              )
          )}
        </>
      )}
    </>
  );
};

export default DashboardPage;
