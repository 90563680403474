import { createSlice } from '@reduxjs/toolkit';

export const eventsSlice = createSlice({
  name: 'events',
  initialState: {
    list: [],
    refreshTasks: false,
  },
  reducers: {
    addEvent: (state, { payload }) => {
      state.list.push(payload);
    },
    setEvents: (state, { payload }) => {
      state.list = payload;
    },
    removeEvent: (state, { payload }) => {
      state.list = state.list.filter((event) => event._id !== payload);
    },
    setRefreshTasks: (state, { payload }) => {
      state.refreshTasks = payload;
    },
  },
});

export const {
  addEvent,
  setEvents,
  removeEvent,
  setRefreshTasks,
} = eventsSlice.actions;

export default eventsSlice.reducer;
