import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import SignupHero from '@/components/SignupHero';
import SignupIntro from '@/components/SignupIntro';
import SignupWhyJoin from '@/components/SignupWhyJoin';
import SignupTestimonials from '@/components/SignupTestimonials';
import SignupToday from '@/components/SignupToday';
import SignupHow from '@/components/SignupHow';
import { getToken } from '@/utils/token';
import { post } from '@/config/axios';
import { setCmpn } from '@/store/slices/configSlice';
import { logAnalyticEvent } from '@/config/analytics';

const SignupPage = () => {
  if (!!getToken()) {
    return <Redirect to="/dashboard" />;
  }

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const logCampaign = async () => {
    let params = location.search;

    if (!params) return;

    params = params.replace('?', '');

    try {
      const { data } = await post(
        'v1/user-action',
        {
          action: 'campaign_click_log',
          query_params: params,
        },
        null,
        true
      );
      dispatch(setCmpn(data?.data?.cmpn));
      history.push('/signup');
    } catch (err) {
      // 🤫
    }
  };

  useEffect(() => {
    if (location.search) {
      logCampaign();
    }
  }, [location]);

  useEffect(() => {
    logAnalyticEvent('signup_page', 'landing');
  }, []);

  return (
    <div className="bg-grey-200">
      <SignupHero />
      <SignupIntro />
      <SignupWhyJoin />
      <SignupTestimonials />
      <SignupToday />
      <SignupHow />
    </div>
  );
};

export default SignupPage;
