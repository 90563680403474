import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLockAppToDashboard } from '@/store/slices/configSlice';
import TermsModal from '@/components/TermsModal';
import WelcomeModal from '@/components/WelcomeModal';

const ActionsManager = () => {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.actions);
  const { lockAppToDashboard } = useSelector((state) => state.config);

  const actionIs = (name) => list.length && list[0].id === name;

  // If any action exists here, we lock the app
  // to the dashboard so the user "cannot"
  // reach another page or try to do something else
  useEffect(() => {
    if (!list.length && lockAppToDashboard) {
      dispatch(setLockAppToDashboard(false));
      return;
    }

    const hasTutorial = list.find(
      (item) => item.id === 'TUTORIAL_FIRST_TIME_USER'
    );

    if (hasTutorial) {
      dispatch(setLockAppToDashboard(true));
    }
  }, [list]);

  if (actionIs('TERMS_AGREE_POPUP')) {
    return <TermsModal terms={list[0]} />;
  }

  if (actionIs('TUTORIAL_FIRST_TIME_USER')) {
    return <WelcomeModal terms={list[0]} />;
  }

  return <></>;
};

export default ActionsManager;
