import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { get } from '@/config/axios';
import TimelineItem from '@/components/TimelineItem';
import Button from '@/components/Button';
import LoadingEllipsis from '@/components/LoadingEllipsis';
import { Link } from 'react-router-dom';

const TimelinePage = () => {
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(true);
  const [timeline, setTimeline] = useState([]);
  const [skip, setSkip] = useState(0);
  const [hasReachedEnd, setHasReachedEnd] = useState(false);

  const getRewards = async () => {
    try {
      const { data } = await get('v1/user/transactions/load', {
        limit: 10,
        skip,
      });
      if (data.data.length) {
        setTimeline([...timeline, ...data.data]);
      } else {
        setHasReachedEnd(true);
      }
      setLoading(false);
      setLoadingMore(false);
    } catch (err) {
      // TODO: Handle errors
      setLoading(false);
      setLoadingMore(false);
    }
  };

  const getMoreRewards = () => {
    setSkip(skip + 10);
    setLoadingMore(true);
  };

  useEffect(() => {
    if (skip > 0 && !hasReachedEnd) {
      getRewards();
    }
  }, [skip]);

  useEffect(() => {
    getRewards();
  }, []);

  return (
    <>
      <Helmet>
        <title>Timeline / Communa | Give Your Opinion. Earn Rewards</title>
      </Helmet>

      <h1 className="text-2xl">Timeline</h1>
      {loading ? (
        <div className="text-blue flex p-10 md:p-16 justify-center">
          <LoadingEllipsis width={60} />
        </div>
      ) : !timeline.length ? (
        <div className="mt-6">
          <h3>There are no transactions yet.</h3>
          <p>
            Go back to the{' '}
            <Link
              to="/dashboard"
              className="text-blue hover:text-blue-400 transition-colors duration-200"
            >
              Dashboard
            </Link>{' '}
            to earn some points!
          </p>
        </div>
      ) : (
        <div>
          <div className="md:ml-40 border-l-4 border-grey-275 border">
            {timeline.map((timelineEl, i) => (
              <TimelineItem
                timeline={timelineEl}
                isLast={i === timeline.length - 1}
                key={`tm-${i}`}
              />
            ))}
          </div>
          <div className="mt-10 mb-6 flex justify-center">
            {!hasReachedEnd && (
              <Button
                variant="outline"
                loading={loadingMore}
                onClick={getMoreRewards}
              >
                Load More
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TimelinePage;
