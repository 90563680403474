// import '@/utils/wdyr';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import ScrollToTop from '@/components/ScrollToTop';
import GlobalCurtain from '@/components/GlobalCurtain';
import Head from '@/components/Head';
import MaxMind from '@/components/MaxMind';
import AnalyticsPageViews from '@/components/AnalyticsPageViews';
import reportWebVitals from '@/config/reportWebVitals';
import RenderRoutes from '@/config/routes';
import initAnalytics from '@/config/analytics';
import store from '@/store';

import '@/assets/css/vendors/tailwind.css';
import '@/assets/css/main.css';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <Head />
        <Router>
          <ScrollToTop />
          <RenderRoutes />
          <GlobalCurtain />
          <MaxMind />
          <AnalyticsPageViews />
        </Router>
      </HelmetProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

initAnalytics();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
