import PropTypes from 'prop-types';

const Dropdown = ({
  width = 'w-48',
  children,
  active = false,
  clipRight = false,
  isSelect = false,
  className = '',
}) => {
  const dropdownClass = `
    ${className}
    ${
      active
        ? `opacity-100 visible ${isSelect ? '-mt-px' : 'mt-0'} scale-100`
        : `opacity-0 invisible -mt-2  ${isSelect ? 'scale-100' : 'scale-95'}`
    }
    absolute z-50  transform  bg-white overflow-hidden ${width} transition-all duration-200
    ${
      isSelect
        ? 'border-l border-b border-r rounded-b border-brightBlue max-h-48  overflow-y-auto'
        : 'shadow-md rounded-md'
    }
    ${clipRight ? 'right-0' : 'left-0'}
  `;

  return <div className={dropdownClass}>{children}</div>;
};

Dropdown.propTypes = {
  active: PropTypes.bool,
  width: PropTypes.string,
  children: PropTypes.node.isRequired,
  clipRight: PropTypes.bool,
  isSelect: PropTypes.bool,
  className: PropTypes.string,
};

export default Dropdown;
