import { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import usePortal from '@/hooks/usePortal';
import useOnClickOutside from '@/hooks/useOnClickOutside';

const Modal = ({
  children,
  onClose,
  persistOverlay = false,
  maxWidth = 'max-w-xl',
}) => {
  const [active, setActive] = useState(false);
  const ref = useRef();
  const modalRef = useRef();

  useOnClickOutside(ref, () => !persistOverlay && handleOnClose());

  useEffect(() => {
    setTimeout(() => {
      disableBodyScroll(modalRef.current);
      setActive(true);
    }, 10);
    return () => clearAllBodyScrollLocks();
  }, []);

  const handleOnClose = (id) => {
    setActive(false);
    setTimeout(() => {
      enableBodyScroll(modalRef.current);
      onClose(id);
    }, 400);
  };

  return createPortal(
    <div>
      <div
        ref={modalRef}
        className="flex items-start justify-center fixed z-300 py-6 md:p-12 inset-0 overflow-y-auto"
      >
        <section ref={ref} className={`${maxWidth} p-4 md:p-6 w-full`}>
          <div
            className={`${
              active
                ? 'translate-y-4  opacity-100 visible'
                : 'translate-y-0 invisible opacity-0'
            } transform transition-all duration-200 bg-white relative overflow-hidden rounded-md`}
          >
            {children(handleOnClose)}
          </div>
        </section>
      </div>
      <div
        className={`${
          active ? 'opacity-80 visible' : 'invisible opacity-0'
        } transition-all duration-200 z-295 fixed inset-0 bg-grey-900`}
        onClick={handleOnClose}
        role="presentation"
      />
    </div>,
    usePortal('modal-root')
  );
};

Modal.propTypes = {
  children: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  persistOverlay: PropTypes.bool,
  maxWidth: PropTypes.string,
};

export default Modal;
