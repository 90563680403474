import { useEffect } from 'react';
import { removeToken } from '@/utils/token';
import { post } from '@/config/axios';

const LogoutPage = () => {
  const logUserOut = async () => {
    try {
      await post(
        'v1/user-action',
        {
          action: 'logout',
        },
        null,
        true
      );
    } catch (err) {}
  };

  useEffect(() => {
    logUserOut();
    removeToken();
    window.location.href = '/login';
  }, []);

  return <></>;
};

export default LogoutPage;
