import PropTypes from 'prop-types';

const SponsoredHeader = ({ sponsor }) => {
  const iconsLookup = {
    pollfish: 'pollfish.gif',
    marketcube: 'market_cube.png',
    sayso: 'sayso.png',
    dynata: 'dynata-logo.png',
    peanutlabs: 'peanut-labs.png',
  };

  return (
    <header>
      <h1 className="flex flex-col md:flex-row text-2xl md:text-3xl items-center">
        {iconsLookup[sponsor.id] && (
          <img
            className="h-8 md:h-10 mr-4"
            src={`/img/surveys/${iconsLookup[sponsor.id]}`}
            alt={`${sponsor.name} logo`}
          />
        )}
        {sponsor.name} Surveys
      </h1>
      <div className="font-bold mb-2 text-lg mt-6">
        {sponsor.name} has located surveys they think will interest you.
      </div>
      <p>
        Earn Communa points for every offer you complete. Before you start,
        remember that quality is important to us and our partners. Please answer
        honestly and do not rush. For some of the surveys, please allow up to
        48hrs for your Communa points to be credited to your account.
      </p>
    </header>
  );
};

SponsoredHeader.propTypes = {
  sponsor: PropTypes.object.isRequired,
};

export default SponsoredHeader;
