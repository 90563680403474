import PropTypes from 'prop-types';

const Alert = ({ type = 'success', children, onClose = null }) => {
  const alertClass =
    type === 'error'
      ? 'bg-red-200 text-red-600'
      : type === 'success'
      ? 'bg-green-200 text-green-600'
      : '';

  return (
    <div
      className={`${alertClass} relative p-2 flex items-center mb-4 rounded`}
    >
      {type === 'error' && (
        <div className="w-7">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            viewBox="0 0 50 50"
          >
            <circle cx="25" cy="25" r="25" fill="#C1272D" />
            <path
              fill="#FFF"
              d="M25.1,34.4 C26.8120827,34.4 28.2,35.7879173 28.2,37.5 C28.2,39.2120827 26.8120827,40.6 25.1,40.6 C23.3879173,40.6 22,39.2120827 22,37.5 C22,35.7879173 23.3879173,34.4 25.1,34.4 Z M25,9.4 C27.6,9.4 29.8,11.5 29.7,14 L28.2,28.1 C28,29.8 26.8,31.2 25.1,31.2 C23.4,31.2 22.1,29.8 22,28.1 L20.4,14.1 C20.3,11.5 22.5,9.4 25,9.4 Z"
            />
          </svg>
        </div>
      )}
      <div className="ml-2 leading-snug">{children}</div>
      {!!onClose && (
        <button
          onClick={onClose}
          className="top-0.5 right-0.5 absolute hover:text-green-800 duration-200 transition-colors"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-6 -6 24 24"
            width="20"
          >
            <path
              fill="currentColor"
              d="M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
};

export default Alert;
