import { useState, useEffect } from 'react';
import FormRadio from '@/components/FormRadio/FormRadio';
import PropTypes from 'prop-types';
import Row from './Row';

const OptionsMatrix = ({ data, onChange }) => {
  const [preAnswers, setPreAnswers] = useState({});

  const {
    answer_set,
    type_specific_config: { rows },
  } = data;

  const handleOnChange = ({ target }) => {
    setPreAnswers({
      ...preAnswers,
      [target.name]: target.value,
    });
  };

  const isMatrixComplete = () => Object.keys(preAnswers).length === rows.length;

  useEffect(() => {
    if (isMatrixComplete()) {
      onChange(preAnswers);
    }
  }, [preAnswers]);

  return (
    <section>
      <Row className="flex py-4">
        <div className="flex-1" />
        {answer_set.map(({ label }, i) => (
          <div className="flex-1 text-xs text-center font-bold" key={`lb-${i}`}>
            {label}
          </div>
        ))}
      </Row>
      {rows.map(({ label, id }, i) => (
        <Row className="flex items-center py-4" key={`rw-${i}`}>
          <div className="flex-1 text-sm font-bold">{label}</div>
          {answer_set.map(({ id: value }, i) => (
            <div className="flex-1" key={`cl-${i}`}>
              <FormRadio
                className="justify-center"
                noLabel
                name={id}
                value={value}
                onChange={handleOnChange}
              />
            </div>
          ))}
        </Row>
      ))}
    </section>
  );
};

OptionsMatrix.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OptionsMatrix;
