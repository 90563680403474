import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

const GoogleRecaptcha = ({ onChange }) => (
  <ReCAPTCHA
    sitekey={process.env.REACT_APP_RECAPTCHA_CHECKBOX_KEY}
    onChange={onChange}
  />
);

GoogleRecaptcha.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default GoogleRecaptcha;
