import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import TaskError from './TaskError';
import TaskCheckPhone from './TaskCheckPhone';
import TaskCheckEmail from './TaskCheckEmail';
import TaskCap from './TaskCap';
import TaskDefault from './TaskDefault';

import { setRefreshTasks } from '@/store/slices/eventsSlice';

const TaskDispatcher = ({
  task,
  rate,
  onClose,
  onToggleProfiler,
  bypassIntro = false,
}) => {
  const dispatch = useDispatch();

  const { goal_id, task_type, slink, caps, task_status } = task;

  const isLocked = [
    'phone_verification_required',
    'email_verification_required',
  ].includes(task_status);

  const handleOnClick = () => {
    if (task_type === 'profiler') {
      onClose();
      onToggleProfiler();
      return;
    }

    if (task_type !== 'goal') {
      window.open(slink, '_blank');
    }

    if (
      [
        'survey',
        'limited_time_offers',
        'enter_to_win',
        'up_and_coming_surveys',
        'hot_surveys',
        'popular_surveys',
        'trending_surveys',
      ].includes(task_type)
    ) {
      dispatch(setRefreshTasks(true));
    }
  };

  useEffect(() => {
    if (bypassIntro) {
      handleOnClick();
    }
  }, []);

  if (isLocked) {
    return <TaskError task={task} onClose={onClose} />;
  }

  if (caps) {
    return <TaskCap task={task} onClose={onClose} />;
  }

  if (task_type === 'goal' && goal_id === 'verify_phone') {
    return <TaskCheckPhone task={task} onClose={onClose} />;
  }

  if (task_type === 'goal' && goal_id === 'verify_email') {
    return <TaskCheckEmail task={task} onClose={onClose} />;
  }

  if (bypassIntro) {
    return <></>;
  }

  return (
    <TaskDefault
      task={task}
      rate={rate}
      onClose={onClose}
      onClick={handleOnClick}
    />
  );
};

TaskDispatcher.propTypes = {
  task: PropTypes.object.isRequired,
  rate: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onToggleProfiler: PropTypes.func,
  bypassIntro: PropTypes.bool,
};

export default TaskDispatcher;
