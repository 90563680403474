import PropTypes from 'prop-types';

const OrDivider = ({ className = '' }) => {
  return (
    <div className={`relative my-3 h-7 ${className}`}>
      <div className="absolute h-px bg-grey-300 right-4 left-4 top-3" />
      <div className="bg-grey-200 w-16 text-center absolute left-1/2 transform -translate-x-1/2">
        Or
      </div>
    </div>
  );
};

OrDivider.propTypes = {
  className: PropTypes.string,
};

export default OrDivider;
