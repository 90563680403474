import TaskModal from './TaskModal';
import TaskEventCongratulationEL from './components/TaskEventCongratulation';
import TaskDailyLoginRewardsEl from './components/TaskDailyLoginRewards';
import TaskWelcomeEl from './components/TaskWelcome';
import TaskVerifyEmailEl from './components/TaskVerifyEmail';
import TaskVerifyPhoneEl from './components/TaskVerifyPhone';
import Task30DayHoldEl from './components/Task30DayHold';
import TaskRedeemRewardEl from './components/TaskRedeemReward';
import TaskSomethingWrongEl from './components/TaskSomethingWrong';
import TaskNotEnoughPointsEl from './components/TaskNotEnoughPoints';
import TaskBallotDrawRewardEl from './components/TaskBallotDrawReward';
import TaskBallotLoginRewardEl from './components/TaskBallotLoginReward';

export const TaskEventCongratulation = TaskEventCongratulationEL;
export const TaskDailyLoginRewards = TaskDailyLoginRewardsEl;
export const TaskWelcome = TaskWelcomeEl;
export const TaskVerifyPhone = TaskVerifyPhoneEl;
export const Task30DayHold = Task30DayHoldEl;
export const TaskRedeemReward = TaskRedeemRewardEl;
export const TaskSomethingWrong = TaskSomethingWrongEl;
export const TaskNotEnoughPoints = TaskNotEnoughPointsEl;
export const TaskBallotDrawReward = TaskBallotDrawRewardEl;
export const TaskBallotLoginReward = TaskBallotLoginRewardEl;
export const TaskVerifyEmail = TaskVerifyEmailEl;

export default TaskModal;
