import PropTypes from 'prop-types';

const TaskInfo = ({ points, length, upto = null, bonusPoints = null }) => {
  const pointsSource = upto || points;
  const totalPoints = Number(points) + Number(bonusPoints);
  const pts = pointsSource > 1 ? 'Pts' : 'Pt';
  return (
    <section className="flex sm:flex-row flex-col items-center justify-center py-5">
      {!isNaN(points) && (
        <div
          className={`  flex items-center ${
            length ? 'sm:w-1/2 sm:pr-10 justify-end' : 'justify-center'
          }`}
        >
          <div className="py-1 px-2 rounded bg-grey-250 font-bold text-lg w-auto">
            <span className={upto ? 'block text-center' : ''}>
              {upto && (
                <span className="font-normal opacity-80 mr-1">UP TO</span>
              )}
              <span className="relative">
                {bonusPoints && (
                  <span className="h-1 rounded-full w-8 transform -rotate-12 absolute top-3 left-0 bg-red-500" />
                )}
                {pointsSource} {!bonusPoints && pts}
              </span>
            </span>
            {bonusPoints && (
              <span className="ml-1">
                NOW {totalPoints} {pts}
              </span>
            )}
          </div>
        </div>
      )}

      {length && (
        <div className="sm:w-1/2 mt-3 md:mt-0 flex items-center md:pl-10 justify-start sm:border-l border-grey-250">
          <div className="flex px-2 py-1 rounded bg-grey-250 font-bold text-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-2 -2 24 24"
              width="14"
              className="mr-1"
            >
              <path
                fill="currentColor"
                d="M11 9h4a1 1 0 0 1 0 2h-5a1 1 0 0 1-1-1V4a1 1 0 1 1 2 0v5zm-1 11C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
              />
            </svg>{' '}
            {length} Min
          </div>
        </div>
      )}
    </section>
  );
};

TaskInfo.propTypes = {
  points: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  length: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  upto: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bonusPoints: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TaskInfo;
