import PropTypes from 'prop-types';

const Row = ({ children, className = '' }) => (
  <div
    className={`${className} border-b border-grey-275 px-2 hover:bg-grey-50 transition-colors duration-200`}
  >
    {children}
  </div>
);

Row.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Row;
