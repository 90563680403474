import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { post } from '@/config/axios';
import { makeFieldErrors } from '@/utils/helpers';
import regions from '@/data/regions';
import countries from '@/data/countries';
import Fieldset from '@/components/Fieldset';
import FormItem from '@/components/FormItem';
import FormSelect from '@/components/FormSelect';
import Button from '@/components/Button';
import FormWrapper from '@/components/FormWrapper';
import LoadingEllipsis from '@/components/LoadingEllipsis';
import Alert from '@/components/Alert';

const ProfileForm = () => {
  const { data, userId } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    name: null,
    address: null,
    postal_code: null,
    country: null,
    region: null,
  });
  const [regionsOptions, setRegionsOptions] = useState(regions[data.country]);
  const [loading, setLoading] = useState(true);
  const [postLoading, setPostLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState();
  const [formError, setFormError] = useState();
  const [showSuccess, setShowSuccess] = useState(false);

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async () => {
    setFieldErrors();
    setShowSuccess(false);
    setFormError();
    setPostLoading(true);

    const postData = {
      ...formData,
      user_id: userId,
      speculative: false,
    };

    try {
      await post('v1/user/update-name-and-address', null, postData);
      setShowSuccess(true);
      setPostLoading(false);
    } catch (err) {
      const { fieldErrors, formError } = makeFieldErrors(
        err?.response?.data?.errors
      );
      if (fieldErrors) {
        setFieldErrors(fieldErrors);
      }
      if (formError) {
        setFormError(formError);
      }
      setPostLoading(false);
    }
  };

  useEffect(() => {
    if (!formData) return;

    if (formData.country && regions[formData.country]) {
      setRegionsOptions(regions[formData.country]);
    }

    setLoading(false);
  }, [formData]);

  useEffect(() => {
    if (loading && data?.postal_code) {
      const { name, address, city, postal_code, country, region } = data;
      setFormData({
        name,
        address,
        city,
        postal_code,
        country,
        region,
      });
    }
  }, [data]);

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <Fieldset title="Personal Information">
      {loading ? (
        <div className="text-blue flex p-10 md:p-16 justify-center">
          <LoadingEllipsis width={60} />
        </div>
      ) : (
        <FormWrapper onSubmit={onSubmit} error={formError}>
          <div className="mt-4">
            <FormItem
              label="Email"
              name="email"
              placeholder="john@doe.com"
              disabled
              value={data.email}
            />
            <FormItem
              label="Birth Date"
              name="birth_date"
              placeholder=""
              className="mt-3"
              disabled
              value={data.birthdate}
            />
            <FormItem
              label="Name"
              name="name"
              placeholder=""
              className="mt-3"
              value={formData.name}
              onChange={handleOnChange}
              error={fieldErrors?.name}
            />
            <FormItem
              label="Address"
              name="address"
              placeholder=""
              className="mt-3"
              value={formData.address}
              onChange={handleOnChange}
              error={fieldErrors?.address}
            />
            <FormItem
              label="City"
              name="city"
              placeholder=""
              className="mt-3"
              value={formData.city}
              onChange={handleOnChange}
              error={fieldErrors?.city}
            />
            <FormItem
              label="Postal/Zip Code"
              name="postal_code"
              placeholder=""
              className="mt-3"
              value={formData.postal_code}
              onChange={handleOnChange}
              error={fieldErrors?.postal_code}
            />
            <FormSelect
              className="mt-3"
              label="Country"
              name="country"
              options={countries}
              value={formData.country}
              onChange={handleOnChange}
              error={fieldErrors?.country}
            />
            <FormSelect
              className="mt-3"
              name="region"
              label="State/Province/Region"
              options={regionsOptions}
              value={formData.region}
              onChange={handleOnChange}
              error={fieldErrors?.region}
            />
            {showSuccess && (
              <div className="mt-4">
                <Alert type="success">
                  Your information has been successfully saved!
                </Alert>
              </div>
            )}
            <div className="flex justify-end mt-4">
              <Button loading={postLoading} type="submit">
                Update
              </Button>
            </div>
          </div>
        </FormWrapper>
      )}
    </Fieldset>
  );
};

export default ProfileForm;
