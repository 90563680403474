import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '@/components/Logo';
import { getToken } from '@/utils/token';

import BallotDraw from './components/BallotDraw';
import PointsCount from './components/PointsCount';
import UserNav from './components/UserNav';

const AppHeader = () => {
  const isLoggedIn = getToken();
  const refEl = useRef();

  return (
    <header className="sticky top-0 left-0 z-20 bg-white px-4 flex justify-between shadow h-12">
      <Link to="/" className="flex items-center">
        <Logo />
      </Link>
      <section ref={refEl} className="flex items-center">
        {isLoggedIn && (
          <>
            <PointsCount refEl={refEl} />
            <BallotDraw />
          </>
        )}

        <UserNav />
      </section>
    </header>
  );
};

export default AppHeader;
