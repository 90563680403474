import PropTypes from 'prop-types';
import Button from '@/components/Button';
import TaskDetailHeader from './TaskDetailHeader';
import { numberFormat } from '@/utils/helpers';

const TaskNotEnoughPoints = ({ minimumPoints, pointsRequired, onClose }) => {
  const task = {
    name: 'You do not have enough points!',
    design_class: 'Locked',
  };

  const widthPercent = `${Math.floor(
    (1 - pointsRequired / minimumPoints) * 100
  )}%`;

  return (
    <div className="task-single bg-grey-50">
      <TaskDetailHeader task={task} onClose={onClose} />
      <section className="mt-4 px-5 md:px-8 ">
        <p className="md:text-lg text-center">
          Complete some more surveys to earn more points and unlock rewards.
          <br />
          <br />
          You need <strong>{numberFormat(pointsRequired)}</strong> more points
          to redeem the minimum amount for this reward.
        </p>
        <div className="mt-6 max-w-xs mx-auto rounded-full bg-grey-300 h-3 ">
          <div
            className="bg-green rounded-full w-2/3 h-3"
            style={{
              width: widthPercent,
            }}
          />
        </div>
      </section>

      <footer className="p-5 pt-8 md:p-8">
        <Button to="/dashboard" variant="success" className="w-full">
          Return To Dashboard
        </Button>
      </footer>
    </div>
  );
};

TaskNotEnoughPoints.propTypes = {
  minimumPoints: PropTypes.number.isRequired,
  pointsRequired: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TaskNotEnoughPoints;
