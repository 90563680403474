import ContactForm from '@/components/ContactForm';
import PageHeader from '@/components/PageHeader';

const ContactPage = () => {
  return (
    <section>
      <PageHeader title="Contact Us" className="py-16 md:py-24" />
      <section className="mx-auto max-w-5xl py-16 flex flex-col md:flex-row px-6">
        <aside className="md:w-1/3">
          <h3 className="text-lg">Our Office</h3>
          <p className="text-lg mt-2 mb-3">
            PO Box 26086 Broadway PO <br />
            Toronto, ON M4P 0A8
          </p>
          <a
            href="mailto:panelmanager@communa.com"
            className="text-lg hover:text-blue transition-colors duration-200"
          >
            panelmanager@communa.com
          </a>
        </aside>
        <main className="md:w-2/3 mt-12 md:mt-0">
          <ContactForm />
        </main>
      </section>
    </section>
  );
};

export default ContactPage;
