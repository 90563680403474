import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addEvent } from '@/store/slices/eventsSlice';
import { randId } from '@/utils/helpers';

const BallotDraw = () => {
  const dispatch = useDispatch();
  const {
    ballots,
    nextDraw,
    hasCollectedBallot,
    dailyLoginRewards,
  } = useSelector((state) => state.user);
  const [hover, setHover] = useState(false);

  const ballotsPlural = ballots && ballots > 1 ? 's' : '';

  const handleOnClick = () => {
    const event = {
      _id: randId(),
      type: 'DAY_FIRST_LANDING_POPUP',
      points: hasCollectedBallot ? 0 : dailyLoginRewards,
    };
    dispatch(addEvent(event));
  };

  if (!ballots || !nextDraw) {
    return <></>;
  }

  return (
    <button
      id="ballot"
      className="flex mx-2 h-full font-bold"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleOnClick}
    >
      <div
        className={`flex items-center transition-colors duration-200 h-full pl-1.5 ${
          hover ? 'bg-grey-100' : ''
        }`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox="0 0 32 22">
          <path
            fill="#1494D6"
            d="M20.6,0.2 L20.9,21.1 L2.9,21.3 C2.2,21.3 1.6,21.1 1.1,20.6 C0.65,20.15 0.362,19.619 0.3089,19.007 L0.3,18.8 L0.2,13.9 L1.2,13.5 C2.24210526,13.1210526 3.01495845,12.1138504 3.0934247,10.9885552 L3.1,10.8 L3.07779492,10.6010288 C2.92992112,9.54588477 2.31236283,8.59609053 1.29535322,8.17304527 L1.1,8.1 L0.1,7.8 L-1.42108547e-14,2.9 L0.0088,2.6931 C0.061,2.082 0.34,1.56 0.7,1.2 C1.15,0.75 1.681,0.462 2.293,0.4089 L2.5,0.4 L20.6,0.2 Z M29.1,0 C29.8,0 30.4,0.2 30.9,0.7 C31.35,1.15 31.638,1.681 31.6911,2.293 L31.7,2.5 L31.8,7.4 L30.6,7.7 C29.3590909,7.98636364 28.4826446,9.0927686 28.4055316,10.3234222 L28.4,10.5 L28.4185575,10.6890026 C28.5744403,11.9328799 29.3732272,12.9113723 30.5166406,13.2516329 L30.7,13.3 L31.9,13.6 L31.9,18.3 L31.8939,18.507 C31.857,19.119 31.65,19.65 31.2,20.1 C30.75,20.55 30.219,20.838 29.607,20.8911 L29.4,20.9 L23.6,21 L23.5,0.1 L29.1,0 Z"
          />
        </svg>
        <div className="ml-1 pr-2 leading-none text-sm sm:text-base">
          {ballots} Ballot{ballotsPlural}
        </div>
      </div>
      <div
        className={`h-full md:flex hidden px-3 transition-colors relative duration-200 flex-col justify-center ${
          hover ? 'bg-blue text-white' : 'bg-grey-200'
        }`}
      >
        <div className="text-2xs mb-0.5 uppercase leading-none">Next Draw</div>
        <div className="text-sm leading-none">{nextDraw}</div>
        <div
          className={`absolute w-4 h-4 rounded-sm -left-0.5 transition-colors transform rotate-45 duration-200 ${
            hover ? 'bg-blue' : 'bg-grey-200'
          }`}
        />
      </div>
    </button>
  );
};

export default BallotDraw;
