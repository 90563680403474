import { createSlice } from '@reduxjs/toolkit';

export const actionsSlice = createSlice({
  name: 'actions',
  initialState: {
    list: [],
    tutorialAvailable: false,
  },
  reducers: {
    setTutorialAvailable: (state, { payload }) => {
      state.tutorialAvailable = payload;
    },
    setActions: (state, { payload }) => {
      state.list = payload;
    },
    removeAction: (state, { payload }) => {
      state.list = state.list.filter((action) => action.id !== payload);
    },
  },
});

export const {
  setActions,
  setTutorialAvailable,
  removeAction,
} = actionsSlice.actions;

export default actionsSlice.reducer;
