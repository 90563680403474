import { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@/components/Modal';
import { Task30DayHold } from '@/components/TaskModal';

const Verify30DayHoldModal = ({ onClose }) => {
  return (
    <Modal onClose={onClose}>
      {(onClose) => <Task30DayHold onClose={onClose} />}
    </Modal>
  );
};

Verify30DayHoldModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Verify30DayHoldModal;
