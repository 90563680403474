import PropTypes from 'prop-types';
import { randId, setValidationRules } from '@/utils/helpers';

const FormItem = ({
  value,
  label = null,
  type = 'text',
  onChange,
  placeholder = '',
  tooltip = null,
  required = false,
  id = '',
  className = '',
  name,
  error = '',
  ...props
}) => {
  const isTextarea = type === 'textarea';
  const Element = isTextarea ? 'textarea' : 'input';
  const elId = id || randId();

  return (
    <div className={`${className}`}>
      {label && (
        <label
          className="text-xs font-oswald mb-1 block uppercase"
          htmlFor={elId}
        >
          {label}
        </label>
      )}
      <Element
        id={elId}
        type={type}
        name={name}
        value={value}
        required={required}
        placeholder={placeholder}
        className={`${
          isTextarea ? 'h-48 py-2' : 'h-10'
        } border rounded border-grey-275 px-3 w-full text-sm`}
        {...props}
        onChange={onChange}
      />
      {error && (
        <span
          className="text-xs mt-0.5 text-red-500"
          dangerouslySetInnerHTML={{ __html: error }}
        />
      )}
    </div>
  );
};

FormItem.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
};

export default FormItem;
