import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import LoadingEllipsis from '@/components/LoadingEllipsis';

const TaskRateLimitCountDown = ({ seconds, hasEnded }) => {
  const [counterDate, setCounterDate] = useState(null);

  useEffect(() => {
    const countDownDate = new Date();
    countDownDate.setSeconds(countDownDate.getSeconds() + seconds);
    setCounterDate(countDownDate);
  }, []);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    const padHours = hours > 9 ? hours : `0${hours}`;
    const padMinutes = minutes > 9 ? minutes : `0${minutes}`;
    const padSeconds = seconds > 9 ? seconds : `0${seconds}`;

    return (
      <span>
        {padHours}:{padMinutes}:{padSeconds}
      </span>
    );
  };

  return (
    <div className="flex items-center justify-center">
      {!counterDate ? (
        <div className="text-blue">
          <LoadingEllipsis width={50} />
        </div>
      ) : (
        <div className="rounded px-3 py-2 bg-grey-250 text-3xl font-medium">
          <Countdown
            date={counterDate}
            onComplete={hasEnded}
            renderer={renderer}
          />
        </div>
      )}
    </div>
  );
};

TaskRateLimitCountDown.propTypes = {
  seconds: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  hasEnded: PropTypes.func.isRequired,
};

export default TaskRateLimitCountDown;
