const TaskLock = () => (
  <div className="absolute inset-0 z-10 transition-all duration-200 task-lock flex items-center justify-center">
    <div className="lock relative z-10 w-10 h-10 rounded-full p-2.5 bg-grey-900 border-2 border-white flex items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 80">
        <path
          fill="#FFF"
          d="M52.7674024,30.3 L52.7674024,22.4 C52.7674024,10 42.5806452,0 29.9490662,0 L29.9490662,0 C17.3174873,0 7.13073005,10 7.13073005,22.4 L7.13073005,30.3 C2.85229202,32.2 0,36.4 0,41.2 L0,68 C0,72.9 2.95415959,77 7.13073005,78.9 C8.65874363,79.6 10.3904924,80 12.2241087,80 L47.7758913,80 C49.6095076,80 51.2393888,79.6 52.8692699,78.9 C57.147708,77 60,72.8 60,68 L60,41.2 C59.8981324,36.4 56.9439728,32.2 52.7674024,30.3 Z M34.1256367,53.5 L34.1256367,58.2 C34.1256367,60.5 32.1901528,62.3 29.9490662,62.3 L29.9490662,62.3 C27.6061121,62.3 25.7724958,60.4 25.7724958,58.2 L25.7724958,53.5 C25.7724958,52.9 25.5687606,52.4 25.3650255,51.8 C24.9575552,51 24.6519525,50.1 24.75382,49.1 C24.8556876,46.5 26.9949066,44.4 29.6434635,44.2 C32.6994907,44 35.24618,46.4 35.24618,49.4 C35.24618,50.3 35.0424448,51.1 34.6349745,51.8 C34.3293718,52.3 34.1256367,52.9 34.1256367,53.5 Z M44.9235993,29.2 L14.9745331,29.2 L14.9745331,22.4 C14.9745331,14.3 21.6977929,7.7 29.9490662,7.7 C38.2003396,7.7 44.9235993,14.3 44.9235993,22.4 L44.9235993,29.2 Z"
        />
      </svg>
    </div>
    <div className="overlay absolute inset-0 z-1 transition-all duration-200 bg-grey-800" />
  </div>
);

export default TaskLock;
