import PropTypes from 'prop-types';
import withSession from '@/hoc/withSession';
import AppBlogHeader from '@/components/AppBlogHeader';
import AppFooter from '@/components/AppFooter';

const BlogLayout = ({ children }) => {
  return (
    <div className="bg-grey-200">
      <AppBlogHeader />
      <main>{children}</main>
      <AppFooter />
    </div>
  );
};

BlogLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withSession(BlogLayout);
