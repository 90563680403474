import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PageHeader from '@/components/PageHeader';
import FaqItem from '@/components/FaqItem';
import LoadingEllipsis from '@/components/LoadingEllipsis';
import useGetPageContent from '@/hooks/useGetPageContent';
import Alert from '@/components/Alert';

const FaqPage = () => {
  const { contentData, loading, error } = useGetPageContent('/faq');

  return (
    <>
      <Helmet>
        <title>FAQ / Communa | Give Your Opinion. Earn Rewards</title>
      </Helmet>
      <section>
        <PageHeader title="FAQ" className="py-16 md:py-24" />
        <main className="max-w-3xl px-6 mx-auto md:pt-8 pb-16">
          {error ? (
            <div className="mt-3">
              <Alert type="error">
                Something went wrong. Please refresh the page. If the issue
                persists, please <Link to="/contact">contact us</Link>.
              </Alert>
            </div>
          ) : loading ? (
            <div className="py-12 flex justify-center text-blue">
              <LoadingEllipsis width={48} />
            </div>
          ) : (
            contentData?.content.map(({ category, faq }, i) => (
              <div key={`fqc-${i}`}>
                <h2 className="pb-4 mt-12">{category}</h2>
                {faq.map(({ title, content }, i) => (
                  <FaqItem key={`fqc-${i}`} title={title} content={content} />
                ))}
              </div>
            ))
          )}
        </main>
      </section>
    </>
  );
};

export default FaqPage;
