import { useState } from 'react';
import { Link } from 'react-router-dom';
import FormItem from '@/components/FormItem';
import Alert from '@/components/Alert';
import Button from '@/components/Button';
import FormWrapper from '@/components/FormWrapper';
import { post } from '@/config/axios';
import { makeFieldErrors } from '@/utils/helpers';

const PasswordForgotPage = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [isSent, setIsSent] = useState(false);
  const [fieldErrors, setFieldErrors] = useState();
  const [formError, setFormError] = useState();

  const handleSubmit = async () => {
    setError(null);
    setLoading(true);
    try {
      const { data } = await post('v1/reset_password/send', null, {
        speculative: false,
        email,
      });
      setIsSent(true);
      setLoading(false);
    } catch (err) {
      const { fieldErrors, formError } = makeFieldErrors(
        err?.response?.data?.errors
      );
      if (fieldErrors) {
        setFieldErrors(fieldErrors);
      }
      if (formError) {
        setFormError(formError);
      }
      setLoading(false);
    }
  };

  if (isSent) {
    return (
      <div>
        <Alert>
          A reset password link has been sent to &quot;{email}&quot;.
        </Alert>
        <div className="text-center text-sm">
          <Link
            to="/login"
            className="hover:text-blue transition-colors duration-200"
          >
            Return to Login Page
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div>
      <p className="mb-5 text-center ">
        Please enter your email to reset password.
      </p>
      <FormWrapper onSubmit={handleSubmit} error={formError}>
        <FormItem
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          name="email"
          className="mb-3"
          placeholder="Email"
          error={fieldErrors?.email}
        />
        <Button
          loading={loading}
          disabled={!email}
          className="w-full"
          variant="success"
          type="submit"
        >
          Submit
        </Button>
      </FormWrapper>
      <div className="mt-3 text-right">
        <Link
          to="/login"
          className="text-blue hover:text-blue-400 transition-colors duration-200 font-bold"
        >
          Cancel
        </Link>
      </div>
    </div>
  );
};

export default PasswordForgotPage;
