import PropTypes from 'prop-types';

const TaskPoints = ({ points, upto = null, bonusPoints = null }) => {
  const pointsSource = upto || points;
  const totalPoints = Number(points) + Number(bonusPoints);
  const pts = pointsSource > 1 ? 'pts' : 'pt';

  return (
    <div className="leading-none font-bold uppercase font-oswald text-lg text-white mb-2">
      {upto && <span className="font-normal opacity-80">Up To </span>}
      <span className="relative">
        {bonusPoints && (
          <span className="h-1 rounded-full w-8 transform -rotate-12 absolute top-3 left-0 bg-red-500" />
        )}
        {pointsSource} {!bonusPoints && pts}
      </span>
      {bonusPoints && (
        <span
          className="ml-1 px-1.5 py-0.5 rounded"
          style={{ background: 'rgba(0,0,0,0.25)' }}
        >
          NOW {totalPoints} {pts}
        </span>
      )}
    </div>
  );
};

TaskPoints.propTypes = {
  points: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  upto: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bonusPoints: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default TaskPoints;
