import PropTypes from 'prop-types';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getToken } from '@/utils/token';
import withSession from '@/hoc/withSession';
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import AppNav from '@/components/AppNav';
import EventsManager from '@/components/EventsManager';
import ActionsManager from '@/components/ActionsManager';
import OfflineModal from '@/components/OfflineModal';
import { useEffect } from 'react';

const AppLayout = ({ children, payloadLoaded }) => {
  const location = useLocation();
  const history = useHistory();

  if (!getToken()) {
    return <Redirect to="/login" />;
  }

  const { lockAppToDashboard } = useSelector((state) => state.config);

  useEffect(() => {
    if (lockAppToDashboard && !location.pathname.includes('dashboard')) {
      history.push('/dashboard');
    }
  }, [location, lockAppToDashboard]);

  return (
    <>
      <div className="bg-grey-200 flex flex-col min-h-screen">
        <AppHeader />
        <main className="flex w-full max-w-8xl mx-auto">
          <AppNav />
          <section className="flex-1 md:px-10 md:py-8 p-6">
            {payloadLoaded && children}
          </section>
        </main>

        <div className="mt-auto">
          <AppFooter />
        </div>
      </div>
      <EventsManager />
      <ActionsManager />
      <OfflineModal />
    </>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  payloadLoaded: PropTypes.bool.isRequired,
};

export default withSession(AppLayout);
