import axios from 'axios';
import qs from 'qs';
import { getToken, removeToken } from '@/utils/token';
import store from '@/store';
import {
  setCsrf,
  setLogoutSessionId,
  resetUser,
} from '@/store/slices/userSlice';
import { setEvents } from '@/store/slices/eventsSlice';
import { setActions } from '@/store/slices/actionsSlice';
// import events from '@/data/events.json'; // TODO: For testing purpose. Remove once ok

let csrfToken = null;

const axiosInstance = () => {
  const { user } = store.getState();
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Add baseUrl from .env file
    headers: {
      'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      Authorization: user.logoutSessionId,
    },
  });

  // Request interceptors can be used to append elements to the request,
  // like a JWT token
  instance.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptors can be use to handle global errors, redirects etc.
  instance.interceptors.response.use(
    (response) => {
      const events =
        response.data?.events ||
        response.data?.data?.events ||
        response.data?.data?.payload?.user_events;

      const actions = response.data?.data?.payload?.user_actions;

      if (events && events.length) {
        store.dispatch(setEvents(events));
      }

      if (actions) {
        store.dispatch(setActions(actions));
      }

      return response;
    },
    (error) => {
      const isLoginPage = window.location.pathname.includes('login');
      const errorStatus = error?.response?.status;
      const errorMsg = error?.response?.data?.errors?.[0]?.message;
      const token = error?.response?.data?.session_id;

      refreshCsrf(error?.response);

      if (errorStatus === 500) {
        window.location.href = '/something-went-wrong';
      }

      // On 403, record logoutSessionId & csrf
      if ([403].includes(errorStatus)) {
        token && store.dispatch(setLogoutSessionId(token));

        if (errorMsg === 'Unauthorized') {
          removeToken();
          store.dispatch(resetUser());
        }

        if (!isLoginPage) {
          window.location.href = '/logout';
        }

        return;
      }

      if ([401].includes(errorStatus)) {
        removeToken();
        store.dispatch(resetUser());
        token && store.dispatch(setLogoutSessionId(token));

        if (!isLoginPage) {
          window.location.href = '/logout';
        }

        return;
      }

      if (errorStatus === 404) {
        window.location.href = '/not-found';
      }
      // Handle other status here or reject to allow local handling with
      // try / catch blocks for instance
      return Promise.reject(error);
    }
  );
  return instance;
};

const refreshCsrf = (resp) => {
  const csrf = resp?.data?.csrf_token || resp?.data?.data?.csrf_token;

  if (csrf) {
    store.dispatch(setCsrf(csrf));
    csrfToken = csrf;
  }
};

export const post = async (url, formData = {}, params = {}, isJSON = false) => {
  const { user } = store.getState();

  const postParams = {
    ...params,
    version: '3.0.0',
  };

  if (csrfToken || user.csrf) {
    postParams.csrf = user.csrf;
  }

  const resp = isJSON
    ? await axiosInstance().post(url, formData, {
        params: postParams,
        headers: {
          'content-type': 'application/json',
        },
      })
    : await axiosInstance().post(url, qs.stringify(formData), {
        params: postParams,
      });

  refreshCsrf(resp);

  return resp;
};

export const get = async (url, urlParams) => {
  const { user } = store.getState();

  const params = {
    ...urlParams,
    // bust: 2,
    // sca_a: null,
    csrf: user.csrf,
    version: '3.0.0',
  };

  if (user.userId) {
    params.user_id = user.userId;
  }

  const resp = await axiosInstance().get(url, { params });

  refreshCsrf(resp);

  return resp;
};

export default axiosInstance;
