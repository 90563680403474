import PageSimple from '@/components/PageSimple';

const PersonalInfoPage = () => (
  <PageSimple
    title="Notice Of Right To Opt Out Of Sale of Information"
    path="/do-not-sell-my-personal-information"
  />
);

export default PersonalInfoPage;
