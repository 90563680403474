import { Helmet } from 'react-helmet-async';
import Button from '@/components/Button';
import { useSelector } from 'react-redux';

const EmailVerifiedPage = () => {
  const { userId } = useSelector((state) => state.user);
  return (
    <>
      <Helmet>
        <title>
          Email Verified / Communa | Give Your Opinion. Earn Rewards
        </title>
      </Helmet>
      <section className="bg-grey-200">
        <section className="max-w-md mx-auto py-16 px-8 flex flex-col items-center">
          <h1 className="text-2xl md:text-3xl text-center mb-0">
            Your Email is Verified!
          </h1>
          <div className="pb-8">
            <img
              src={process.env.PUBLIC_URL + '/img/i_c_dkmb_EmailVerified.svg'}
              alt="Email verified successfully"
              height="180"
              width="250"
              className="m-auto"
            />
          </div>
          <p className="text-center text-lg md:text-xl mb-8 leading-relaxed">
            Thank you for verifying your email.
            <br />
            {userId ? 'Go back to the dashboard ' : 'Log in'} to earn some more
            points. Keep up the good&nbsp;work.
          </p>
          {userId ? (
            <Button to="/dashboard" variant="outline">
              Return to Dashboard
            </Button>
          ) : (
            <Button to="/login" variant="outline">
              Log In To Communa
            </Button>
          )}
        </section>
      </section>
    </>
  );
};

export default EmailVerifiedPage;
