import { createSlice } from '@reduxjs/toolkit';

export const sponsorsSlice = createSlice({
  name: 'sponsors',
  initialState: {
    list: [],
  },
  reducers: {
    setSponsors: (state, { payload }) => {
      state.list = payload;
    },
  },
});

export const { setSponsors } = sponsorsSlice.actions;

export default sponsorsSlice.reducer;
