import PropTypes from 'prop-types';
import { useState } from 'react';

const FaqItem = ({ title, content }) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="border-b border-grey-200 mb-5 pb-5">
      <button
        onClick={() => setShowDetails(!showDetails)}
        className={`hover:text-blue ${
          showDetails ? 'text-blue' : ''
        } transition-colors flex items-center justify-between duration-200 font-bold w-full text-left`}
      >
        <span>{title}</span>
        <span className="w-5 h-5 rounded-full bg-grey-200 flex items-center justify-center">
          {showDetails ? '-' : '+'}
        </span>
      </button>
      <p
        className={`leading-relaxed overflow-hidden transition-all duration-200 ${
          showDetails
            ? ' transform scale-100 max-h-999 opacity-100 pt-4'
            : ' scale-95 max-h-0 opacity-0'
        }`}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

FaqItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default FaqItem;
