import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const BlogArticle = ({ article, isSingle = false }) => {
  const {
    id,
    author_name,
    title,
    slug,
    summary,
    content,
    date_string,
    featured_image,
  } = article;

  return (
    <article
      itemScope
      itemType="http://schema.org/BlogPosting"
      className={isSingle ? '' : 'pb-5 mb-8 border-b border-grey-250'}
    >
      <header className="mb-4">
        {isSingle ? (
          <h1 itemProp="headline" className="text-2xl md:text-3xl mb-3">
            {title}
          </h1>
        ) : (
          <Link itemProp="url" to={`/blog/${id}/${slug}`}>
            <h2
              itemProp="headline"
              className="hover:text-blue transition-colors duration-200 text-2xl md:text-3xl mb-3"
            >
              {title}
            </h2>
          </Link>
        )}

        <div
          className="font-bold leading-none"
          itemScope
          itemProp="author"
          itemType="https://schema.org/Person"
        >
          {author_name}
        </div>
        <div className="text-grey" itemProp="datePublished">
          {date_string}
        </div>
      </header>
      <img itemProp="image" src={featured_image} alt={`${title} featured`} />
      {isSingle ? (
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className="page text-lg mt-5"
        />
      ) : (
        <p itemProp="articleBody" className="text-lg my-4">
          {summary}{' '}
          <Link
            itemProp="url"
            className="text-blue hover:text-blue-400 transition-colors duration-200"
            to={`/blog/${id}/${slug}`}
          >
            Continue Reading
          </Link>
        </p>
      )}

      <div />
    </article>
  );
};

BlogArticle.propTypes = {
  article: PropTypes.object.isRequired,
  isSingle: PropTypes.bool,
};

export default BlogArticle;
