import { useRef, useEffect } from 'react';

const TaskTutorialInfo = () => {
  const ref = useRef();

  const scrollToTutorial = () => {
    const el = ref.current?.getBoundingClientRect();
    if (el) {
      const scrollTopDistance = window.scrollY + el.top - 80;
      window.scrollTo({
        top: scrollTopDistance,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    scrollToTutorial();
    window.addEventListener('resize', () => scrollToTutorial());
    return window.removeEventListener('resize', () => scrollToTutorial());
  }, []);

  return (
    <div
      ref={ref}
      className="absolute z-200 bg-green rounded p-3 bottom-36 animate-fadeInTop"
    >
      <h4 className="text-white">Let&apos;s get to know each other!</h4>
      <p className="text-white">
        In order to provide you the best offer we need to get to know a bit
        about you.
      </p>
      <div className="w-4 h-4 rounded bg-green transform rotate-45 absolute -bottom-1.5" />
    </div>
  );
};

export default TaskTutorialInfo;
