import { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const [showBtn, setShowBtn] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const onScroll = () => {
    if (window.scrollY > 200 && !showBtn) {
      setShowBtn(true);
    }
    if (window.scrollY < 200) {
      setShowBtn(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <button
      onClick={scrollToTop}
      className={`z-100 fixed bottom-24 right-5 md:bottom-10 md:right-10 rounded-full bg-orange-500 text-white shadow p-2.5 ${
        showBtn ? 'opacity-100 visible' : 'invisible opacity-0'
      } transition-all duration-200`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-5 -7.5 24 24"
        width="24"
        height="24"
      >
        <path
          fill="currentColor"
          d="M7.071 2.828l-4.95 4.95A1 1 0 0 1 .707 6.364L6.364.707a1 1 0 0 1 1.414 0l5.657 5.657a1 1 0 0 1-1.414 1.414l-4.95-4.95z"
        />
      </svg>
    </button>
  );
}
