import PropTypes from 'prop-types';

const BgImg = ({ images, redeemable = false }) => (
  <img
    srcSet={`
          ${images['1200w-326ppi']} 1200w,
          ${images['300w-326ppi']} 300w,
          ${images['278w-326ppi']} 278w,
          ${images['200w-326ppi']} 200w,
          ${images['130w-326ppi']} 130w
          `}
    src={images['80w-326ppi']}
    className={`rounded-2xl  ${redeemable ? 'opacity-100' : 'opacity-60'} `}
    alt="reward bg"
  />
);

BgImg.propTypes = {
  images: PropTypes.object.isRequired,
  redeemable: PropTypes.bool,
};

export default BgImg;
