import { useState } from 'react';
import Button from '@/components/Button';
import FormItem from '@/components/FormItem';
import FormWrapper from '@/components/FormWrapper';
import GoogleRecaptcha from '@/components/GoogleRecaptcha';
import Alert from '@/components/Alert';
import { post } from '@/config/axios';
import { makeFieldErrors } from '@/utils/helpers';

const ContactForm = () => {
  const defaultValues = {
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    subject: '',
    message: '',
    'g-recaptcha-response': '',
  };

  const [formData, setFormData] = useState(defaultValues);
  const [formError, setFormDataError] = useState();
  const [formSuccess, setFormDataSuccess] = useState(false);
  const [fieldErrors, setFieldErrors] = useState(defaultValues);
  const [loading, setLoading] = useState(false);

  const handleOnChange = ({ target }) => {
    const { name, value } = target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOnSubmit = async () => {
    setFieldErrors();
    setFormDataError();
    setFormDataSuccess(false);
    setLoading(true);

    try {
      const { data } = await post(
        'http://postform.donkeymob.com/v1/contact?location=www.communa.com.contact',
        formData
      );
      setFormDataSuccess(true);
    } catch (err) {
      const { fieldErrors, formError } = makeFieldErrors(
        err?.response?.data?.errors
      );
      if (fieldErrors) {
        setFieldErrors(fieldErrors);
      }
      if (formError) {
        setFormDataError(formError);
      }
      setLoading(false);
    }
  };

  if (formSuccess) {
    return <Alert>Your message has been successfully sent!</Alert>;
  }

  return (
    <div>
      <h2 className="mb-6">Say Hello! Don&apos;t be Shy</h2>
      <FormWrapper onSubmit={handleOnSubmit} error={formError}>
        <div className="grid sm:grid-cols-2 gap-4">
          <FormItem
            placeholder="First Name*"
            name="first_name"
            required
            onChange={(e) => setFormData(handleOnChange(e))}
            error={fieldErrors?.first_name}
          />
          <FormItem
            placeholder="Last Name*"
            name="last_name"
            required
            onChange={(e) => setFormData(handleOnChange(e))}
            error={fieldErrors?.last_name}
          />
          <FormItem
            placeholder="Phone Number"
            name="phone_number"
            onChange={(e) => setFormData(handleOnChange(e))}
            error={fieldErrors?.phone_number}
          />
          <FormItem
            placeholder="Email Address*"
            name="email"
            required
            onChange={(e) => setFormData(handleOnChange(e))}
            error={fieldErrors?.email}
          />
        </div>
        <div className="my-4">
          <FormItem
            placeholder="Subject*"
            name="subject"
            required
            onChange={(e) => setFormData(handleOnChange(e))}
            error={fieldErrors?.subject}
          />
        </div>
        <div className="my-3">
          <FormItem
            type="textarea"
            name="message"
            placeholder="Enter your message here*"
            onChange={(e) => setFormData(handleOnChange(e))}
            error={fieldErrors?.message}
          />
        </div>
        <GoogleRecaptcha
          onChange={(token) =>
            setFormData({ ...formData, 'g-recaptcha-response': token })
          }
        />
        <div className="flex justify-end mt-3">
          <Button loading={loading} type="submit" variant="success">
            Submit
          </Button>
        </div>
      </FormWrapper>
    </div>
  );
};

export default ContactForm;
