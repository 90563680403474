import PropTypes from 'prop-types';

const ProgressBar = ({ progress }) => {
  return (
    <div className="relative">
      <div className="h-3 absolute inset-0.5">
        <div
          className="bg-blue transition-all duration-200 rounded-full h-full"
          style={{ width: `${progress * 100}%` }}
        />
      </div>
      <div className="bg-gray-200 rounded-full w-full h-4 shadow-inner" />
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.string.isRequired,
};

export default ProgressBar;
