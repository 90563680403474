import TaskCategory from '@/components/TaskCategory/TaskCategory';
import PropTypes from 'prop-types';

const TaskHeader = ({ task, category, children }) => {
  const {
    design_class,
    design_color,
    offer_image,
    task_type,
    name,
    is_narrow_card,
  } = task;

  // TODO card color should not be hardcoded
  const isPeanutLabs =
    !['featured', 'sponsored', 'trending_surveys'].includes(category) &&
    (task_type === 'hot_surveys' || design_class === 'PeanutLabs');
  const isGoal = task_type === 'goal';
  const isNarrowCard = is_narrow_card;

  var cardHeight = 'h-28';
  if (isNarrowCard === true) {
    cardHeight = 'h-20';
  } else if (isGoal === true) {
    cardHeight = 'h-40';
  }

  return (
    <section
      className={`${design_color || 'card_base'} ${cardHeight} ${
        isPeanutLabs ? 'dark_blue' : ''
      } ${design_class} overflow-hidden relative`}
    >
      {offer_image && !isPeanutLabs && (
        <img
          className="w-24 absolute right-0 top-4"
          src={offer_image}
          alt={`${name}`}
        />
      )}
      <div className="p-3 relative">{children}</div>
    </section>
  );
};

TaskHeader.propTypes = {
  task: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  category: PropTypes.string,
};

export default TaskHeader;
