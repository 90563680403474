import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Confetti from 'react-confetti';
import { useSelector, useDispatch } from 'react-redux';
import TaskDetailHeader from './TaskDetailHeader';
import Button from '@/components/Button';
import { setAddPoints, setReducePoints } from '@/store/slices/userSlice';
import { setRefreshTasks } from '@/store/slices/eventsSlice';
import { useHistory } from 'react-router-dom';
import { logAnalyticEvent } from '@/config/analytics';

const TaskEventCongratulation = ({ task, onClose }) => {
  const { hasCollectedPoints } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const { title, message, points, submitText, type } = task;
  const [isCollected, setIsCollected] = useState(false);
  const [showConfettis, setShowConfettis] = useState(false);

  const isRedeem = type === 'REDEEM_COMPLETE';

  const taskClone = {
    ...task,
    name: title,
    design_class: isRedeem ? 'Reward' : 'Trophy',
  };

  const btnLabel = submitText || isRedeem ? 'OK' : 'Collect';

  const handleOnClick = async () => {
    if (isRedeem) {
      history.push('/dashboard');
      dispatch(setReducePoints(points));
      onClose();
      return;
    }
    setIsCollected(true);
    dispatch(setAddPoints(points));
  };

  const handleOnClose = () => {
    if (isRedeem) {
      dispatch(setReducePoints(points));
      onClose();
      return;
    }
  };

  useEffect(() => {
    // If they've completed the first time user profile, dispatch facebook tracking event
    if (type === 'GENERALPROFILER2105_PROFILER_COMPLETE') {
      if (typeof fbq === 'function') {
        fbq('track', 'SubmitApplication');
      }
    }
  }, [type]);

  useEffect(() => {
    if (hasCollectedPoints) {
      dispatch(setRefreshTasks(true));
      onClose();
    }
  }, [hasCollectedPoints]);

  useEffect(() => {
    setShowConfettis(true);

    logAnalyticEvent('rewardpopup', 'impression', {
      points,
    });
  }, []);

  return (
    <div ref={ref} className="task-single bg-grey-50">
      <TaskDetailHeader task={taskClone} onClose={handleOnClose} />
      {showConfettis && (
        <div className="absolute top-0">
          <Confetti
            numberOfPieces={250}
            tweenDuration={800}
            recycle={false}
            width={800}
            height={800}
            onConfettiComplete={() => setShowConfettis(false)}
          />
        </div>
      )}
      <section id="coinsStart" className="mt-4">
        <p
          className="px-5 md:px-8 text-lg text-center"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </section>

      <footer className="p-5 pt-8 md:p-8">
        <Button
          onClick={handleOnClick}
          variant="success"
          disabled={isCollected}
          className="w-full"
        >
          {btnLabel}
        </Button>
      </footer>
    </div>
  );
};

TaskEventCongratulation.propTypes = {
  task: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TaskEventCongratulation;
