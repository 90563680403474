import PropTypes from 'prop-types';
import OptionsRadio from './OptionsRadio';
import OptionsMulti from './OptionsMulti';
import OptionsMatrix from './OptionsMatrix';
import OptionsSelect from './OptionsSelect';
import OptionsNumber from './OptionsNumber';
import OptionsLikert from './OptionsLikert';
import OptionsDate from './OptionsDate';
import OptionsAddress from './OptionsAddress';
import OptionText from './OptionText';

const QuestionsDispatcher = ({ data, onChange }) => {
  const { question_type, type_specific_config, answer_set } = data;

  if (question_type === 'option' && type_specific_config?.multi) {
    return <OptionsMulti data={data} onChange={onChange} />;
  }

  if (question_type === 'option') {
    return answer_set.length > 24 ? (
      <OptionsSelect data={data} onChange={onChange} />
    ) : (
      <OptionsRadio data={data} onChange={onChange} />
    );
  }

  if (question_type === 'option_matrix') {
    return <OptionsMatrix data={data} onChange={onChange} />;
  }

  if (question_type === 'number') {
    return <OptionsNumber data={data} onChange={onChange} />;
  }

  if (question_type === 'likert_matrix') {
    return <OptionsLikert data={data} onChange={onChange} />;
  }

  if (question_type === 'date') {
    return <OptionsDate data={data} onChange={onChange} />;
  }

  if (question_type === 'address') {
    return <OptionsAddress data={data} onChange={onChange} />;
  }

  if (question_type === 'text') {
    return <OptionText data={data} onChange={onChange} />;
  }

  return <></>;
};

QuestionsDispatcher.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default QuestionsDispatcher;
