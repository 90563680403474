import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from '@/config/axios';
import Modal from '@/components/Modal';
import TaskDispatcher from './components/TaskDispatcher';
import TaskSkeleton from './components/TaskSkeleton';

const TaskModal = ({
  onClose,
  taskId,
  pId = null,
  subtaskId = null,
  rfgId = null,
  source,
  onToggleProfiler = null,
  bypassIntro = false,
  taskOverride = null,
}) => {
  const [taskData, setTaskData] = useState();
  const [rateLimitData, setRateLimitData] = useState();
  const [loading, setLoading] = useState(true);
  const [showProfilerModal, setShowProfilerModal] = useState(false);

  const loadTaskDetails = async () => {
    setLoading(true);

    if (taskOverride) {
      setTaskData(taskOverride);
      setLoading(false);
      return;
    }

    const taskParams = {
      source,
      task_id: taskId,
    };

    if (pId) {
      taskParams.p_id = pId;
    }

    if (subtaskId) {
      taskParams.subtask_id = subtaskId;
    }

    if (rfgId) {
      taskParams.rfg_id = rfgId;
    }

    try {
      const {
        data: { data },
      } = await get('v1/task/load-by-id', taskParams);

      data?.rate_limiting && setRateLimitData(data.rate_limiting);
      data?.task && setTaskData(data.task);
      setLoading(false);
    } catch (err) {
      // TODO: Handle errors
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadTaskDetails();
  }, []);

  return (
    <Modal onClose={onClose}>
      {(onClose) =>
        loading ? (
          <TaskSkeleton onClose={onClose} />
        ) : (
          <TaskDispatcher
            task={taskData}
            rate={rateLimitData}
            onClose={onClose}
            onToggleProfiler={onToggleProfiler}
            bypassIntro={bypassIntro}
          />
        )
      }
    </Modal>
  );
};

TaskModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onToggleProfiler: PropTypes.func,
  source: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  pId: PropTypes.string,
  subtaskId: PropTypes.string,
  rfgId: PropTypes.string,
  bypassIntro: PropTypes.bool,
  taskOverride: PropTypes.object,
};

export default TaskModal;
