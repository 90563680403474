import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GoogleRecaptcha from '@/components/GoogleRecaptcha';
import TaskInfo from './TaskInfo';
import Button from '@/components/Button';
import TaskDetailHeader from './TaskDetailHeader';
import TaskRateLimitCountDown from './TaskRateLimitCountDown';

const TaskDefault = ({ task, rate, onClose, onClick }) => {
  const {
    points,
    length,
    requires_captcha,
    popup,
    upto,
    bonus_points,
    popup_credit_delay,
  } = task;
  const {
    popup_common,
    popup_rate_section_once,
    survey_interval_check,
    countdown_seconds,
  } = rate;
  const [disableBtn, setDisableBtn] = useState(requires_captcha);
  const [isFailed, setIsFailed] = useState(survey_interval_check === 'fail');

  const handleCaptcha = () => {
    setDisableBtn(false);
  };

  const handleOnClick = () => {
    !isFailed && onClick();
    onClose();
  };

  const to = isFailed ? '/dashboard' : null;

  const btnLabel = isFailed
    ? 'Back to Dashboard'
    : 'I Understand, Please Proceed';

  return (
    <div className="task-single bg-grey-50">
      <TaskDetailHeader task={task} onClose={onClose} />

      {isFailed ? (
        <>
          <p className="px-5 my-4 md:px-8 text-lg text-center">
            Please wait a few moments to try again.
            <br />
            Check back in:
          </p>
          <TaskRateLimitCountDown
            seconds={countdown_seconds}
            hasEnded={() => setIsFailed(false)}
          />
        </>
      ) : (
        <>
          <TaskInfo
            points={points}
            length={length}
            upto={upto}
            bonusPoints={bonus_points}
          />

          <section className="mt-2 px-5 md:px-8 text-lg text-center">
            {popup && (
              <div
                className="content mb-5 text-grey-500"
                dangerouslySetInnerHTML={{ __html: popup }}
              />
            )}
            {upto && (
              <p className="mb-5">
                Data security is important to us! Due to privacy policies and
                3rd party data policies, you may not qualify for certain surveys
                or offers. This may result in different reward results per user.
              </p>
            )}
            <p>
              {popup_common ||
                'Before you start, remember that quality is important to us and our partners. Please answer honestly and do not rush.'}
            </p>

            {popup_credit_delay && (
              <p className="text-lg text-center mt-5">{popup_credit_delay}</p>
            )}
            {popup_rate_section_once && (
              <p className="text-lg text-center mt-5">
                {popup_rate_section_once}
              </p>
            )}
            <div className="mt-4 flex justify-center">
              {requires_captcha && <GoogleRecaptcha onChange={handleCaptcha} />}
            </div>
          </section>
        </>
      )}

      <footer className="p-5 pt-8 md:p-8">
        <Button
          onClick={handleOnClick}
          to={to}
          variant="success"
          className="w-full"
          disabled={disableBtn}
        >
          {btnLabel}
        </Button>
      </footer>
    </div>
  );
};

TaskDefault.propTypes = {
  task: PropTypes.object.isRequired,
  rate: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TaskDefault;
