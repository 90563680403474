import PropTypes from 'prop-types';
import Alert from '@/components/Alert/Alert';

const ProfilerErrors = ({ errors }) => {
  if (errors[0] && errors[0].code) {
    const error = {
      1: 'Invalid street address',
      2: 'Invalid city',
      3: 'Invalid postal code',
      4: 'Invalid country',
      5: 'Invalid region',
      6: 'Invalid address',
      33: "We're experiencing difficulties. Please try again later",
    };

    return (
      <div className="mt-4">
        <Alert type="error">{error[errors[0].code]}</Alert>
      </div>
    );
  }

  return <></>;
};

ProfilerErrors.propTypes = {
  errors: PropTypes.array.isRequired,
};

export default ProfilerErrors;
