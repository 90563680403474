import PropTypes from 'prop-types';

const SignUpTitle = ({ title }) => (
  <h2 className="text-center flex items-center justify-center text-blue-400">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 32 50">
      <path
        fill="currentColor"
        d="M31.2075899,35.3128317 C31.6848626,36.3628317 31.342094,37.5039474 30.3532323,38.0403862 L30.2075899,38.1128317 C25.1348626,40.5673771 20.3968461,43.1558069 15.5553735,45.878121 L12.3075899,47.7128317 L8.90758988,49.6128317 C8.60758988,49.8128317 8.30758988,49.9128317 7.90758988,49.9128317 C7.20758988,49.9128317 6.40758988,49.5128317 6.00758988,48.8128317 C5.40758988,47.8128317 5.80758988,46.5128317 6.80758988,45.9128317 L10.2075899,44.0128317 C16.0075899,40.7128317 22.1075899,37.3128317 28.4075899,34.3128317 C29.5075899,33.8128317 30.7075899,34.2128317 31.2075899,35.3128317 Z M27.4075899,22.7128317 C28.6075899,22.8128317 29.4075899,23.8128317 29.3075899,25.0128317 C29.2075899,26.2128317 28.1075899,27.0128317 27.0075899,26.9128317 C23.8075899,26.5928317 20.6075899,26.7208317 17.2539899,26.9384317 L14.7075899,27.1128317 C12.0075899,27.4128317 9.20758988,27.6128317 6.30758988,27.6128317 C5.10758988,27.6128317 3.83412049,27.6128317 2.61312924,27.4868842 L2.00758988,27.4128317 C0.807589881,27.3128317 -0.0924101193,26.3128317 0.0075898807,25.1128317 C0.107589881,23.9128317 1.10758988,23.1128317 2.30758988,23.1128317 C6.30758988,23.4128317 10.5075899,23.2128317 14.5075899,22.9128317 C18.7075899,22.6128317 23.1075899,22.4128317 27.4075899,22.7128317 Z M8.80758988,0.212831695 C14.0575899,2.71283169 19.0992565,5.49060947 24.0483306,8.25681318 L30.4075899,11.8128317 C31.4075899,12.4128317 31.8075899,13.7128317 31.2075899,14.7128317 C30.9075899,15.4128317 30.1075899,15.8128317 29.4075899,15.8128317 C29.0075899,15.8128317 28.7075899,15.7128317 28.4075899,15.5128317 L23.5324163,12.7860956 L20.5779024,11.1284567 C16.1388399,8.64408169 11.6575899,6.18783169 7.00758988,4.01283169 C5.90758988,3.51283169 5.50758988,2.21283169 6.00758988,1.21283169 C6.50758988,0.112831695 7.80758988,-0.287168305 8.80758988,0.212831695 Z"
      />
    </svg>
    <span className="text-grey-900 px-2 sm:px-4">{title}</span>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 32 50">
      <path
        fill="currentColor"
        d="M0.281907993,35.3128317 C-0.195364735,36.3628317 0.14740386,37.5039474 1.13626562,38.0403862 L1.28190799,38.1128317 C6.35463527,40.5673771 11.0926518,43.1558069 15.9341244,45.878121 L19.181908,47.7128317 L22.581908,49.6128317 C22.881908,49.8128317 23.181908,49.9128317 23.581908,49.9128317 C24.281908,49.9128317 25.081908,49.5128317 25.481908,48.8128317 C26.081908,47.8128317 25.681908,46.5128317 24.681908,45.9128317 L21.281908,44.0128317 L18.357371,42.3568132 C13.4430191,39.5906095 8.33190799,36.8128317 3.08190799,34.3128317 C1.98190799,33.8128317 0.781907993,34.2128317 0.281907993,35.3128317 Z M4.08190799,22.7128317 C2.88190799,22.8128317 2.08190799,23.8128317 2.18190799,25.0128317 C2.28190799,26.2128317 3.38190799,27.0128317 4.48190799,26.9128317 C7.68190799,26.5928317 10.881908,26.7208317 14.235508,26.9384317 L16.781908,27.1128317 C19.481908,27.4128317 22.281908,27.6128317 25.181908,27.6128317 C26.381908,27.6128317 27.6553774,27.6128317 28.8763686,27.4868842 L29.481908,27.4128317 C30.681908,27.3128317 31.581908,26.3128317 31.481908,25.1128317 C31.381908,23.9128317 30.381908,23.1128317 29.181908,23.1128317 C25.181908,23.4128317 20.981908,23.2128317 16.981908,22.9128317 C12.781908,22.6128317 8.38190799,22.4128317 4.08190799,22.7128317 Z M22.681908,0.212831695 C17.431908,2.71283169 12.3902413,5.49060947 7.44116725,8.25681318 L1.08190799,11.8128317 C0.0819079927,12.4128317 -0.318092007,13.7128317 0.281907993,14.7128317 C0.581907993,15.4128317 1.38190799,15.8128317 2.08190799,15.8128317 C2.48190799,15.8128317 2.78190799,15.7128317 3.08190799,15.5128317 L7.9570816,12.7860956 L10.9115955,11.1284567 C15.350658,8.64408169 19.831908,6.18783169 24.481908,4.01283169 C25.581908,3.51283169 25.981908,2.21283169 25.481908,1.21283169 C24.981908,0.112831695 23.681908,-0.287168305 22.681908,0.212831695 Z"
      />
    </svg>
  </h2>
);

SignUpTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SignUpTitle;
