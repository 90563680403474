import actionsSlice from './actionsSlice';
import configSlice from './configSlice';
import eventsSlice from './eventsSlice';
import featureFlagsSlice from './featureFlagsSlice';
import pageSlice from './pageSlice';
import sponsorsSlice from './sponsorsSlice';
import userSlice from './userSlice';

export default {
  actions: actionsSlice,
  config: configSlice,
  events: eventsSlice,
  flags: featureFlagsSlice,
  sponsors: sponsorsSlice,
  page: pageSlice,
  user: userSlice,
};
