import PropTypes from 'prop-types';
import Button from '@/components/Button';
import TaskDetailHeader from './TaskDetailHeader';

const TaskBallotDrawReward = ({ onClose }) => {
  const task = {
    name: 'Congratulations! You Won!',
    design_class: 'Trophy',
  };

  return (
    <div className="task-single bg-grey-50">
      <TaskDetailHeader task={task} onClose={onClose} />
      <section className="mt-4">
        <p className="px-5 md:px-8 md:text-lg text-center">
          You&apos;ve won our Monthly Draw and have earned 5,000 Pts! Click
          &apos;Claim Your Reward&apos; to receive your prize.
          <br />
          <br />
          Thanks for using Communa! Keep logging in for more chances to earn.
        </p>
      </section>

      <footer className="p-5 pt-8 md:p-8">
        <Button onClick={onClose} variant="success" className="w-full">
          Claim Your Reward
        </Button>
      </footer>
    </div>
  );
};

TaskBallotDrawReward.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default TaskBallotDrawReward;
