import ErrorInner from '@/components/ErrorInner';

const ErrorPage = () => (
  <ErrorInner
    title="Sorry for the interruption."
    content="Something went wrong on our side, we're working on it. <br /> In the meantime, you can go back to the dashboard."
  />
);

export default ErrorPage;
