import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setShowGlobalCurtain } from '@/store/slices/configSlice';

const GoogleLoginButton = ({
  label = 'Login with Google',
  onError = null,
  onClick,
  onSuccess,
  className = '',
}) => {
  const dispatch = useDispatch();
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const btnRef = useRef();

  const initAuth2 = () => {
    gapi.load('auth2', () => {
      window.auth2 = gapi.auth2.init({
        client_id: CLIENT_ID,
        cookiepolicy: 'single_host_origin',
        scope: 'email',
      });
      attachClickHandler();
    });
  };

  const attachClickHandler = () => {
    window.auth2.attachClickHandler(
      btnRef.current,
      {},
      (googleUser) => {
        // TODO: Moving this comment from communa.js back here:
        ///// Fix hacks below, placeholders used to bypass required parameters problem
        const params = {
          email: googleUser.getBasicProfile().getEmail(),
          password: googleUser.getBasicProfile().getId(),
          id: googleUser.getBasicProfile().getId(),
          source: 'google',
          access_token: googleUser.getAuthResponse().id_token,
        };
        dispatch(setShowGlobalCurtain(true));
        onClick();
        onSuccess(params);
      },
      ({ error }) => {
        const msg =
          error === 'popup_closed_by_user'
            ? 'Google popup has been closed while attempting to authenticate you. Please try again'
            : 'Something went wrong. Please try again or select another method.';
        onError(msg);
      }
    );
  };

  useEffect(() => {
    initAuth2();
  }, []);

  return (
    <button
      className={`rounded-full flex items-center justify-center relative px-4 h-11 w-full border border-grey-300 bg-white hover:opacity-75 active:opacity-95 transition-all duration-200 ${className}`}
      ref={btnRef}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        className="absolute left-5"
      >
        <path
          fill="#F44336"
          d="M9.79704158,4.16666667 C11.1169972,4.16666667 12.3289705,4.62793983 13.2964762,5.39505008 L16.2659154,2.50340783 C14.539444,0.949961333 12.279489,0 9.79704158,0 C6.0353001,0 2.77310773,2.1665955 1.13139763,5.34154258 L4.43335464,8.00231933 C5.23323108,5.76614383 7.32939377,4.16666667 9.79704158,4.16666667 Z"
        />
        <path
          fill="#2196F3"
          d="M19.5092221,11.2515259 C19.5602483,10.8418274 19.5940832,10.4238892 19.5940832,10 C19.5940832,9.28512575 19.5175936,8.58901975 19.3779192,7.91666667 L9.79704158,7.91666667 L9.79704158,12.0833333 L15.0925108,12.0833333 C14.6641195,13.2198079 13.9013666,14.1814677 12.9304724,14.8495483 L16.2446876,17.5202433 C18.0015556,15.9461975 19.203812,13.7419637 19.5092221,11.2515259 Z"
        />
        <path
          fill="#FFC107"
          d="M4.08210066,10 C4.08210066,9.29712933 4.21016465,8.62635292 4.43335464,8.00231933 L1.13139763,5.34154258 C0.411748022,6.73334758 0,8.316803 0,10 C0,11.6643778 0.404173848,13.2302857 1.10887431,14.6107992 L4.41501703,11.9466146 C4.20328802,11.337026 4.08210066,10.6836955 4.08210066,10 Z"
        />
        <path
          fill="#00B060"
          d="M9.79704158,15.8333333 C7.31060778,15.8333333 5.20094109,14.2095947 4.41501711,11.9466146 L1.10887431,14.6107992 C2.74256169,17.8112284 6.01686288,20 9.79704158,20 C12.2689748,20 14.5230996,19.0626526 16.2446876,17.5202433 L12.9304724,14.8495483 C12.0350213,15.4657491 10.9632208,15.8333333 9.79704158,15.8333333 Z"
        />
      </svg>

      {label}
    </button>
  );
};

GoogleLoginButton.propTypes = {
  label: PropTypes.string,
  onError: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default GoogleLoginButton;
