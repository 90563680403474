import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import TaskDetailHeader from './TaskDetailHeader';

const Task30DayHold = ({ onClose }) => {
  const { data } = useSelector((state) => state.user);

  const task = {
    name: 'Please Be Patient!',
    offer_image: '/img/task/calendar-30-day.png',
  };

  const getDate = () => {
    const date = new Date(data.signup_time * 1000);
    date.setDate(date.getDate() + 30);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      day: '2-digit',
      month: 'long',
    });
  };

  return (
    <div className="task-single bg-grey-50">
      <TaskDetailHeader task={task} onClose={onClose} />
      <section className="mt-4">
        <p className="px-5 md:px-8 md:text-lg text-center">
          In order to reduce fraudulent accounts, we have placed a 30 day hold
          on all new users looking to redeem their points. Please check back
          after {getDate()} to receive your gift card.
        </p>
      </section>

      <footer className="p-5 pt-8 md:p-8">
        <Button to="/dashboard" variant="success" className="w-full">
          Back To Dashboard
        </Button>
      </footer>
    </div>
  );
};

Task30DayHold.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Task30DayHold;
