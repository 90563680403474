import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import useAuth from '@/hooks/useAuth';
import SignUpTitle from '@/components/SignUpTitle';
import Modal from '@/components/Modal';
import FormWrapper from '@/components/FormWrapper';
import FormItem from '@/components/FormItem';
import Button from '@/components/Button';
import GoogleRecaptcha from '@/components/GoogleRecaptcha';
import { logAnalyticEvent } from '@/config/analytics';

const SignupModal = ({ onClose }) => {
  const history = useHistory();
  const { cmpn } = useSelector((state) => state.config);
  const { logoutSessionId, csrf } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    email_communa: null,
    password_communa: null,
    password_communa_1: null,
    recaptcha: null,
  });
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState();
  const [formError, setFormError] = useState();
  const auth = useAuth();

  const signUpUser = async (data = null, isSocial = false) => {
    logAnalyticEvent('signup', 'register_attempt');
    setLoading(true);

    const postData = {
      ...formData,
      session_id: logoutSessionId,
      csrf: csrf,
    };

    if (cmpn) {
      postData.cmpn = cmpn;
    }

    const testingVariants = JSON.parse(
      localStorage.getItem('testing-variants')
    );

    if (testingVariants?.signup_page_image?.test_id) {
      postData.test_id = testingVariants.signup_page_image.test_id;
    }

    if (testingVariants?.signup_page_image?.variant_id) {
      postData.variant_id = testingVariants.signup_page_image.variant_id;
    }

    const {
      success,
      isSignup,
      formError,
      fieldErrors,
      isMobileOrTabletSignup,
      isFacebookConversion,
    } = await auth.signup(postData, isSocial);

    setLoading(false);

    if (!success) {
      fieldErrors && setFieldErrors(fieldErrors);
      formError && setFormError(formError);
      return;
    }

    if (isSignup) {
      // Facebook tracking

      const analyticsParams = {
        description: 'Application Signup',
        value: 1,
      };

      if (isMobileOrTabletSignup !== null) {
        analyticsParams.is_mobile_or_tablet_signup = isMobileOrTabletSignup;
      }

      if (isFacebookConversion !== null) {
        analyticsParams.is_facebook_conversion = isFacebookConversion;
      }

      fbq('track', 'Lead');
      // Amplitude and GA tracking
      logAnalyticEvent('signup', 'successful', analyticsParams);
    }

    history.push('/dashboard');
  };

  const handleRecaptcha = (recaptcha) => {
    setFormData({
      ...formData,
      recaptcha,
    });
  };

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const isDisabled =
    !formData.email_communa ||
    !formData.password_communa ||
    !formData.password_communa_1 ||
    !formData.recaptcha;

  return (
    <Modal maxWidth="max-w-lg" onClose={onClose}>
      {(onClose) => (
        <section className="bg-grey-50">
          <header className="pt-8">
            <button
              className="hover:opacity-100 opacity-75 duration-200 transition-opacity absolute top-4 right-4 z-10"
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                viewBox="0 0 32 32"
              >
                <polygon
                  fill="currentColor"
                  points="32 3.8 28.2 0 16 12.2 3.8 0 0 3.8 12.2 16 0 28.2 3.8 32 16 19.8 28.2 32 32 28.2 19.8 16"
                />
              </svg>
            </button>
            <SignUpTitle title="Email Sign Up" />
          </header>
          <section className="p-8 pt-5">
            <FormWrapper onSubmit={signUpUser} error={formError}>
              <FormItem
                name="email_communa"
                placeholder="Email Address"
                autoComplete="email"
                value={formData.email_communa}
                onChange={handleOnChange}
                error={fieldErrors?.email_communa}
              />
              <FormItem
                name="password_communa"
                type="password"
                placeholder="Password"
                value={formData.password_communa}
                autoComplete="new-password"
                onChange={handleOnChange}
                error={fieldErrors?.password_communa}
                className="my-5"
              />
              <FormItem
                name="password_communa_1"
                type="password"
                placeholder="Confirm Password"
                value={formData.password_communa_1}
                autoComplete="new-password"
                onChange={handleOnChange}
                error={fieldErrors?.password_communa_1}
                className="my-5"
              />
              <GoogleRecaptcha onChange={handleRecaptcha} />
              <Button
                variant="success"
                type="submit"
                loading={loading}
                disabled={isDisabled}
                className="mt-5 w-full"
              >
                Sign Up Now
              </Button>
            </FormWrapper>
            <div className="text-sm text-center mt-3">
              Already a member?{' '}
              <Link
                to="/login"
                className="hover:text-blue transition-colors duration-200"
              >
                Log in here
              </Link>
              .
            </div>
          </section>
        </section>
      )}
    </Modal>
  );
};

SignupModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SignupModal;
