import PropTypes from 'prop-types';

const TimelineItem = ({ timeline, isLast }) => {
  const { description, pending, date, type, points } = timeline;

  const timelineDate = new Date(date * 1000).toLocaleTimeString(undefined, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour12: '2-digits',
    hour: '2-digit',
    minute: '2-digit',
  });

  const pointsType = points >= 0 ? 'reward' : 'redeem';
  const absPoints = Math.abs(points);

  const isReward = pointsType === 'reward';
  const isRedeem = pointsType === 'redeem';

  const printTitle = () => {
    if (pending) {
      return 'Pending';
    }

    const titles = {
      DAY_FIRST_LANDING_POPUP: 'You collected your Daily Login Reward!',
      WON_BALLOT_DRAW: 'You won a Ballot Draw!',
      BALLOT_EARNED_LOGIN_REWARD: 'You earned a Ballot!',
    };

    return titles[type] || description;
  };

  const printDescription = () => {
    if (pending) {
      return 'This transaction is still being processed';
    }

    const plural = absPoints > 1 ? 's' : '';

    const descriptions = {
      WON_BALLOT_DRAW: `You earned <strong>${absPoints}</strong> Pt${plural}.`,
      BALLOT_EARNED_LOGIN_REWARD: 'You earned <strong>1</strong> ballot.',
      CHRISTMAS_2020_POINTS_REWARD: `'You were gifted <strong>${absPoints}</strong> Pt${plural}.`,
    };

    if (type === 'DAY_FIRST_LANDING_POPUP') return '';

    if (descriptions[type]) return descriptions[type];

    if (isReward) {
      return `You earned <strong>${absPoints}</strong> Pt${plural}.`;
    }

    if (isRedeem) {
      return `You redeemed <strong>${absPoints}</strong> Pt${plural}.`;
    }

    return '';
  };

  return (
    <div
      className={`px-8 py-6 text-grey-700 relative  ${
        !isLast ? 'border-dashed border-b-2 border-grey-275' : ''
      } w-full`}
    >
      <div
        className={`rounded-full w-3.5 h-3.5 bg-green ${
          isReward ? 'bg-green' : 'bg-orange-500'
        } absolute -left-2 -ml-px mt-1.5`}
      />
      <div className="font-oswald text-grey-400 text-sm md:absolute md:-ml-48 md:mt-1">
        {timelineDate}
      </div>
      <h3 className="font-bold text-lg text-grey-700">{printTitle()}</h3>
      <div dangerouslySetInnerHTML={{ __html: printDescription() }} />
    </div>
  );
};

TimelineItem.propTypes = {
  timeline: PropTypes.object.isRequired,
  isLast: PropTypes.bool,
};

export default TimelineItem;
