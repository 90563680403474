import PropTypes from 'prop-types';

const SocialLink = ({ type = 'facebook', className = '' }) => {
  const path =
    type === 'instagram'
      ? 'M25,0 C38.8,0 50,11.2 50,25 C50,38.8 38.8,50 25,50 C11.2,50 0,38.8 0,25 C0,11.2 11.2,0 25,0 Z M31.4672758,8.9665269 L18.7207577,8.9665269 C13.5465283,8.9665269 9.23514184,13.0218894 9.09741091,18.3311577 L9.09401679,18.5932678 L9.09401679,31.339786 C9.09401679,36.5140154 13.1493793,40.8254019 18.4586476,40.9631328 L18.7207577,40.9665269 L31.4672758,40.9665269 C36.6428517,40.9665269 41.0414647,36.8227244 41.0948403,31.5936403 L41.0940168,18.5932678 C41.0940168,13.4190384 37.0386543,9.10765195 31.729386,8.96992102 L31.4672758,8.9665269 Z M31.4672758,11.9971676 C35.04878,11.9971676 37.9454307,14.8221882 38.0598641,18.3748656 L38.0633761,18.5932678 L38.0633761,31.4289224 C38.0633761,35.0104266 35.2383555,37.9070773 31.6856781,38.0215107 L31.4672758,38.0250227 L18.7207577,38.0250227 C15.1392536,38.0250227 12.2426029,35.2000021 12.1281694,31.6473247 L12.1246575,31.4289224 L12.1246575,18.5932678 C12.1246575,15.0117637 14.9496781,12.115113 18.5023555,12.0006795 L18.7207577,11.9971676 L31.4672758,11.9971676 Z M25.138585,17.2562205 C20.770897,17.2562205 17.2054374,20.8216801 17.2054374,25.1893681 C17.2054374,29.5570561 20.770897,33.1225158 25.138585,33.1225158 C29.5062731,33.1225158 33.0717327,29.5570561 33.0717327,25.1893681 C33.0717327,20.8216801 29.5062731,17.2562205 25.138585,17.2562205 Z M25.138585,20.1085882 C27.9446223,20.1085882 30.219365,22.3833308 30.219365,25.1893681 C30.219365,27.9954054 27.9446223,30.2701481 25.138585,30.2701481 C22.3325478,30.2701481 20.0578051,27.9954054 20.0578051,25.1893681 C20.0578051,22.3833308 22.3325478,20.1085882 25.138585,20.1085882 Z M33.5174151,14.8495353 C32.4477772,14.8495353 31.6455488,15.6517637 31.6455488,16.7214016 C31.6455488,17.7910394 32.5369137,18.5932678 33.5174151,18.5932678 C34.4979165,18.5932678 35.3001449,17.7910394 35.3892814,16.7214016 C35.4784179,15.6517637 34.6761895,14.8495353 33.5174151,14.8495353 Z'
      : type === 'twitter'
      ? 'M25,0 C11.2,0 0,11.2 0,25 C0,38.8 11.2,50 25,50 C38.8,50 50,38.8 50,25 C50,11.2 38.8,0 25,0 Z M38.8,16.5 C38.8,16.5 38.8,16.7 38.7,16.9 C38.1,17.7 37.4,18.5 36.5,19.2 C36.4,19.3 36.2,19.4 36.2,19.6 C36.2,24.3 35,28.9 31.6,32.3 C27.3,36.6 20.8,37.8 15,36.2 C13.9,35.9 12.8,35.4 11.8,34.9 C11.5,34.7 11.1,34.5 10.8,34.3 C10.7,34.2 10.6,34.1 10.7,34.1 C10.8,34.1 10.8,34.1 11.1,34.1 C12.7,34.3 14.4,34 15.9,33.5 C16.8,33.2 18.6,32.6 19.1,31.8 C18.9,31.7 18.7,31.7 18.5,31.7 C17,31.2 14.9,30.4 13.9,27.8 C13.9,27.6 13.9,27.6 14.1,27.6 C15.4,27.7 15.9,27.6 16.3,27.5 C16.1,27.5 15.8,27.4 15.6,27.3 C13.6,26.5 12.2,24.6 11.9,22.5 C11.9,22.2 11.9,22 11.9,21.8 C11.9,21.7 12,21.6 12.1,21.7 C13.1,22.2 14.2,22.3 14.3,22.3 C14.1,22.1 13.8,21.9 13.6,21.7 C11.9,20.1 11.2,16.8 12.6,14.7 C12.7,14.5 12.8,14.5 12.9,14.7 C16,18.2 19.8,19.9 24.5,20.6 C24.6,20.7 24.6,20.6 24.6,20.5 C24.5,19.7 24.5,18.9 24.7,18.1 C25,17.3 25.4,16.5 25.9,15.8 C26.4,15.1 27.1,14.6 27.8,14.2 C28.6,13.8 29.4,13.6 30.2,13.5 C31,13.4 31.9,13.6 32.7,13.9 C33.3,14.1 33.8,14.5 34.3,14.9 C34.4,15 34.5,15.1 34.6,15.2 C34.6,15.3 34.7,15.3 34.8,15.3 C36,15 37.1,14.6 38.2,14 C38.3,14 38.3,14 38.4,14 L38.4,14.1 C38.3,14.7 37.9,15.3 37.5,15.8 C37.2,16.1 36.6,16.9 36.1,17 C37.1,16.9 38.1,16.6 39,16.2 C39.1,16.1 39.1,16.1 38.8,16.5 Z'
      : 'M25,0 C38.8,0 50,11.2 50,25 C50,38.8 38.8,50 25,50 C11.2,50 0,38.8 0,25 C0,11.2 11.2,0 25,0 Z M31.6,10.2 L26.5,10.2 C23.5731707,10.2 21.2174301,12.4843546 21.104257,15.3815847 L21.1,15.6 L21.1,19.9 L18.4,19.9 C18.225,19.9 18.05,20.053125 18.0089844,20.2253906 L18,20.3 L18,24.7 C18,24.875 18.153125,25.05 18.3253906,25.0910156 L18.4,25.1 L21.1,25.1 L21.1,39.6 C21.1,39.775 21.253125,39.95 21.4253906,39.9910156 L21.5,40 L26.9,40 C27.075,40 27.25,39.846875 27.2910156,39.6746094 L27.3,39.6 L27.3,24.9 L31.2,24.9 C31.375,24.9 31.55,24.746875 31.5910156,24.6416016 L31.6,24.6 L32,20.2 C32,20.025 31.846875,19.85 31.6746094,19.8089844 L31.6,19.8 L27.3,19.8 L27.3,16.7 C27.3,16.04375 27.8273438,15.4753906 28.4700439,15.4069092 L28.6,15.4 L31.6,15.4 C31.775,15.4 31.95,15.246875 31.9910156,15.0746094 L32,15 L32,10.6 C32,10.425 31.846875,10.25 31.6746094,10.2089844 L31.6,10.2 Z';

  const url =
    type === 'instagram'
      ? 'https://www.instagram.com/communa_online'
      : type === 'twitter'
      ? 'https://twitter.com/communaonline'
      : 'https://www.facebook.com/communaonline';

  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer noopener"
      className={`${className} text-grey-600 hover:text-blue transition-colors duration-200`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 50 50"
      >
        <path fill="currentColor" d={path} />
      </svg>
    </a>
  );
};

SocialLink.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
};

export default SocialLink;
