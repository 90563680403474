import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const OptionsNumber = ({ data, onChange }) => {
  const {
    type_specific_config: { min, max },
  } = data;
  const [value, setValue] = useState(min || 0);

  const handleOnChange = (e) => {
    const invalidChars = ['-', 'e', '+', 'E'];
    const targetValue = e.target.value;

    if (
      invalidChars.includes(targetValue) ||
      isNaN(targetValue) ||
      targetValue > max ||
      targetValue < min
    ) {
      e.preventDefault();
      return;
    }

    setValue(targetValue);
  };

  const onDecrease = () => {
    if (value > min) {
      setValue(Number(value) - 1);
    }
  };

  const onIncrease = () => {
    if (value < max) {
      setValue(Number(value) + 1);
    }
  };

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <div className="flex items-center justify-center mt-8">
      <button
        onClick={onDecrease}
        className="bg-grey-400  w-10 h-10 text-white flex items-center justify-center rounded-tl rounded-bl hover:bg-blue active:bg-blue-600 transition-colors duration-200"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-5 -11 24 24"
          width="24"
          height="24"
        >
          <path
            fill="currentColor"
            d="M1 0h12a1 1 0 0 1 0 2H1a1 1 0 1 1 0-2z"
          />
        </svg>
      </button>
      <input
        min={min}
        max={max}
        type="tel"
        value={value}
        onChange={handleOnChange}
        className="h-10 border w-16 border-grey-400 text-xl text-center"
      />
      <button
        onClick={onIncrease}
        className="bg-grey-400  w-10 h-10 text-white flex items-center justify-center rounded-tr rounded-br hover:bg-blue active:bg-blue-600 transition-colors duration-200"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-4.5 -4.5 24 24"
          width="24"
          height="24"
        >
          <path
            fill="currentColor"
            d="M8.9 6.9v-5a1 1 0 1 0-2 0v5h-5a1 1 0 1 0 0 2h5v5a1 1 0 1 0 2 0v-5h5a1 1 0 1 0 0-2h-5z"
          />
        </svg>
      </button>
    </div>
  );
};

OptionsNumber.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OptionsNumber;
