import { useState } from 'react';
import PropTypes from 'prop-types';
import TaskModal from '@/components/TaskModal';
import Profiler from '@/components/Profiler';
import TaskFooter from './components/TaskFooter';
import TaskPoints from './components/TaskPoints';
import TaskFeatured from './components/TaskFeatured';
import TaskHot from './components/TaskHot';
import TaskHeader from './components/TaskHeader';
import TaskTutorialInfo from './components/TaskTutorialInfo';
import TaskLock from './components/TaskLock';

const TaskCard = ({ task, category, showTutorial = false }) => {
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showProfilerModal, setShowProfilerModal] = useState(false);

  const {
    is_title_visible,
    points,
    upto,
    length,
    name,
    featured,
    task_type,
    project_id,
    profiler_id,
    goal_id,
    task_class,
    offer_image,
    source,
    task_status,
    bonus_points,
    design_class,
    p_id,
    subtask_id,
    rfg_id,
    show_title_on_dashboard_card,
  } = task;

  const showTitle = show_title_on_dashboard_card === false ? false : true;

  const isLocked = [
    'phone_verification_required',
    'email_verification_required',
  ].includes(task_status);

  const taskId = project_id || profiler_id || goal_id;
  return (
    <div className="relative">
      {showTutorial && <TaskTutorialInfo />}
      <article
        onClick={() => setShowTaskModal(true)}
        role="presentation"
        className={`task-single ${
          showTutorial ? 'relative z-200' : ''
        } overflow-hidden rounded-md w-full shadow-md hover:scale-105 transform transition-all duration-200 cursor-pointer`}
      >
        {isLocked && <TaskLock />}
        {featured && <TaskFeatured />}
        {bonus_points && !featured && <TaskHot />}
        <TaskHeader task={task} category={category}>
          <TaskPoints points={points} upto={upto} bonusPoints={bonus_points} />
          {showTitle === true && (
            <div
              className={`font-bold text-white leading-snug ${
                offer_image && showTitle
                  ? 'max-w-13 md:max-w-9 lg:max-w-9.5 xl:max-w-8 2xl:max-w-11.5'
                  : ''
              } ${name.length >= 50 ? 'text-sm' : 'text-base'}`}
            >
              {name}
            </div>
          )}
        </TaskHeader>
        {task.length && (
          <TaskFooter
            bonusPoints={bonus_points}
            length={length}
            featured={featured}
          />
        )}
      </article>
      {showTaskModal && (
        <TaskModal
          taskId={taskId}
          pId={p_id}
          subtaskId={subtask_id}
          rfgId={rfg_id}
          source={task_class || source}
          onClose={() => setShowTaskModal(false)}
          onToggleProfiler={() => setShowProfilerModal(true)}
        />
      )}
      {showProfilerModal && (
        <Profiler
          surveyId={profiler_id}
          title={name}
          onClose={() => setShowProfilerModal(false)}
        />
      )}
    </div>
  );
};

TaskCard.propTypes = {
  task: PropTypes.object.isRequired,
  showTutorial: PropTypes.bool,
  category: PropTypes.string,
};

export default TaskCard;
