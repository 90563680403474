import { Helmet } from 'react-helmet-async';
import ProfileForm from '@/components/ProfileForm';
import ProfilePasswordUpdate from '@/components/ProfilePasswordUpdate';
import ProfilePhone from '@/components/ProfilePhone';
import ProfileDownloadData from '@/components/ProfileDownloadData';

const ProfilePage = () => {
  return (
    <>
      <Helmet>
        <title>Profile / Communa | Give Your Opinion. Earn Rewards</title>
      </Helmet>

      <h1 className="text-2xl">Profile</h1>

      <div className="flex flex-col md:flex-row">
        <div className="flex-1">
          <ProfileForm />
          <div className="pt-6">
            <ProfileDownloadData />
          </div>
        </div>
        <div className="lg:w-full md:w-72 lg:max-w-md md:pl-6 mt-6 md:mt-0">
          <ProfilePasswordUpdate />
          <ProfilePhone />
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
