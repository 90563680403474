import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import useAuth from '@/hooks/useAuth';
import FacebookLoginButton from '@/components/FacebookLoginButton';
import GoogleLoginButton from '@/components/GoogleLoginButton';
import OrDivider from '@/components/OrDivider/OrDivider';
import SignupModal from '@/components/SignupModal';
import SignUpTitle from '@/components/SignUpTitle';
import Alert from '@/components/Alert';
import { logAnalyticEvent } from '@/config/analytics';

const SignForm = () => {
  const { list: flags } = useSelector((state) => state.flags);
  const { cmpn } = useSelector((state) => state.config);
  const history = useHistory();
  const auth = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [formError, setFormError] = useState('');

  const handleOnSubmit = async (formData) => {
    logAnalyticEvent('signup', 'register_attempt');

    const postData = {
      ...formData,
      password_communa: formData.id,
      password_communa_1: formData.id,
      email_communa: formData.email,
    };

    if (cmpn) {
      postData.cmpn = cmpn;
    }

    const testingVariants = JSON.parse(
      localStorage.getItem('testing-variants')
    );

    if (testingVariants?.signup_page_image?.test_id) {
      postData.test_id = testingVariants.signup_page_image.test_id;
    }

    if (testingVariants?.signup_page_image?.variant_id) {
      postData.variant_id = testingVariants.signup_page_image.variant_id;
    }

    delete postData.email;
    delete postData.password;

    const {
      success,
      isSignup,
      formError,
      isMobileOrTabletSignup,
      isFacebookConversion,
    } = await auth.signup(postData, true);

    if (!success) {
      formError && setFormError(formError);
      return;
    }

    if (isSignup) {
      // Facebook tracking

      const analyticsParams = {
        description: 'Application Signup',
        value: 1,
      };

      if (isMobileOrTabletSignup !== null) {
        analyticsParams.is_mobile_or_tablet_signup = isMobileOrTabletSignup;
      }

      if (isFacebookConversion !== null) {
        analyticsParams.is_facebook_conversion = isFacebookConversion;
      }

      fbq('track', 'Lead');
      // Amplitude and GA tracking
      logAnalyticEvent('signup', 'successful', analyticsParams);
    }

    history.push('/dashboard');
  };

  return (
    <>
      <section className="rounded-xl border bg-grey-200 border-grey-300 px-5 py-6">
        {formError && <Alert type="error">{formError}</Alert>}
        <SignUpTitle title="Sign Up For Free" />
        {flags && flags.facebook_authentication && (
          <FacebookLoginButton
            label="Sign Up with Facebook"
            onClick={() => setFormError()}
            onError={(error) => setFormError(error)}
            onSuccess={(formData) => handleOnSubmit(formData)}
          />
        )}
        {flags && flags.google_authentication && (
          <GoogleLoginButton
            className="mt-4"
            label="Sign Up with Google"
            onClick={() => setFormError()}
            onError={(error) => setFormError(error)}
            onSuccess={(formData) => handleOnSubmit(formData)}
          />
        )}

        {flags &&
          (flags.google_authentication || flags.facebook_authentication) && (
            <OrDivider />
          )}
        <button
          onClick={() => setShowModal(true)}
          className="bg-blue h-11 rounded-full transition-colors duration-200 w-full text-white hover:bg-blue-400 active:bg-blue-600 "
        >
          Sign Up with Email
        </button>
        <div className="mt-5 text-center text-grey text-sm">
          Already a member?{' '}
          <Link
            to="/login"
            className="text-blue hover:text-blue-400 transition-colors duration-200"
            onClick={() => logAnalyticEvent('click', 'login_link_signup_form')}
          >
            Login here
          </Link>
        </div>
      </section>
      {showModal && <SignupModal onClose={() => setShowModal(false)} />}
    </>
  );
};

export default SignForm;
