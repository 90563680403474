export default [
  {
    to: '/blog',
    label: 'Blog',
  },
  {
    to: '/about',
    label: 'About',
  },
  {
    to: '/faq',
    label: 'FAQ',
  },
  {
    to: '/contact',
    label: 'Contact',
  },
  {
    to: '/terms',
    label: 'Terms & Conditions',
  },
  {
    to: '/privacy',
    label: 'Privacy Policy',
  },
  {
    to: '/privacy-policy-ccpa',
    label: 'Privacy Policy - California Residents',
  },
  {
    to: '/do-not-sell-my-personal-information',
    label: 'Do Not Sell My Personal Information',
  },
];
