import { useSelector } from 'react-redux';
import NavItem from './components/NavItem';
import SponsoredNav from './components/SponsoredNav';

const AppNav = () => {
  const { addPoints, isAdmin } = useSelector((state) => state.user);
  return (
    <aside
      className={`${
        addPoints ? 'z-1' : 'z-10'
      } bg-grey-100 md:bg-transparent fixed md:relative w-auto md:w-44 xl:w-56 md:py-3 bottom-0 shadow md:shadow-none md:bottom-auto md:left-auto left-0 md:right-auto right-0`}
    >
      <nav className="flex md:flex-col flex-row">
        <NavItem to="/dashboard" label="Dashboard" />
        <NavItem to="/rewards" label="Rewards" />
        <NavItem to="/timeline" label="Timeline" />
        {isAdmin && <NavItem to="/admin" label="Admin" external />}
      </nav>
      <SponsoredNav />
    </aside>
  );
};

export default AppNav;
