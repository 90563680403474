import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Alert from '@/components/Alert';
import useGetPageContent from '@/hooks/useGetPageContent';
import BlogArticle from '@/components/BlogArticle';
import LoadingEllipsis from '@/components/LoadingEllipsis';

const BlogPage = () => {
  const { contentData, loading, error } = useGetPageContent('/blog');

  return (
    <>
      <Helmet>
        <title>Communa Blog | Communa</title>

        <meta property="og:title" content="Communa Blog" />
        <meta property="og:url" content="https://www.communa.com/blog" />
      </Helmet>
      <section className="max-w-2xl mx-auto my-8 px-6 md:px-10">
        {error ? (
          <div className="mt-3">
            <Alert type="error">
              Something went wrong. Please refresh the page. If the issue
              persists, please <Link to="/contact">contact us</Link>.
            </Alert>
          </div>
        ) : loading ? (
          <div className="py-12 md:py-48 flex justify-center text-blue">
            <LoadingEllipsis width={48} />
          </div>
        ) : (
          contentData.map((article, i) => (
            <BlogArticle key={`ar-${i}`} article={article} />
          ))
        )}
      </section>
    </>
  );
};

export default BlogPage;
