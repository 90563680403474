import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, Link } from 'react-router-dom';
import BlogArticle from '@/components/BlogArticle';
import BlogSeeAlso from '@/components/BlogSeeAlso';
import LoadingEllipsis from '@/components/LoadingEllipsis';
import Alert from '@/components/Alert';
import useGetPageContent from '@/hooks/useGetPageContent';

const BlogArticlePage = () => {
  const { id, slug } = useParams();

  const [path, setPath] = useState(null);

  const { contentData, loading, error } = useGetPageContent(path);

  const seoTitle = contentData?.title ? `${contentData.title} |` : '';
  const seoSummary = contentData?.summary ? `${contentData.summary}` : '';
  const seoImage = contentData?.featured_image
    ? `${contentData.featured_image}`
    : '';

  useEffect(() => {
    if (id && slug) {
      setPath(`/blog/${id}/${slug}`);
    }
  }, [id, slug]);

  return (
    <>
      <Helmet>
        <title>{seoTitle} Communa</title>

        {/* META TAGS */}
        <meta name="title" content={`${seoTitle} Communa`} />
        <meta name="description" content={seoSummary} />
        <meta name="abstract" content={seoSummary} />
        <meta name="image" content={seoImage} />

        {/* OPEN GRAPH TAGS */}
        <meta property="og:title" content={`${seoTitle} Communa`} />
        <meta
          property="og:url"
          content={`https://www.communa.com/blog/${id}/${slug}`}
        />
        <meta property="og:image" content={seoImage} />
        <meta property="og:description" content={seoSummary} />
      </Helmet>
      <section className="max-w-2xl mx-auto my-8 px-5 md:px-8">
        {error ? (
          <div className="mt-3">
            <Alert type="error">
              Something went wrong. Please refresh the page. If the issue
              persists, please <Link to="/contact">contact us</Link>.
            </Alert>
          </div>
        ) : loading ? (
          <div className="py-24 md:py-48 flex justify-center text-blue">
            <LoadingEllipsis width={48} />
          </div>
        ) : (
          <>
            <BlogArticle article={contentData} isSingle />
            {contentData.see_also && contentData.see_also.slug && (
              <BlogSeeAlso article={contentData.see_also} />
            )}
          </>
        )}
      </section>
    </>
  );
};

export default BlogArticlePage;
