import PropTypes from 'prop-types';

const Header = ({ title, onClose }) => {
  return (
    <header className="bg-blue p-8 font-bold  text-center">
      <h2 className="text-2xl text-white mb-0">{title}</h2>
      <button
        className="hover:opacity-100 opacity-75 duration-200 transition-opacity absolute top-4 right-4 z-10"
        onClick={onClose}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 32 32">
          <polygon
            fill="#FFF"
            points="32 3.8 28.2 0 16 12.2 3.8 0 0 3.8 12.2 16 0 28.2 3.8 32 16 19.8 28.2 32 32 28.2 19.8 16"
          />
        </svg>
      </button>
    </header>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Header;
