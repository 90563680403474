/* eslint-disable react/display-name */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { post, get } from '@/config/axios';
import useRecursiveTimeout from '@/hooks/useRecursiveTimeout';
import { setUser } from '@/store/slices/userSlice';
import { setFlags } from '@/store/slices/featureFlagsSlice';
import { setSponsors } from '@/store/slices/sponsorsSlice';
import { setPageContent, resetPageContent } from '@/store/slices/pageSlice';
import { setIsOnline } from '@/store/slices/configSlice';

const withSession = (Component) => ({ ...props }) => {
  const { userId } = useSelector((state) => state.user);
  const [time, setTime] = useState(12000);
  const { list: eventsList } = useSelector((state) => state.events);
  const { list: actionsList } = useSelector((state) => state.actions);
  const { isOnline } = useSelector((state) => state.config);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [payloadLoaded, setPayloadLoaded] = useState(false);

  const getPayload = async () => {
    const queryParams = {
      path: location.pathname,
    };

    if (location.search) {
      let params = location.search.replace('?', '');
      // params = encodeURIComponent(params);
      queryParams.params = params;
    }

    try {
      const { data } = await post('v2/payload', queryParams, null, true);
      const {
        payload,
        feature_flags,
        survey_sponsors,
        page_content,
        ccpa_opt_in_required,
      } = data?.data;

      payload?.user && dispatch(setUser(payload?.user));
      feature_flags && dispatch(setFlags(feature_flags));
      survey_sponsors && dispatch(setSponsors(survey_sponsors));
      page_content && dispatch(setPageContent(page_content));
      // sample_chain && dispatch(setSamplechain(sample_chain));
      // dispatch(setPKeys(data.data));

      setPayloadLoaded(true);

      if (
        ccpa_opt_in_required &&
        !location.pathname.includes('privacy-policy-ccpa-opt-in')
      ) {
        history.push('/privacy-policy-ccpa-opt-in');
      }
    } catch (err) {
      // console.error(err);
    }
  };

  const onTabBlur = () => {
    setTime(60000);
  };

  const onTabFocus = () => {
    setTime(12000);
    getUserEvents();
  };

  const getUserEvents = async () => {
    if (!userId || actionsList.length || eventsList.length || !isOnline) return;

    try {
      await get('v1/user/load', {
        user_id: userId,
      });
    } catch (err) {
      // console.error(err);
    }
  };

  useRecursiveTimeout(() => getUserEvents(), time);

  useEffect(() => {
    dispatch(resetPageContent());
    getPayload();
  }, [location]);

  useEffect(() => {
    window.addEventListener('blur', onTabBlur);
    window.addEventListener('focus', onTabFocus);
    window.addEventListener('offline', () => dispatch(setIsOnline(false)));
    window.addEventListener('online', () => dispatch(setIsOnline(true)));
    return () => {
      window.removeEventListener('blur', onTabBlur);
      window.removeEventListener('focus', onTabFocus);
      window.removeEventListener('offline', () => dispatch(setIsOnline(false)));
      window.removeEventListener('online', () => dispatch(setIsOnline(true)));
    };
  }, []);

  return <Component payloadLoaded={payloadLoaded} {...props} />;
};

export default withSession;
