import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormSelect from '@/components/FormSelect';

const OptionsDate = ({ data, onChange }) => {
  const [preAnswers, setPreAnswers] = useState({});
  const [yearsList, setYearsList] = useState([]);
  const [daysList, setDaysList] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedDay, setSelectedDay] = useState();

  const {
    type_specific_config: { no_day, max, above_max_error_message },
  } = data;

  const monthsList = [
    {
      label: 'January',
      value: '01',
    },
    {
      label: 'Febuary',
      value: '02',
    },
    {
      label: 'March',
      value: '03',
    },
    {
      label: 'April',
      value: '04',
    },
    {
      label: 'May',
      value: '05',
    },
    {
      label: 'June',
      value: '06',
    },
    {
      label: 'July',
      value: '07',
    },
    {
      label: 'August',
      value: '08',
    },
    {
      label: 'September',
      value: '09',
    },
    {
      label: 'October',
      value: '10',
    },
    {
      label: 'November',
      value: '11',
    },
    {
      label: 'December',
      value: '12',
    },
  ];

  const makeYearsList = () => {
    const currentYear = new Date().getFullYear() - 17;
    const firstYear = currentYear - 99;

    const yearsList = Array.from(
      { length: (firstYear - currentYear) / -1 + 1 },
      (_, i) => currentYear + i * -1
    );
    const yearsArr = [];
    for (const year of yearsList) {
      yearsArr.push({ label: year, value: year });
    }
    setYearsList(yearsArr);
  };

  const makeDaysList = () => {
    const nbOfDays = new Date(selectedYear, Number(selectedMonth), 0).getDate();
    const daysList = [];
    for (let i = 1; i <= nbOfDays; i++) {
      daysList.push({
        label: i.toString(),
        value: i < 10 ? '0' + i : i.toString(),
      });
    }
    setDaysList(daysList);
  };

  const handleYearChange = (e) => {
    if (selectedMonth) {
      makeDaysList();
    }
    if (selectedDay) {
      setSelectedDay('');
    }
    setSelectedYear(e.target.value);
  };

  useEffect(() => {
    makeDaysList();
  }, [selectedMonth]);

  useEffect(() => {
    const isValid = selectedDay && selectedMonth && selectedYear;

    if (isValid) {
      const dateToIso = new Date(
        `${selectedYear}-${selectedMonth}-${selectedDay}`
      ).toISOString();
      onChange(dateToIso);
    }
  }, [selectedDay, selectedMonth, selectedYear]);

  useEffect(() => {
    makeYearsList();
  }, []);

  return (
    <section className="max-w-lg flex mx-auto py-8">
      <div className="flex-1 px-3">
        <FormSelect
          name="year"
          value={selectedYear}
          placeholderOption=""
          options={yearsList}
          onChange={handleYearChange}
          label="Year"
        />
      </div>
      <div className="flex-1 px-3">
        <FormSelect
          name="month"
          value={selectedMonth}
          placeholderOption=""
          disabled={!selectedYear}
          options={monthsList}
          label="Month"
          onChange={(e) => setSelectedMonth(e.target.value)}
        />
      </div>
      <div className="flex-1 px-3">
        <FormSelect
          name="day"
          value={selectedDay}
          placeholderOption=""
          disabled={!selectedMonth}
          options={daysList}
          onChange={(e) => setSelectedDay(e.target.value)}
          label="Day"
        />
      </div>
    </section>
  );
};

OptionsDate.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OptionsDate;
