import PropTypes from 'prop-types';

const ProgressBar = ({ reward }) => {
  const { points_adjusted, min_value_points } = reward;

  const progressionBarWidth =
    points_adjusted / min_value_points <= 1
      ? (points_adjusted / min_value_points) * 100
      : 100;
  return (
    <div className="absolute bottom-4 left-4 right-4 flex items-center py-2.5 px-4 justify-center">
      <div className="bg-grey-900 absolute left-0 top-0 rounded-full w-9 flex justify-center border-2 border-white text-white items-center h-9">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-5 -2 24 24"
          width="20"
        >
          <path
            fill="currentColor"
            d="M12 10a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2V5a5 5 0 1 1 10 0v5zm-5 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-7V5a3 3 0 1 0-6 0v5h6z"
          />
        </svg>
      </div>
      <div className="bg-white w-4/6 ml-3 h-4 rounded-full">
        <div className="relative pr-1.5">
          <div
            className="h-2.5 rounded-full  bg-blue"
            style={{
              margin: '0.1875rem',
              width: `${progressionBarWidth}%`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  reward: PropTypes.object.isRequired,
};

export default ProgressBar;
