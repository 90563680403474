import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormRadio from '@/components/FormRadio';
import Row from './Row';

const OptionsLikert = ({ data, onChange }) => {
  const [preAnswers, setPreAnswers] = useState({});

  const {
    answer_set,
    type_specific_config: { rows },
  } = data;

  const handleOnChange = ({ target }) => {
    setPreAnswers({
      ...preAnswers,
      [target.name]: target.value,
    });
  };

  const isMatrixComplete = () =>
    Object.keys(preAnswers).length === answer_set.length;

  useEffect(() => {
    if (isMatrixComplete()) {
      onChange(preAnswers);
    }
  }, [preAnswers]);

  return (
    <section>
      {answer_set.map(({ label, id, answer_set }, i) => (
        <Row key={`rw-${i}`} className="py-4">
          <div className="font-bold mb-3">{label}</div>
          <div className="flex">
            {answer_set.map(({ id: value, label }, ic) => (
              <div
                className="flex-1 flex flex-col text-center px-2"
                key={`r-${i}-col-${ic}`}
              >
                <div className="text-xs font-bold flex-1 mb-2">{label}</div>
                <FormRadio
                  className="justify-center"
                  noLabel
                  name={id}
                  value={value}
                  onChange={handleOnChange}
                />
              </div>
            ))}
          </div>
        </Row>
      ))}
    </section>
  );
};

OptionsLikert.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OptionsLikert;
