import { InView } from 'react-intersection-observer';
import DecoratedHeading from '@/components/DecoratedHeading';
import WhyMan from '@/assets/img/why.png?webp';
import Img from 'react-optimized-image';
import bckgImg from '@/assets/img/c-pattern.png?webp';

const AboutWhyJoin = () => {
  return (
    <InView triggerOnce={true} threshold={0.25}>
      {({ inView, ref }) => (
        <div ref={ref} className="relative">
          <img
            src={bckgImg}
            alt=""
            className="absolute left-10 bottom-10 md:bottom-auto md:top-10 w-80"
          />
          <section className="px-6 max-w-7xl relative z-1 mx-auto pt-12 md:pt-20 flex flex-col-reverse md:flex-row items-center">
            <div
              className={`${
                inView ? 'animate-1' : ''
              } animate md:w-1/2 w-full px-6 md:px-0  flex justify-center`}
            >
              <div className="w-72">
                <Img src={WhyMan} webp />
              </div>
            </div>
            <div
              className={`${
                inView ? 'animate-2' : ''
              } animate md:w-1/2 mt-5 md:mt-0`}
            >
              <DecoratedHeading h={2} label="Why Join Communa?" />
              <p className="text-lg my-6 text-center md:text-left px-6 md:px-0">
                Communa is an online community where opinions matter! Members
                are able to voice their thoughts and ideas, and get rewarded for
                doing so. Communa members also have the added benefit of giving
                feedback anytime and anywhere.
              </p>
            </div>
          </section>
        </div>
      )}
    </InView>
  );
};

export default AboutWhyJoin;
